import userProfile from "user-profile";
import storageManager from "../../../utils/storageManager";
import template from './grid-auto-refresh-component.html';
import ko from "knockout";
import dataModel from "data-model";

const storageKey = 'ge-grid-auto-refresh-state';

export const options = [
    { label: 'Off', value: -1 },
    { label: '30 Seconds', value: 30 },
    { label: '1 Minute', value: 60 },
    { label: '3 Minute', value: 180 },
]

interface IGridAutoRefreshManagerProps {
    gridId: string;
    fetchUrl: string;
    initData?: any[];
    onRefresh: (data: any[]) => void;
    onRefreshStop?: () => void;
    fetchParams?: any;
    fetchType?: "GET" | "POST";
    dataResponseKey?: string;
    getFetchParams?: () => any;
    onFetchError?: (error: any) => void;
    onFetchComplete?: (response: any) => any | undefined;
}

interface IGridAutoRefreshComponentProps {
    showModal: ko.Observable<boolean>;
    gridId: string;
    onClose: (interval: number | undefined) => void;
}

interface IGridAutoRefreshSavedState {
    userId: string;
    gridName: string;
    interval: number;
}

interface IGridAutoRefreshDataState {
    data: any[],
    lastFetchTimestamp: number,
    lastRefreshTimestamp: number,
    isFetching: boolean,
}

class GridAutoRefreshSavedState {
    constructor(
        public userId: string,
        public gridName: string,
        public interval: number) {
    }
}

export const fetchGridDataAsync = (fetchUrl: string, fetchType: string, fetchParams: any) => {
    return new Promise<any[]>((resolve, reject) => {
        dataModel.ajaxRequest(fetchUrl, fetchType ?? "GET", fetchParams).done((data) => resolve(data))
            .fail((error) => reject(error));
    })
}

const loadSavedOption = (id: string) => {
    const storedStates = storageManager.get(storageKey) as IGridAutoRefreshSavedState[] | undefined;
    const match = storedStates?.find(
        (x) => x.userId === userProfile.userId && x.gridName === id
    );

    return match ?? new GridAutoRefreshSavedState(userProfile.userId, `${id}`, options[0].value);
};

export class GridAutoRefreshManager {
    private readonly props: IGridAutoRefreshManagerProps;
    private dataState: IGridAutoRefreshDataState | undefined = { data: [], lastFetchTimestamp: 0, lastRefreshTimestamp: 0, isFetching: false };
    private fetchIntervalId?: number;
    private refreshCallbackIntervalId?: number;
    private activityTimeoutId?: number;
    private userIsActive = false;

    private fetchIntervalInSeconds: number = -1; // Continuous data fetch interval
    private refreshIntervalInSeconds: number = -1; // Callback trigger interval
    private $container: HTMLElement | undefined;

    constructor(props: IGridAutoRefreshManagerProps) {
        this.props = props;

        if (this.props.initData) {
            this.dataState.data = this.props.initData;
        }

        // Set initial interval from the saved state
        const savedState = this.getSavedIntervalState(this.props.gridId);
        this.fetchIntervalInSeconds = (savedState.interval - 10) > 0 ? savedState.interval - 10 : 0;
        this.refreshIntervalInSeconds = savedState.interval;
    }

    /**
     * Updates callback refresh intervals and restarts timers.
     * @param refreshIntervalInSeconds The interval for triggering onRefresh in seconds
     */
    setInterval = (
        refreshIntervalInSeconds: number
    ) => {
        this.refreshIntervalInSeconds = refreshIntervalInSeconds;
        this.fetchIntervalInSeconds = (refreshIntervalInSeconds - 10) > 0 ? refreshIntervalInSeconds - 10 : 0;
        this.restartIntervals();
    };

    /**
     * Gets the saved auto-refresh state from local storage
     * @param id
     */
    private getSavedIntervalState = (id: string) => {
        return loadSavedOption(id);
    };

    /**
     * Starts both fetch and callback intervals
     */
    private restartIntervals = () => {
        this.stop(); // Clear existing intervals

        // Start continuous data fetching interval
        if (this.fetchIntervalInSeconds > 0) {
            this.fetchIntervalId = window.setInterval(async () => {
                await this.getData();
            }, this.fetchIntervalInSeconds * 1000);
        }

        // Start the callback trigger interval
        if (this.refreshIntervalInSeconds > 0) {
            this.refreshCallbackIntervalId = window.setInterval(async () => {
                // Trigger callback only if the user is not active
                if (!this.userIsActive) {
                    await this.handleRefresh();
                }
            }, this.refreshIntervalInSeconds * 1000);
        }
    };

    /**
     * Handles periodic updates to the parent via the onRefresh callback
     */
    private handleRefresh = async () => {
        if (this.dataState?.isFetching) {
            console.log(`Waiting for fetch to complete at ${new Date().toLocaleTimeString()}`)
            await this.waitForFetchCompletion();
        }

        console.log(`Refreshing data at ${new Date().toLocaleTimeString()}`)
        this.dataState.lastRefreshTimestamp = Date.now();
        this.props.onRefresh(this.dataState.data ?? []);
    };

    /**
     * Wait for ongoing fetch to complete
     */
    private waitForFetchCompletion = (): Promise<void> => {
        return new Promise((resolve) => {
            const intervalId = setInterval(() => {
                if (!this.dataState?.isFetching) {
                    clearInterval(intervalId);
                    resolve();
                }
            }, 100); // Check every 100ms
        });
    };

    /**
     * Monitors user activity and pauses refresh callbacks
     */
    private windowActivityHandler = () => {
        if (this.activityTimeoutId) {
            clearTimeout(this.activityTimeoutId);
        }

        this.userIsActive = true;

        // Reset to inactive after 1.5 seconds of inactivity
        this.activityTimeoutId = window.setTimeout(() => {
            this.userIsActive = false;
        }, 1500);
    };

    /**
     * Handles browser visibility changes
     */
    private handleVisibilityChange = async () => {
        if (document.visibilityState === "visible") {
            const now = Date.now();
            const timeSinceLastRefresh = (now - (this.dataState?.lastRefreshTimestamp ?? 0)) / 1000;

            // Check if the time since the last refresh exceeds the refresh interval
            if (timeSinceLastRefresh >= this.refreshIntervalInSeconds) {
                if (this.dataState?.isFetching) {
                    await this.waitForFetchCompletion();
                }

                // Perform the refresh
                await this.handleRefresh();
            }
        }
    };

    /**
     * Initialize activity tracking on the grid element
     */
    private initActivityTracking = () => {
        this.clearActivityTracking();

        this.$container = $(`#${this.props.gridId}`).closest("div")[0] as HTMLElement;
        if (!this.$container) {
            console.warn("Grid parent container is not found!");
            return;
        }

        // Track activity within the grid container
        const events = ["mousemove", "mousedown", "keydown", "click"];
        events.forEach((event) =>
            this.$container?.addEventListener(event, this.windowActivityHandler)
        );

        document.addEventListener("visibilitychange", this.handleVisibilityChange);
    };

    /**
     * Remove event listeners, etc
     */
    private clearActivityTracking = () => {
        if (this.$container) {
            const events = ["mousemove", "mousedown", "keydown", "click"];
            events.forEach((event) =>
                this.$container?.removeEventListener(event, this.windowActivityHandler)
            );
        }

        document.removeEventListener("visibilitychange", this.handleVisibilityChange);

        if (this.activityTimeoutId) {
            clearTimeout(this.activityTimeoutId);
            this.activityTimeoutId = undefined;
        }

        this.userIsActive = false;
    };

    /**
     * Fetch data from external source
     */
    private getData = async () => {
        // Check if a fetch is already in progress
        if (this.dataState?.isFetching) {
            return;
        }

        try {
            this.dataState.isFetching = true;
            const params = this.props?.getFetchParams() ?? this.props?.fetchParams ?? {};

            let data = await fetchGridDataAsync(this.props.fetchUrl, this.props.fetchType ?? "GET", params);

            if (this.props.onFetchComplete) {
                data = this.props.onFetchComplete(data);
            }

            if (data) {
                if (this.props.dataResponseKey) {
                    this.dataState.data = data[this.props.dataResponseKey] ?? [];
                } else {
                    this.dataState.data = data ?? [];
                }
            }

            this.dataState.lastFetchTimestamp = Date.now();
        } catch (err) {
            console.error(err);

            if (this.props.onFetchError) {
                this.props.onFetchError(err);
            }
        } finally {
            this.dataState.isFetching = false; // Reset fetch flag
        }
    };

    /**
     * Restarts all timers and refreshes data
     */
    restart = async () => {
        this.stop();
        await this.start();
    };

    /**
     * Starts the refresh process
     */
    start = async () => {
        this.stop();
        this.initActivityTracking();
        await this.getData();
        await this.handleRefresh();
        this.restartIntervals();
    };

    /**
     * Stops all timers and cleans up user activity tracking
     */
    stop = () => {
        this.clearActivityTracking();

        if (this.fetchIntervalId) {
            clearInterval(this.fetchIntervalId);
            this.fetchIntervalId = undefined;
        }

        if (this.refreshCallbackIntervalId) {
            clearInterval(this.refreshCallbackIntervalId);
            this.refreshCallbackIntervalId = undefined;
        }

        if (this.props.onRefreshStop) {
            this.props.onRefreshStop();
        }
    };

    dispose = () => {
        this.stop();
    };
}

class GridAutoRefreshComponentViewModel {
    private readonly props: IGridAutoRefreshComponentProps;
    private savedState: IGridAutoRefreshSavedState | undefined;
    private $modal: JQuery<HTMLElement> = $('#grid-auto-refresh-modal');
    private $jqxRefreshOptions: jqwidgets.jqxListBox | undefined;

    constructor(props: IGridAutoRefreshComponentProps) {
        this.props = props;

        // Load saved settings
        this.loadSavedOption();

        // Initialize UI for refresh interval options
        this.$jqxRefreshOptions = jqwidgets.createInstance("#refresh-interval-options", 'jqxListBox', {
            source: options,
            selectedIndex: options.findIndex(x => x.value === this.savedState?.interval),
            width: '100%',
            height: 200,
            autoHeight: true,
            displayMember: 'label',
            valueMember: 'value',
        });

        // Bind event handlers
        this.$jqxRefreshOptions.addEventHandler('select', this.handleOptionSelected);
        this.props.showModal.subscribe((show) => {
            if (show) {
                this.$modal.modal('show');
            } else {
                this.$modal.modal('hide');
            }
        });
        this.$modal.on('hidden.bs.modal', () => this.handleClose());
    }

    private handleOptionSelected = () => {
        const option = this.$jqxRefreshOptions?.getSelectedItem();
        if (option) {
            this.savedState = new GridAutoRefreshSavedState(userProfile.userId, this.props.gridId, option.value);
        }
    }

    private handleClose = () => {
        // Save the selected refresh interval
        if (this.savedState) {
            this.saveOption(this.savedState);
        }
        this.props.onClose(this.savedState.interval); // Notify the parent that the modal has closed
    }

    private loadSavedOption = () => {
        this.savedState = loadSavedOption(this.props.gridId);
    };

    private saveOption = (state: IGridAutoRefreshSavedState) => {
        const storedStates = (storageManager.get(storageKey) ?? []) as IGridAutoRefreshSavedState[];
        const index = storedStates.findIndex(
            (x) => x.userId === state.userId && x.gridName === state.gridName
        );
        if (index > -1) {
            storedStates[index] = state;
        } else {
            storedStates.push(state);
        }
        storageManager.set(storageKey, storedStates);
    };

    dispose = () => {
        this.$jqxRefreshOptions?.removeEventHandler('select');
    };
}

export default { viewModel: GridAutoRefreshComponentViewModel, template: template }