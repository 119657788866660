import "jqwidgets-scripts/jqwidgets/globalization/globalize";
import {datetimeUTC, GenerateGuid} from "./global-functions";
import { mobileAndTabletcheck } from "../shared-components/global-functions";
import dayjs from "dayjs";
import userProfile from "user-profile";
import dataModel from "data-model";
// 'use strict';

export const removeFilterKeysFromObject = (filterObject, keysToRemove) => {
  // Prepare to build a new filter object with only the non-matching fields
  const newFilters = {};
  let newFiltersCount = 0; // Track new index of filters

  for (let i = 0; i < filterObject.filterscount; i++) {
    // Check each filter's data field
    const filterDataField = filterObject[`filterdatafield${i}`];
    if (keysToRemove.indexOf(filterDataField) === -1) {
      // If the key doesn't match, copy the filter fields
      newFilters[`filtervalue${newFiltersCount}`] = filterObject[`filtervalue${i}`];
      newFilters[`filtercondition${newFiltersCount}`] = filterObject[`filtercondition${i}`];
      newFilters[`filteroperator${newFiltersCount}`] = filterObject[`filteroperator${i}`];
      newFilters[`filterdatafield${newFiltersCount}`] = filterDataField;
      newFilters[`filtertype${newFiltersCount}`] = filterObject[`filtertype${i}`];
      newFiltersCount++; // Update the count for the new filter
    }
  }

  return newFilters; // Return the cleaned object
}

export class GreatEdgeCustomWidgets {
  constructor() {
    // Parent (like a static class)
    var parent = this;

    // GEDateRangePickerWidget - This is the 'manager' or 'builder' function.
    // __dateRangePickerWidget -> Is the widget itself.
    // *The widget can be used as part of a grid or stand alone input on a page. The widget is mostly a wrapper around two
    // jqx datetime inputs with custom styling and logic that has the two inputs communicating with each other based on user
    // input/uses.
    // Using it as a widget on a page is pretty straightforward (see driversettlements.aspx/viewModel or agentSettletments.aspx/viewModel)
    // However using it as a filter on a jqx grid does require a few more hooks and options to be set.
    /* Example Usage
        ------------------
        
        //widget (class) level options
        var options = {
            initialFromDate: fromDate, // optional
            initialToDate: toDate, // optional
            // (optional) grid options if widget is used as a grid filter
            gridOptions: {
                gridId: 'jqxEDI', // required to apply gridoptions
                columnName: column.datafield, // also required, especially if this is a filter widget
                isFilter: true // create the widget as a filter on the grid (sets up bindings, etc)
                state: gridState // if grid has a save state with initial dates you can pass it here and they will be applied
            }
        }

        // GEDateRangePickerWidget method will create a new widget (or return the widget if already exists only if it is a grid filtering widget).
        // *Since jqx grid repaints for certain events instead of creating new widgets return what is already created and 'rebind' to the grid.
        var widget = GECustomWidgets.GEDateRangePickerWidget(options);
        or
        just GECustomWidgets.GEDateRangePickerWidget(options); to apply it to element.

    ==================================================================================================== */
    parent.GEDateRangePickerWidget = function (options) {
      //debugger
      var self = this;
      var gridOptions =
        options["gridOptions"] != undefined &&
        typeof options["gridOptions"] === "object"
          ? options["gridOptions"]
          : null;
      var _widget = null;

      return _widget != null ? _widget : new __dateRangePickerWidget(options);
    };

    // DATETIME RANGE PICKER /W TYPING CAPABILITIES
    var __dateRangePickerWidget = function (options) {
      //debugger;
      var self = this;

      self.options = options || {};
      const showQuickOptions = self.options["showQuickOptions"] || false;
      var bindToElement = self.options["bindToElement"] || null;
      //self._appendToEl_ = self.options['gridOptions'] != null ? self.options['gridOptions']['appendToEl'] != null ? self.options['gridOptions']['appendToEl'] : null : null;
      var dateFormat = "MM/dd/yyyy";
      var __currentDate = new Date();

      var minAllowedYear =
        self.options["minAllowedYear"] || __currentDate.getFullYear() - 50;
      var maxAllowedYear =
        self.options["maxAllowedYear"] || __currentDate.getFullYear() + 3;
      var minAllowedDate =
        self.options["minAllowedDate"] || new Date(minAllowedYear, "00", "01"); // Zero based - 00 -> Jan
      var maxAllowedDate =
        self.options["maxAllowedDate"] || new Date(maxAllowedYear, "11", "31"); // 11 -> Dec

      var widgetPrefixGuid =
        "GEDateRangePickerWidget_UID" + GenerateGuid(6) + "_";
      var widgetId = self.options["setWidgetId"] || widgetPrefixGuid;
      var width =
        self.options["width"] != null
          ? self.options["width"]
          : self.options["gridOptions"] != null
          ? (showQuickOptions ? 100 : 73)
          : "100%";

      width = showQuickOptions ? width + 20 : width;

      var animationType = mobileAndTabletcheck() ? "none" : "slide";
      var widgetHeight = self.options["height"] || 25;
      var isWidgetInitialized = false;
      var valueChangeCallBackFn = self.options["valueChangeCallBackFn"] || null;
      //var observableObj = self.options['setObservable'] || null;

      //-----------------
      // Build the widget
      const conWidth = showQuickOptions ? 275 : 255;
      var $dateRangeContainer = $("<div/>", {
        class: "input-group ge-input-daterange",
        style: bindToElement ? `min-width: ${conWidth}px;!important;` : "",
      });
      var $fromInput = $("<div/>", {
        class: "ge-date-control datepicker-from",
        placeholder: dateFormat,
        id: widgetId + "fromDate",
      });
      var $toInput = $("<div/>", {
        class: "ge-date-control datepicker-to",
        placeholder: dateFormat,
        id: widgetId + "toDate",
      });
      var $separator = $("<span/>", { class: "ge-group-separator", text: "-" });
      var $calendarBtn = $("<button/>", {
        class: "ge-date-calendar-btn",
        type: "button",
        style: bindToElement ? "padding: 2.5px 8px!important" : "padding: 5px;",
      }).append(
        $("<i/>", {
          class: "glyphicon glyphicon-calendar",
        })
      );

      self.$widgetEl = $dateRangeContainer
        .append($fromInput)
        .append($separator)
        .append($toInput)
        .append($calendarBtn);

      // Pre-setup of QuickDateOptions so they are in outer scope.
      const $optionList = $("<div/>", {});
      const $quickDayTextInput = $("<input/>", {
        type: "text",
        class: "jqx-input jqx-widget-content jqx-widget",
        placeholder: "By day(s)", // Input placeholder
        style: "width: 100px; margin-right: 5px;",
      });
      const $quickDatePopupBtn = $("<div>");

      // Add 'QuickOptions' droplist when option is set.
      if(showQuickOptions) {
        let isQuickOptionsOpen = false;
        const options = [
          {label: 'Last 30 Days', value: -30},
          {label: 'Last 14 Days', value: -14},
          {label: 'Last 7 Days', value: -7},
          {label: 'Last 3 Days', value: -3},
          {label: 'Prior Day', value: -1},
          {label: 'Today', value: 0},
          {label: 'Tomorrow', value: 1},
          {label: 'Next 3 Days', value: 3},
          {label: 'Next 7 Days', value: 7},
          {label: 'Next 14 Days', value: 14},
          {label: 'Next 30 Days', value: 30},
        ]

        $optionList.jqxTree({ source: options });
        $optionList.on("itemClick", function (event) {
          const args = event.args || {};
          const { label, value } = $(this).jqxTree("getItem", args.element) ?? {};

          const dates = calculateQuickDates(value, label);

          $quickDayTextInput.val("");

          if(self.options && self.options['onQuickOptionSelect']) {
            self.options['onQuickOptionSelect'](value, label, dates);
          }

          if (isWidgetInitialized) {
            self.setDates(dates);
            invokeValueChangeCallBackFn({
              fromDate: dates.from,
              toDate: dates.to,
            });

            if (self._gridId_ && self.options["gridOptions"]["columnName"]) {
              applyOrResetFiltersAndEventBindings(self.options["gridOptions"]["columnName"], $("#" + self._gridId_))
            }

            $showAllBtn.click();
          }
        });

        const $dropdownContent = $("<div>").css({ padding: "5px" });
        const $inputContainer = $("<div/>").css({
          marginTop: "10px",
          padding: "5px",
          display: "flex",
          alignItems: "center",
        });
        const $inputLabel = $("<label/>", {
          text: "Custom", // Descriptive label
          style: "margin-right: 5px; font-weight: bold;",
        });

        // Only accept numerics
        $quickDayTextInput.on("keypress", function (e) {
          const charCode = e.which ? e.which : e.keyCode;

          $optionList.jqxTree("clearSelection");

          if (
              charCode !== 45 && // Allow minus sign (-)
              (charCode < 48 || charCode > 57) // Allow numeric (0-9)
          ) {
            e.preventDefault();
          }
        });

        // Catch enter key and submit changes
        $quickDayTextInput.on("keypress", function (e) {
          if (e.key === "Enter") {
            const customValue = parseInt($(this).val(), 10);
            if (!isNaN(customValue)) {

              const dates = calculateQuickDates(customValue, "Custom");

              if (self.options && self.options["onQuickOptionSelect"]) {
                self.options["onQuickOptionSelect"](
                    customValue,
                    "Custom Shortcut",
                    dates
                );
              }

              self.setDates(dates);
              invokeValueChangeCallBackFn({
                fromDate: dates.from,
                toDate: dates.to,
              });

              if (self._gridId_ && self.options["gridOptions"]["columnName"]) {
                applyOrResetFiltersAndEventBindings(self.options["gridOptions"]["columnName"], $("#" + self._gridId_))
              }
            }

            $showAllBtn.click();
            return false;
          }
        });

        // Change tracking
        let prevInputValue = "";
        $quickDayTextInput.on("focus", function (e) {
          prevInputValue = $(this).val();
        });

        // Submit any changes on blur
        $quickDayTextInput.on("blur", function (e) {
          const value = $(this).val();
          const customValue = parseInt(value, 10);

          if(value !== prevInputValue) {
            prevInputValue = value;

            if(isNaN(customValue)) {
              resetQuickOptions();
              return false;
            }

            const dates = calculateQuickDates(customValue, "Custom");

            if(self.options && self.options['onQuickOptionSelect']) {
              self.options['onQuickOptionSelect'](value, "Custom", dates);
            }

            if (isWidgetInitialized) {
              self.setDates(dates);
              invokeValueChangeCallBackFn({
                fromDate: dates.from,
                toDate: dates.to,
              });
            }

            if (self._gridId_ && self.options["gridOptions"]["columnName"]) {
              applyOrResetFiltersAndEventBindings(self.options["gridOptions"]["columnName"], $("#" + self._gridId_))
            }
          }
        });

        const helpIconExample1 = datetimeUTC(new Date()).add(4, "days").format("MM/DD/YYYY");
        const helpIconExample2 = datetimeUTC(new Date()).add(-3, "days").format("MM/DD/YYYY");

        const $helpIcon = $("<i/>", {
          class: "glyphicon glyphicon-question-sign",
          style: "color: #999; cursor: pointer; font-size: 16px;",
          "data-toggle": "tooltip",
          "data-placement": "right",
          title: `Enter a number to calculate a date from the current date:<br>
                    e.g., 4 → '${helpIconExample1}' (current date + 4 days)<br>
                    -3 → '${helpIconExample2}' (current date - 3 days).`
        });

        $helpIcon.tooltip({
          html: true,
          container: 'body',
          template: '<div class="tooltip" role="tooltip"><div class="tooltip-arrow"></div><div class="tooltip-inner"></div></div>'
        });

        const $clearBtnContainer = $inputContainer.clone();
        const $clearButton = $("<button/>", {
          text: "Clear",
          class: "btn btn-default",
          style: "width: 100%; height: 28px;",
        });

        $clearButton.on("click", () => {
          resetQuickOptions();
          $showAllBtn.click();
        });

        $clearBtnContainer.append($clearButton);
        $inputContainer.append($inputLabel, $quickDayTextInput, $helpIcon);
        $dropdownContent.append($optionList, $inputContainer, $clearBtnContainer);

        $quickDatePopupBtn.append($dropdownContent);
        $quickDatePopupBtn.jqxDropDownButton({ animationType: "none",
          width: 250,
          height: 30,
        });
        $quickDatePopupBtn.css({
          position: "absolute",
          left: "0",
          top: "0",
          visibility: "hidden",
        });
        $quickDatePopupBtn.on("close", function (event) {
          isQuickOptionsOpen = false;
        })

        const $showAllBtn = $(
            '<button tabindex="-1" class="ui-button ui-widget ui-state-default ui-button-icon-only ui-corner-right ui-button-icon" role="button" >' +
            '<span class="ui-button-icon-primary ui-icon ui-icon-triangle-1-s"></span>' +
            '<span class="ui-button-text">&nbsp;</span>' +
            "</button>"
        );
        $showAllBtn.css({
          height: 28,
          width: "1.8em",
          position: "absolute",
        });

        $showAllBtn.click(function () {
          if (isQuickOptionsOpen) {
            $quickDatePopupBtn.jqxDropDownButton("close");
            isQuickOptionsOpen = false;
            return false;
          }

          $quickDatePopupBtn.jqxDropDownButton("open");
          isQuickOptionsOpen = true;
          return false;
        });

        // $('body').on("keydown", function (e) {
        //   const charCode = e.which ? e.which : e.keyCode;
        //
        //   if(charCode === 9 && isQuickOptionsOpen) {
        //     $showAllBtn.click();
        //     return false;
        //   }
        // })

        self.$widgetEl.append($showAllBtn).append($quickDatePopupBtn);

        if(self.options && self.options['initQuickDateOptions']) {
          const option = self.options['initQuickDateOptions'] ?? {};

          if(Object.keys(option).length === 0) {
            return;
          }

          if((option?.label === "Custom") || (option?.option  === "Custom")) {
            $quickDayTextInput.val(option?.value);
          }
          else {
            const treeItems = $optionList.jqxTree("getItems");
            const treeItem = treeItems.find((item) => item.label === (option?.label || option?.option)) ;

            if (treeItem) {
              $optionList.jqxTree("selectItem", treeItem.element);
            }
          }

          let isInitFilterBeingApplied = false;
          document.addEventListener('geCustomDateWidgetLoaded', (event) => {
            const dates = calculateQuickDates(option.value, (option.label || option.option));

            self.setDates(dates);
            invokeValueChangeCallBackFn({
              fromDate: dates.from,
              toDate: dates.to,
            });

            if (!isInitFilterBeingApplied && self._gridId_ && self.options["gridOptions"]["columnName"]) {
              isInitFilterBeingApplied = true;
              const $grid = $("#" + self._gridId_);

              setTimeout(() => {
                addColumnDateFiltersToGrid(self.options["gridOptions"]["columnName"], $grid);
              }, 10)

              // $grid.off("bindingcomplete").on("bindingcomplete", function () {
              //   setTimeout(() => {
              //     addColumnDateFiltersToGrid(self.options["gridOptions"]["columnName"], $grid);
              //      $grid.jqxGrid("updatebounddata", "filters");
              //     $grid.off("bindingcomplete");
              //   }, 50)
              //
              // });
            }

          });
        }
      }

      function calculateQuickDates(value, label) {

        if(value == null) {
          return {from: null, to: null};
        }

        const isFuture = value > 0; // Future if value is positive
        const today = datetimeUTC(new Date());
        const tomorrow = datetimeUTC(new Date()).add(1, "days");
        const date = isFuture ? tomorrow.add(value, "days") : today.add(value, "days");

        const dates = {
          from: isFuture ? tomorrow.toDate() : date.toDate(),
          to: isFuture ? date.toDate() : today.toDate(),
        };

        // Specific options require "from" and "to" to be the same day
        if (label === "Prior Day" || label === "Today" || label === "Tomorrow") {
          dates.to = dates.from;
        }

        return dates;
      }

      function resetQuickOptions() {
        $quickDayTextInput.val("");
        $optionList.jqxTree("clearSelection");

        if (self.options && self.options["onQuickOptionSelect"]) {
          self.options["onQuickOptionSelect"](null, "Clear", { from: null, to: null });
        }

        if (isWidgetInitialized) {
          self.setDates({ from: null, to: null });
          invokeValueChangeCallBackFn({ fromDate: null, toDate: null });

          if (self._gridId_ && self.options["gridOptions"]["columnName"]) {
            self.removeColumnDateFilterFromGrid()
          }
        }
      }

      // If widget is part of grid this will be set below as a placeholder
      // reference so we still have access to the grid's default widget for use cases
      // outside of the grid's create filter widget functionality.
      self._gridWidgetSelector_ = null;
      self._gridWidgetParentSelector_ = null;
      self._gridId_ = null;

      if (bindToElement != null) {
        var $container =
          typeof bindToElement == "object"
            ? $(bindToElement)
            : $("#" + bindToElement);
        $container.html($dateRangeContainer);
      } else if (
        self.options["gridOptions"] != null &&
        self.options["gridOptions"]["gridWidget"] != null
      ) {
        self._gridId_ = self.options["gridOptions"]["gridId"] || null;
        self._gridWidgetSelector_ = self.options["gridOptions"]["gridWidget"];
        self._gridWidgetParentSelector_ = self._gridWidgetSelector_.parent();

        if ($(self._gridWidgetSelector_).length) {
          $(self._gridWidgetSelector_).replaceWith($dateRangeContainer);
        }
      }

      $fromInput.jqxDateTimeInput({
        showFooter: true,
        height: widgetHeight,
        width: getInputWidthSetting(width),
        formatString: dateFormat,
        placeHolder: dateFormat.toUpperCase(),
        showCalendarButton: false,
        dropDownHorizontalAlignment: "left",
        animationType: animationType,
        value: null,
        min: minAllowedDate,
        max: maxAllowedDate,
      });

      $toInput.jqxDateTimeInput({
        showFooter: true,
        height: bindToElement ? "23" : widgetHeight,
        width: getInputWidthSetting(width),
        formatString: dateFormat,
        placeHolder: dateFormat.toUpperCase(),
        showCalendarButton: false,
        dropDownHorizontalAlignment: "right",
        animationType: animationType,
        value: null,
        min: minAllowedDate,
        max: maxAllowedDate,
      });

      // Set input text to be readable, fit inside elements
      var _fontSize = self.options["gridOptions"] != null ? "10px" : "12px";
      var _textAlign = self.options["gridOptions"] != null ? "left" : "center";
      $fromInput
        .find("input")
        .css({ "font-size": _fontSize, "text-align": _textAlign });
      $toInput
        .find("input")
        .css({ "font-size": _fontSize, "text-align": _textAlign });

      // update our '-' separator element position
      var sepOffset = options["gridOptions"] != null ? 15 : 5;
      $separator.css("left", $fromInput.innerWidth() + sepOffset);

      // Check if we have options to set.
      if (self.options != null && typeof self.options === "object") {
        setWidgetOptions();
      }

      applyDefaultBindings();

      // need to set these flags at the class level
      var inputChangeType = null;
      var isFromDateChanged = false;
      var fromDateOriginalValue = null;
      var isToDateChanged = false;
      var toDateOriginalValue = null;
      // Handle calendar button clicks
      var isFromCalPopupOpen = false;
      // Applies calendar popup and default bindings to widget
      function applyDefaultBindings() {
        //var orgCalCellVal = null;
        $calendarBtn.on("click", function (event) {
          if (isFromCalPopupOpen == false) {
            $fromInput.jqxDateTimeInput("open");
            $fromInput.find("input").blur();

            //if ($('td.jqx-calendar-cell.jqx-calendar-cell-selected').length) {

            //    orgCalCellVal = $('td.jqx-calendar-cell.jqx-calendar-cell-selected').html();

            //}

            isFromCalPopupOpen = true;
          }
        });

        // Add visual style to input box when calendar pop up is open, closed.
        // Gives the date inpute a border to denote which input the calendar pop-up selection
        // will set a date for.
        $toInput.on("open", function (event) {
          var el = $(this);
          el.addClass("ge-date-control-focus");
        });

        $toInput.on("close", function (event) {
          var el = $(this);
          el.removeClass("ge-date-control-focus");

          el = $(this);
          var date = el.jqxDateTimeInput("getDate");
          var fromDate = $fromInput.jqxDateTimeInput("getDate");
          el.removeClass("ge-date-control-focus");

          // Most likley user selected clear here...
          if (date == null && fromDate != null) {
            if (self.options["gridOptions"] != null) {
              self.removeColumnDateFilterFromGrid(true);
            } else {
              self.resetInputFields();
              invokeValueChangeCallBackFn({ fromDate: null, toDate: null });
            }
          }
        });

        $fromInput.on("open", function (event) {
          var el = $(this);

          el.addClass("ge-date-control-focus");
        });

        // Check if user selected 'clear' -> clear both inputs or a date -> open 'to' picker;
        $fromInput.on("close", function (event) {
          var el = $(this);
          var date = el.jqxDateTimeInput("getDate");
          var toDate = $toInput.jqxDateTimeInput("getDate");
          el.removeClass("ge-date-control-focus");
          //var calCellVal = null;

          //if ($('td.jqx-calendar-cell.jqx-calendar-cell-selected').length) {

          //    calCellVal = $('td.jqx-calendar-cell.jqx-calendar-cell-selected').html();

          //}

          // User selected 'clear' on the 'from' calendar popup so clear both so we don't have
          // a null from input with a 'to' input that has a date
          if (date == null && toDate != null) {
            if (self.options["gridOptions"] != null) {
              self.removeColumnDateFilterFromGrid(true);
            } else {
              self.resetInputFields();
              invokeValueChangeCallBackFn({ fromDate: null, toDate: null });
            }
          } else if (date != null && isFromCalPopupOpen && isFromDateChanged) {
            $toInput.jqxDateTimeInput("open");
          }

          isFromDateChanged = false;
          isFromCalPopupOpen = false;
        });

        // On change set the corresponding calendar picker min or max date selection allowed.
        // So we don't have the choice to select a 'from' date that is later than the 'to' date and vice versa.
        // i.e User selects a from date of '01/01/2020' -> We prevent the user selecting an end date before that (vice versa)

        $fromInput.on("change", function (event) {
          var jsDate = event.args.date;
          //var toDate = $toInput.jqxDateTimeInput('getDate');

          if (isWidgetInitialized) {
            if (jsDate != null) {
              inputChangeType = event.args.type;

              fromDateOriginalValue = event.args.oldValue
                ? event.args.oldValue
                : null;
              isFromDateChanged = event.args
                ? event.args.oldValue != event.args.newValue
                : false;

              // Set the 'to' input min date so user cannot select before the 'from' date in picker
              //$toInput.jqxDateTimeInput({ min: jsDate });
            } else {
              $toInput.jqxDateTimeInput({ min: minAllowedDate });
            }
          }
        });

        $toInput.on("change", function (event) {
          var jsDate = event.args.date;

          //var fromDate = $fromInput.jqxDateTimeInput('getDate');
          if (isWidgetInitialized) {
            if (jsDate != null) {
              inputChangeType = event.args.type;

              toDateOriginalValue = event.args.oldValue
                ? event.args.oldValue
                : null;
              isToDateChanged = event.args
                ? event.args.oldValue != event.args.newValue
                : false;

              // Set the 'from' input max date so user cannot select later than the 'from' date in picker
              //$fromInput.jqxDateTimeInput({ max: jsDate });
            } else {
              $fromInput.jqxDateTimeInput({ max: maxAllowedDate });
            }
          }
        });

        $fromInput.find("input").on("focusin", function (event) {
          var el = $(this);
          el.addClass("focused");

          fromDateOriginalValue = el.jqxDateTimeInput("getDate");
        });

        $toInput.find("input").on("focusin", function (event) {
          var el = $(this);
          //el.find('input').addClass('focused');
          el.addClass("focused");

          toDateOriginalValue = el.jqxDateTimeInput("getDate");
        });
      }

      // ADDITIONAL OPTIONS, GRID OPTIONS APPLIED HERE
      // Work through and set any self.options
      function setWidgetOptions() {
        var gridOptions = self.options["gridOptions"];
        var initialFromDate = self.options["initialFromDate"] || null;
        var initialToDate = self.options["initialToDate"] || null;

        if (initialFromDate != null && isWidgetInitialized == false) {
          $fromInput.jqxDateTimeInput("setDate", new Date(initialFromDate));
        }

        if (initialToDate != null && isWidgetInitialized == false) {
          $toInput.jqxDateTimeInput("setDate", new Date(initialToDate));
        }

        // Set up call back fn
        // Returns value obj {from: date, to: date}
        if (
          valueChangeCallBackFn != null &&
          typeof valueChangeCallBackFn === "function"
        ) {
          var fromDate = null;
          var toDate = null;

          $fromInput.on("valueChanged", function (event) {
            fromDate = event.args.date;
            toDate = $toInput.jqxDateTimeInput("getDate");

            if (isWidgetInitialized)
              invokeValueChangeCallBackFn({
                fromDate: fromDate,
                toDate: toDate,
              });
          });

          $toInput.on("valueChanged", function (event) {
            toDate = event.args.date;

            fromDate = $fromInput.jqxDateTimeInput("getDate");

            if (isWidgetInitialized)
              invokeValueChangeCallBackFn({
                fromDate: fromDate,
                toDate: toDate,
              });
          });
        }

        // GRID OPTIONS SETUP ---
        if (gridOptions != undefined && typeof gridOptions === "object") {
          var gridId = gridOptions["id"] || gridOptions["gridId"];
          var columnName = gridOptions["columnName"];

          var isFilter = gridOptions["isFilter"] || false;
          var gridStateObj = gridOptions["state"] || {};
          //var _gridWidgetEl = gridOptions['gridWidget'];

          if (isGridOptionsValid(gridId, columnName)) {
            var $grid = $("#" + gridId);
            self._filterColumnName = columnName;

            //----------------------------
            // GRID STATE FOR THE FILTERS
            // Add grid state if we have the option
            if (
              gridStateObj &&
              isWidgetInitialized === false &&
              initialFromDate == null &&
              initialToDate == null
            ) {

              const { filters } = gridStateObj;

              if ((filters?.filterscount || filters?.length) && (!self.options['initQuickDateOptions']
                  || Object.keys(self.options['initQuickDateOptions'] ?? {}).length === 0)) {

                var filterIndexAccessors = [];

                $.each(filters, function (key, value) {
                  var lastChar = /.$/.exec(key)[0];
                  if (
                    !isNaN(lastChar) &&
                    filterIndexAccessors.indexOf(lastChar) == -1
                  ) {
                    filterIndexAccessors.push(lastChar);
                  }

                });

                for (var i = 0; i < filterIndexAccessors.length; i++) {

                  // get from date
                  if (
                    filters["filtertype" + i] === "datefilter" &&
                    filters["filterdatafield" + i] === columnName &&
                    filters["filtervalue" + i] != null
                  ) {
                    $fromInput.jqxDateTimeInput(
                      "setDate",
                      new Date(filters["filtervalue" + i])
                    );
                    $toInput.jqxDateTimeInput("setDate", new Date()); // setting a default 'to' date here in case none is found, if found gets set in block below

                    // Get the next item in the array that matches and check if we have an end date
                    if (
                      filters["filtertype" + (i + 1)] === "datefilter" &&
                      filters["filterdatafield" + (i + 1)] === columnName &&
                      filters["filtervalue" + (i + 1)] != null
                    ) {
                      $toInput.jqxDateTimeInput(
                        "setDate",
                        new Date(filters["filtervalue" + (i + 1)])
                      );
                      i = i + 1; // skip next one
                    }
                  }
                }
              }
            }

            //------------------
            // Setup/Bind our filtering logic.
            if (isFilter) {
              $dateRangeContainer.attr({ id: "ge-datefilter-" + columnName }); // only need to apply id if widget is grid filter, this is used by checkforChanges()
              // Update the element id to be is easier for query selection on grid
              // i.e. -> $('#columnName_FromDatePicker).jqxDateTimeInput('getDate');
              var fromId = self.options["setWidgetId"] || columnName;
              var toId = self.options["setWidgetId"] || columnName;
              $fromInput.attr("id", fromId + "_FromDatePicker");
              $toInput.attr("id", toId + "_ToDatePicker");

              // Bind to calendar pop up closing (current flow for picker is 'from' opens, then 'to' opens) and what
              // we are doing here is whenever 'to' closes, check if dates are changed, if true -> perform search.
              $toInput.on("close", function (event) {
                if (isFromDateChanged || isToDateChanged) {
                  applyOrResetFiltersAndEventBindings(columnName, $grid);
                }
              });

              // Check if need to apply filter and search on any input changes when user
              // focus out of the input (only if they are not still focused in any of our date inputs)
              // i.e. user clicks on grid, page, somewhere else, etc
              $fromInput.find("input").focusout(function (event) {
                var el = $(this);
                el.removeClass("focused");

                setTimeout(function () {
                  var notActive =
                    $(document.activeElement).is("input") == false;
                  if (notActive && isValidForFiltering()) {
                    applyOrResetFiltersAndEventBindings(columnName, $grid);
                  } else if (notActive) {
                    var fromDate = $fromInput.jqxDateTimeInput("getDate");
                    var toDate = $toInput.jqxDateTimeInput("getDate");

                    if (
                      fromDate == null &&
                      toDate == null &&
                      isFromCalPopupOpen == false
                    ) {
                      self.removeColumnDateFilterFromGrid();
                    }
                  }
                }, 0);
              });

              // Check if need to apply filter and search on any input changes when user
              // focus out of the input (only if they are not still focused in any of our date inputs)
              $toInput.find("input").on("focusout", function (event) {
                var el = $(this);
                el.removeClass("focused");

                setTimeout(function () {
                  var notActive =
                    $(document.activeElement).is("input") == false;
                  if (notActive && isValidForFiltering()) {
                    applyOrResetFiltersAndEventBindings(columnName, $grid);
                  } else if (notActive) {
                    var fromDate = $fromInput.jqxDateTimeInput("getDate");
                    var toDate = $toInput.jqxDateTimeInput("getDate");

                    if (
                      fromDate == null &&
                      toDate == null &&
                      isFromCalPopupOpen == false
                    ) {
                      self.removeColumnDateFilterFromGrid();
                    }
                  }
                }, 0);
              });

              // Capture ENTER key -> do search
              $fromInput.keydown(function (e) {
                var code = e.keyCode || e.which;
                var ENTERKEY = 13;

                if (code === ENTERKEY) {
                  applyOrResetFiltersAndEventBindings(columnName, $grid);
                }
              });

              // Capture ENTER key -> do search
              $toInput.keydown(function (e) {
                var code = e.keyCode || e.which;
                var ENTERKEY = 13;

                if (code === ENTERKEY) {
                  applyOrResetFiltersAndEventBindings(columnName, $grid);
                }
              });

            }

            // Create grid helper instance if we don't already have one.
            if (parent.__gridWidgetHelperInstanceIds.indexOf(gridId) == -1) {
              parent.GEWidgetsGridHelper(gridId);
            }


            // Store in our repo collection to access later
            if (isWidgetInitialized == false) {
              parent.__dateRangeFilterCollectionStore.push({
                gridId: gridId,
                columnName: columnName,
                widget: self,
              });
            }

            // Watcher method to reapply widgets when they are cleared.
            checkForChanges($grid);
          }
        }
      }

      // Remove Filter From Grid and refresh the data contents
      self.removeColumnDateFilterFromGrid = function (refreshGrid) {
        const $grid = $("#" + self._gridId_);
        self.resetInputFields();

        if($grid.length && self.options["gridOptions"]["columnName"]) {
          $grid.jqxGrid("removefilter", self.options["gridOptions"]["columnName"]);

          if (refreshGrid) {
            self.refreshGridData($grid);
          }
        }
      };

      // Everything is rendered and bindings applied
      isWidgetInitialized = true;

      //-----------------------------
      // Helper functions
      // self.'name' -> binds the function to the model so can be access like a public access modifier.
      // normal funtion declarations -> private access modifiers
      self.getStartDate = function () {
        return parseDate($fromInput.jqxDateTimeInput("getDate"));
      };

      self.getEndDate = function () {
        return parseDate($toInput.jqxDateTimeInput("getDate"));
      };

      self.setStartDate = function (dateObj) {
        if (
          dateObj != null &&
          typeof dateObj === "object" &&
          dateObj instanceof Date
        ) {
          $fromInput.jqxDateTimeInput("setDate", dateObj);
        }
      };

      self.setEndDate = function (dateObj) {
        if (
          dateObj != null &&
          typeof dateObj === "object" &&
          dateObj instanceof Date
        ) {
          $toInput.jqxDateTimeInput("setDate", dateObj);
        }
      };

      // Returns an obj with from-to dates
      // @param 'format' - string, or moment code format
      self.getDates = function (format) {
        //var _format = format || 'L';
        var fromDate = self.getStartDate(); // ? self.getStartDate().format(_format) : null;
        var toDate = self.getEndDate(); // ? self.getEndDate().format(_format) : null;
        return { from: fromDate, to: toDate };
      };

      // {from: Date Instance or Date String, to: Date instance or Date String}
      self.setDates = function (datesObj) {
        datesObj = datesObj || {};

        self.setStartDate(new Date(datesObj.from));
        self.setEndDate(new Date(datesObj.to));
      };

      // Clears the jqx widget inputs
      self.resetInputFields = function () {
        // $toInput.jqxDateTimeInput({ value: null });
        // $fromInput.jqxDateTimeInput({ value: null });
        $toInput.jqxDateTimeInput("setDate", null);
        $fromInput.jqxDateTimeInput("setDate", null);
      };

      // Util to rebind (replace) any html node with the widget.
      // Used also to repaint/render the widget on grids as jqx likes to repaint
      // and wipe out, but not rebind any custom widgets for some reason....
      self.rebindTo = function (htmlNode, widget) {
        var _nodeToReplace =
          htmlNode instanceof jQuery ? htmlNode : $(htmlNode);
        var _widget = widget != null ? widget : self.$widgetEl;

        //_nodeToReplace.replaceWith(_widget);

        var columnName = self._filterColumnName;
        var $grid = $("#" + self._gridId_);

        if ($grid.length > 0) {
          var index = $grid.jqxGrid("getcolumnindex", columnName);
          var _$filterrow = $grid.find("div.jqx-grid-cell-filter-row").first();
          var _$nodeContainer = $(_$filterrow[0]["cells"][index]);

          var _customWidget = _$nodeContainer.find(_widget);
          var _gridDefaultWidget = _$nodeContainer.find(_nodeToReplace);

          if (_customWidget.length == 0) {
            if (_gridDefaultWidget.length > 0) {
              _gridDefaultWidget.replaceWith(_widget);
            } else {
              // using append here instead of replaceWith, as we know we have the containing node at this point, but still possible
              // we don't have the default input generated by the grid to replaceWith.
              var getRidOfThisEl = _$nodeContainer
                .find("input.jqx-widget.jqx-input")
                .first();
              if (getRidOfThisEl.length) getRidOfThisEl.remove();

              _$nodeContainer.append(_widget);
            }

            checkForChanges($grid);
          }
        }
      };

      function invokeValueChangeCallBackFn(datesObj) {
        if (
          valueChangeCallBackFn != null &&
          typeof valueChangeCallBackFn === "function" &&
          datesObj
        ) {
          valueChangeCallBackFn({
            from: datesObj.fromDate,
            to: datesObj.toDate,
          });
        }
      }

      // Validate the options passed
      function isGridOptionsValid(gridId, columnName) {
        return (
          gridId != undefined &&
          gridId != "" &&
          typeof gridId === "string" &&
          columnName != undefined &&
          columnName != "" &&
          typeof columnName === "string"
        );
      }

      // Get a calc value to set the input width
      function getInputWidthSetting(width) {
        var calcWidth = Math.floor(width / 2);
        var implicitMinWidth = 73; // The minimum we are allowing to prevent super small input fields.

        return calcWidth < implicitMinWidth ? implicitMinWidth : calcWidth;
      }

      // Get a date format based on the dateformat member
      function parseDate(date) {
        try {
          var _f = dateFormat == "MM/dd/yyyy" ? "MM/DD/YYYY" : dateFormat; // jqx & dayjs has a mismatch on this type of format
          date = dayjs(date).format(_f);
        } catch (error) {
          date = null;
        }

        return date;
      }

      // Validate Date
      function isValidDate(date) {
        var isValid = false;
        if (date != null) {
          //var parsedDate = parseDate(date);
          isValid = dayjs(date).isValid();
        }
        return isValid;
      }

      // Apply filter to grid
      function addColumnDateFiltersToGrid(column, $gridObj) {
        $gridObj = $gridObj || $("#" + self._gridId_);

        var startDate = $fromInput.jqxDateTimeInput("getDate");
        var endDate = $toInput.jqxDateTimeInput("getDate");

        if (isValidDate(startDate) && isValidDate(endDate)) {
          startDate.setHours(0, 0, 0, 0);
          endDate.setHours(0, 0, 0, 0);

          const currentFilters = $gridObj.jqxGrid('getfilterinformation');

          var filtergroup = new $.jqx.filter();

          var filtercondition = "GREATER_THAN_OR_EQUAL";
          var startDateFilter = filtergroup.createfilter(
            "datefilter",
            startDate,
            filtercondition
          );

          var filtercondition2 = "LESS_THAN_OR_EQUAL";
          var endDateFilter = filtergroup.createfilter(
            "datefilter",
            endDate,
            filtercondition2
          );

          var filter_or_operator = 1;
          filtergroup.addfilter(filter_or_operator, startDateFilter);
          filtergroup.addfilter(filter_or_operator, endDateFilter);



          $gridObj.jqxGrid("addfilter", column, filtergroup);
          $gridObj.jqxGrid("applyfilters");
        }
      }

      function applyColumnDateFilterToGrid($gridObj) {
        $gridObj.jqxGrid("applyfilters");
      }

      self.refreshGridData = function ($gridObj) {
        if ($gridObj != null) {
          $gridObj.jqxGrid("refreshdata");
          $gridObj.jqxGrid("render");
        }
      };

      function applyOrResetFiltersAndEventBindings(columnName, $grid) {
        $grid = $grid || $("#" + self._gridId_);
        var sDate = $fromInput.jqxDateTimeInput("getDate");
        var eDate = $toInput.jqxDateTimeInput("getDate");

        if (eDate != null && sDate != null) {
          addColumnDateFiltersToGrid(columnName, $grid);
        } else {
          self.removeColumnDateFilterFromGrid(true);
        }

        isToDateChanged = false;
        isFromDateChanged = false;
      }

      function isFromDateValidForFiltering() {
        var fromDate = $fromInput.jqxDateTimeInput("getDate");
        var toDate = $toInput.jqxDateTimeInput("getDate");
        //var isToInputFocused = $toInput.find('input').hasClass('focused');

        var isFromDateChanged =
          fromDate != null && fromDateOriginalValue != null
            ? fromDate.toString() != fromDateOriginalValue.toString()
            : false;

        return fromDate != null && toDate != null && isFromDateChanged; //&& isToInputFocused == false;
      }

      function isToDateValidForFiltering() {
        var fromDate = $fromInput.jqxDateTimeInput("getDate");
        var toDate = $toInput.jqxDateTimeInput("getDate");
        //var isFromInputFocused = $fromInput.find('input').hasClass('focused');

        var isToDateChanged =
          toDate != null && toDateOriginalValue != null
            ? toDate.toString() != toDateOriginalValue.toString()
            : false;

        return fromDate != null && toDate != null && isToDateChanged; // && isFromInputFocused == false;
      }

      // validate we have two dates to search against
      function isValidForFiltering() {
        var fromDate = $fromInput.jqxDateTimeInput("getDate");
        var toDate = $toInput.jqxDateTimeInput("getDate");

        var isFromDateChanged =
          fromDate != null && fromDateOriginalValue != null
            ? fromDate.toString() != fromDateOriginalValue.toString()
            : false;
        var isToDateChanged =
          toDate != null && toDateOriginalValue != null
            ? toDate.toString() != toDateOriginalValue.toString()
            : false;

        return (
          fromDate != null &&
          toDate != null &&
          (isToDateChanged || isFromDateChanged)
        );
      }

      // Watcher function to check if widgets is still on grid
      // If no then rebind them.
      var _watcher = null;
      function checkForChanges($grid) {
        var _check = $grid.find("#ge-datefilter-" + self._filterColumnName);
        if (isWidgetInitialized && _check.length == 0) {
          self.rebindTo(self._gridWidgetSelector_);
        } else {
          _watcher = setTimeout(checkForChanges, 500, $grid);
        }
      }

      self.runWatcher = function ($grid) {
        if ($grid == null) return false;
        clearTimeout(_watcher);
        checkForChanges($grid);
      };

      document.dispatchEvent(new CustomEvent('geCustomDateWidgetLoaded', {
        detail: { dates: self.getDates() }
      }))
    };

    //=====================================================
    // GRID HELPER FUNCTIONS FOR USE WITH CUSTOM WIDGETS

    // IN MEMORY STORES FOR ALREADY INITIALIZED WIDGETS
    parent.__gridWidgetHelperInstanceIds = [];
    parent.__dateRangeFilterCollectionStore = [];

    // HELPER FUNCTIONS FOR WHEN USING WITH GRID
    parent.GEWidgetsGridHelper = function (
      gridId,
      { onFilter = undefined, validFilterValues = [] } = {}
    ) {
      var self = this;
      var _processing = false;
      var _virtualModeSettingsApplied = false;
      var _bindingsApplied = false;
      var $grid =
        gridId != null
          ? $("#" + gridId).length
            ? $("#" + gridId)
            : null
          : null;

      // If we don't already have an instance of the gridId then apply bindings
      var alreadyExists =
        gridId != null && parent.__gridWidgetHelperInstanceIds.length > 0
          ? parent.__gridWidgetHelperInstanceIds.indexOf(gridId) != -1
          : false;
      if (alreadyExists == false) {
        _init();
      }

      // This should only be called once per grid and widget grid id grouping
      function _init() {
        // Add instance id to collection
        parent.__gridWidgetHelperInstanceIds.push(gridId);

        if (!$grid) return false;

        $grid.on("bindingcomplete", function () {
          if (_processing == false) {
            self.reloadDateRangeWidgets();
          }

          if (_bindingsApplied == false) {
            if (_virtualModeSettingsApplied == false) {
              //-------------------------------------------
              // ADD FILTERING FOR THEN VIRTUALMODE = FALSE
              // (VirtualMode) when true the grid sends all requests to server. When false
              // grid performs all filtering client side. Let's bind to that so our filters
              // filter in virtualmode = false;
              var isVirtualMode = $grid.jqxGrid("virtualmode");
              if (isVirtualMode == false) {
                $grid.jqxGrid({
                  filter: (
                    cellValue,
                    rowData,
                    dataField,
                    filterGroup,
                    defaultFilterResult
                  ) => {
                    if (onFilter) {
                      return onFilter(
                        cellValue,
                        rowData,
                        dataField,
                        filterGroup,
                        defaultFilterResult
                      );
                    } else {
                      return self.gridFilteringOverride(
                        cellValue,
                        rowData,
                        dataField,
                        filterGroup,
                        defaultFilterResult
                      );
                    }
                  },
                });
              }

              _virtualModeSettingsApplied = true;
            }
          }

          _bindingsApplied = true;
        });

        // Need to bind to column reordering so we can be sure the grid re-renders
        // the widgets
        $grid.on("columnreordered", function (event) {
          // The magic sauce combo that calls re-rendering of 'createFilterWidget' method
          //$grid.jqxGrid('refreshdata');
          //$grid.jqxGrid('render');

          // Updated to use this method instead
          if (_processing == false) {
            self.reloadDateRangeWidgets(true);
          }
        });
      }

      // The virtualmode=false filtering.
      // By default the grid still performs filtering and passes "it's" filtering result(true/false) via defaultFilerResult.
      // So we are only going to check our datefilters and verify if passes fitering conditions.
      self.gridFilteringOverride = function (
        cellValue,
        rowData,
        dataField,
        filterGroup,
        defaultFilterResult
      ) {
        if (filterGroup == null && cellValue == null)
          return defaultFilterResult;

        var _filters = filterGroup.getfilters();
        var _datafield = dataField;
        var _rowData = rowData;

        const validValues = ["Any", "Please Choose:", ...validFilterValues];
        var _result =
          _filters.some((x) => validValues.indexOf(x.value) != -1) ||
          defaultFilterResult;
        var _cellVal = cellValue;

        if (_filters.length == 0) return _result;

        if (_filters[0].type == "datefilter") {
          //debugger
          var _dateresults = [];
          // Catch any date construction/parsing errors if dates are invalid
          try {
            for (var i = 0; i < _filters.length; i++) {
              var cellDate = new Date(_cellVal);
              var filterDate = new Date(_filters[i].value);
              cellDate.setHours(0, 0, 0, 0);
              filterDate.setHours(0, 0, 0, 0);

              const cellDateUTCTime = cellDate.getTime() / 1000;
              const filterDateUTCTime = filterDate.getTime() / 1000;
              if (_filters[i].condition == "CONTAINS") {
                _dateresults[i] = cellDate.indexOf(filterDate) != -1;
              } else if (_filters[i].condition == "EQUAL") {
                // For some reason the grid is using the default datatype 'date/string' instead of passing in our
                // 'ranged' filter that was applied. As such it is using the 'EQUAL' condition instead of the filter's 'GREATER_THAN_OR_EQUAL', etc.
                // So here for now, we check if date is greater than or equal. At this point the initial date filter condition
                // was the correct one, so should still get intended results.
                _dateresults[i] = cellDateUTCTime >= filterDateUTCTime;
              } else if (_filters[i].condition == "GREATER_THAN") {
                _dateresults[i] = cellDateUTCTime > filterDateUTCTime;
              } else if (_filters[i].condition == "GREATER_THAN_OR_EQUAL") {
                _dateresults[i] = cellDateUTCTime >= filterDateUTCTime;
              } else if (_filters[i].condition == "LESS_THAN_OR_EQUAL") {
                _dateresults[i] = cellDateUTCTime <= filterDateUTCTime;
              } else if (_filters[i].condition == "LESS_THAN") {
                _dateresults[i] = cellDateUTCTime < filterDateUTCTime;
              }
            }

            var dateresult1 = _dateresults[0];
            var dateresult2 = _dateresults[1] != null ? _dateresults[1] : true;

            _result = dateresult1 && dateresult2;
          } catch (error) {
            //console.error(error)
          }
        }

        return _result;
      };

      // Function to add grid filters and proper date ranges using the custom jqxDateRangeWidget
      // to a dataObj being sent for service requests when virtual mode = true;
      // This is usually used in the 'formatData' method of dataAdapter source.
      self.addGridFiltersToDataObj = function (data, options) {
        var _data = data || [];
        var _options = options || {};
        //var applyAllFilters = _options.applyAllFilters || false;
        var dateRangePostFixes = _options.dateRangePostFixes || [];
        var startPostfix = dateRangePostFixes[0] || "Begin";
        var endPostfix = dateRangePostFixes[1] || "End";

        if ($grid) {
          var filterinfo = $grid.jqxGrid("getfilterinformation");
          for (let i = 0; i < filterinfo.length; i++) {
            var filterName = filterinfo[i].datafield;

            if (filterName != null) {
              var filters = filterinfo[i].filter.getfilters();

              if (filters.length && filters[0].type == "datefilter") {
                var date1 = filters[0];
                var date2 = filters[1];
                var d1Value =
                  date1 != null
                    ? date1.value
                    : $("#" + filterName + "_FromDatePicker").length
                    ? $("#" + filterName + "_FromDatePicker").jqxDateTimeInput(
                        "getDate"
                      )
                    : null;
                var d2Value =
                  date2 != null
                    ? date2.value
                    : $("#" + filterName + "_ToDatePicker").length
                    ? $("#" + filterName + "_ToDatePicker").jqxDateTimeInput(
                        "getDate"
                      )
                    : null;

                // only apply filters if we have both a begin and end date (since it's supposed to be a range)

                if (d1Value != null && d2Value != null) {
                  _data[filterName + startPostfix] = dayjs(d1Value)
                    .format("YYYY-MM-DDTHH:mm")
                    .toString();
                  _data[filterName + endPostfix] = dayjs(d2Value)
                    .format("YYYY-MM-DDTHH:mm")
                    .toString();
                }
              }
            }
          }
        }

        return _data;
      };

      // Resets and clears all date range widgets applied to grid
      self.clearDateRangeFiltersFromGrid = function (refreshGrid) {
        if (parent.__dateRangeFilterCollectionStore.length > 0) {
          $.each(
            parent.__dateRangeFilterCollectionStore,
            function (index, item, val) {
              // clears the jqxDateTimeInput date fields (sets to null)
              item.widget.resetInputFields();
              //item.widget.removeColumnDateFilterFromGrid();
              item.widget.runWatcher($grid);
            }
          );

          if (refreshGrid) {
            $grid.jqxGrid("refreshdata");
            $grid.jqxGrid("render");
          }
        }
      };

      // Adds a widget to the collection
      self.addWidgetToCollection = function (gridId, columnName, $widgetObj) {
        if (gridId != null && columnName != null && $widgetObj != null) {
          parent.__dateRangeFilterCollectionStore.push({
            gridId: gridId,
            columnName: columnName,
            widget: $widgetObj,
          });
        }
      };

      // Get the widget from collection
      self.getWidgetFromCollection = function (columnName) {
        var _widget = null;

        // Check if we already have a built widget in our collection
        if (
          parent.__dateRangeFilterCollectionStore.length > 0 &&
          columnName != undefined
        ) {
          $.each(
            parent.__dateRangeFilterCollectionStore,
            function (index, item, val) {
              if (item.columnName === columnName) {
                _widget = item.widget;
                return false; // exit
              }
            }
          );
        }

        return _widget;
      };

      self.getInitalDateRangeForColumnFromArray = function (
        columnName,
        source
      ) {
        var fromDate = null; // apply/set any grid saved date filter
        var toDate = null; // apply/set any grid saved date filter

        if (source && source.length > 0) {
          $.each(source, function (index, item, val) {
            if (item.filterdatafield === columnName) {
              fromDate = item.filtervalue;

              if (
                source[index + 1] != null &&
                source[index + 1].filterdatafield === columnName
              ) {
                toDate = source[index + 1].filtervalue;
                //i = i + 1; // skip next item since we add / use it here
              }

              return false; // break out of loop
            }
          });
        }

        return { fromDate: fromDate, toDate: toDate };
      };

      // Util function to rebind/render the custom date filter widget to grid
      self.reloadDateRangeWidgets = function (runWatcher) {
        _processing = true;
        if (parent.__dateRangeFilterCollectionStore.length > 0) {
          $.each(
            parent.__dateRangeFilterCollectionStore,
            function (index, item, val) {
              var columnName = item.columnName;
              var cIndex = $grid.jqxGrid("getcolumnindex", columnName);
              var _$filterrow = $grid
                .find("div.jqx-grid-cell-filter-row")
                .first();
              var _$selector = $(_$filterrow[0]["cells"][cIndex]);

              var _customWidget = _$selector.find(item.widget.$widgetEl);
              var _gridDefaultWidget = _$selector.find(
                item.widget._gridWidgetSelector_
              );

              if (_customWidget.length == 0) {
                if (_gridDefaultWidget.length > 0) {
                  _gridDefaultWidget.replaceWith(item.widget.$widgetEl);
                } else {
                  // using append here instead of replaceWith, as we know we have the containing node at this point, but still possible
                  // we don't have the default input generated by the grid to replaceWith.
                  var getRidOfThisEl = _$selector
                    .find("input.jqx-widget.jqx-input")
                    .first();
                  if (getRidOfThisEl.length) getRidOfThisEl.remove();
                  _$selector.append(item.widget.$widgetEl);
                }

                if (runWatcher) item.widget.runWatcher($grid);
              }
            }
          );
        }
        _processing = false;
      };
      return self;
    };
  }
}

// Rating Widget -> See MyAgency (Myfavorites, etc) how it can be implemented on grid column.
// CSS: -> Styling has been added to greatedgestyles.css
class StarRating {
  constructor() {
    this.options();
  }

  // Overrides default options for the column
  // Outside the grid column -> supply an object of options listed below -> i.e. StarRatingWidget.options({text: "TESTING", width: 200})
  options(optionsObj) {
    optionsObj = optionsObj || {};

    this.filtertype = "list";
    this.filteritems = [
      {
        value: 5,
        label: "5",
        html: "<img src='../Content/Images/5star.png' />",
      },
      {
        value: 4,
        label: "4",
        html: "<img src='../Content/Images/4star.png' />",
      },
      {
        value: 3,
        label: "3",
        html: "<img src='../Content/Images/3star.png' />",
      },
      {
        value: 2,
        label: "2",
        html: "<img src='../Content/Images/2star.png' />",
      },
      {
        value: 1,
        label: "1",
        html: "<img src='../Content/Images/1star.png' />",
      },
    ];

    this.width = optionsObj.width || 140;
    this.text = optionsObj.text || "Rating";
    this.datafield = optionsObj.datafield || "favoriteValue";
  }

  cellsrenderer(row, columnfield, value, defaultHTML, column, rowData) {
    let counter = 0;

    if (defaultHTML) {
      let grid = $($(this.uielement).parents(".jqx-grid")[0]);
      let pk = rowData.objectId;

      let fieldset = $("<fieldset>");
      fieldset.addClass("star-rating");
      fieldset.attr("id", "jqxRating_" + counter + pk);

      for (let i = 5; i > 0; i--) {
        let input = $("<input>");
        input.attr({
          type: "radio",
          class: "star" + i,
          name: "rating",
          value: i,
        });
        input.appendTo(fieldset);
        let label = $("<label>").text(i);
        if (value == i) {
          label.attr({ for: "star" + i });
        }
        label.insertAfter(input);

        $(document)
          .off("click", "#jqxRating_" + counter + pk)
          .on("click", "#jqxRating_" + counter + pk, (event) => {
            let newRating;
            let previousRating;
            if (event.target.tagName == "LABEL") {
              newRating = event.target.previousSibling.value;
              $(event.target.parentElement.children).each((i) => {
                if (this.tagName == "LABEL") {
                  if (this.attributes.length > 0) {
                    if (this.attributes.for.value != null) {
                      previousRating = this.attributes.for.value.slice(-1);
                    }
                    if (previousRating != newRating) {
                      $(this).removeAttr("for");
                    }
                  }
                }
              });

              if (previousRating != newRating) {
                let inputClass =
                  event.target.previousSibling.attributes.getNamedItem(
                    "class"
                  ).value;
                $(event.target).attr("for", inputClass);
                let id = event.target.parentElement.id.replace(
                  "jqxRating_" + counter,
                  ""
                );

                let payload = {
                  objectId: id,
                  value: newRating,
                  agencyId: userProfile.currentAgencyId(),
                  favTable: rowData.favoriteTableId,
                };

                dataModel.ajaxRequest("Rating", "post", payload).done(() => {
                  if (grid && grid.length)
                    grid.jqxGrid("updatebounddata", "data");
                });
              }
            }
          });
      }

      return fieldset[0].outerHTML;
    }

    counter++;
    return defaultHTML;
  }

  createfilterwidget(column, columnElement, widget) {
    widget.jqxDropDownList({
      dropDownWidth: 140,
      autoDropDownHeight: true,
      enableBrowserBoundsDetection: true,
      renderer(index, label, value) {
        if (value != "") {
          return `<span style="margin-bottom:1px"><img src="../Content/Images/${value}star.png" /></span>`;
        } else {
          return "Any";
        }
      },
    });
  }
}

export const createGeDateRangeFilterWidget =
  (geDateWidget, gridId, initState = {}) =>
  (column, columnElement, widget) => {
    //set widget (class) level options
    var options = {
      gridOptions: {
        gridWidget: widget,
        gridId: gridId, // required to apply gridoptions
        columnName: column.datafield, // also required, especially if this is a filter widget
        isFilter: true, // create the widget as a filter on the grid (sets up event bindings, etc)
        state: initState, // can send our grid state and apply any initial filters
      },
    };

    return geDateWidget.GEDateRangePickerWidget(options);
  };

let geWidgets = {
  StarRatingWidget: new StarRating(),
  GECustomWidgets: new GreatEdgeCustomWidgets(),
};

export const { StarRatingWidget, GECustomWidgets } = geWidgets;
export default GreatEdgeCustomWidgets;
