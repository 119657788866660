import dataModel from "../../../../../data-model";
import ko, { Observable, ObservableArray, Computed } from "knockout";
import template from "./order-entry-dispatch-truckline-component.html";
import {
  showconfirm,
  showmessage,
} from "../../../../shared-components/dialog-components/show-dialog-methods";
import router from "../../../../../router";
import dayjs from "dayjs";
import * as $ from "jquery";
import OrderEntryTracking from "../../order-entry-tracking-component/order-entry-tracking-component";
import { datetimeUTC } from "global-functions";
import { useDispatch } from "ko-data-store";
import { getAdvanceAbility, sendWire } from "dataStore-actions/wireAdvances";
import { isLoading } from "dataStore-actions/appUI";
import { SendWireData } from "../../../../shared-components/efs-advance-component/types";
import efsService from "../../../../shared-components/efs-advance-component/efsAdvanceServices";

import * as _ from "lodash";
import { OrderEntryViewModel } from "../../order-entry-page";

import TNTContext, {
  TRACKINGSTATUS_ENUM,
} from "../../order-entry-track-trace-component/trackTraceOrderEntryContext";

class OrderEntryDispatchViewModel {
  /**@type { Observable<OrderEntryTracking> } */
  orderEntryTracking;
  $tntContext = TNTContext;

  constructor(params) {
    this.orderEntryTracking = params.$orderEntryTracking;
    var self = this;
    self.dispatchService = new DispatchService(self.orderEntryTracking);
    /**@type { OrderEntryViewModel } */
    self.$componentParent = params.$componentParent || {};
    params = params.params();

    var scrollToDispatchSection =
      router.currentRoute()[0]?.dispatch == "true" ||
      router.currentRoute()[1]?.dispatch == "true";
    self.orderId = params.orderId;
    self.orderExId = params.orderExId;
    self.viewingOrderExId = ko.observable(params.orderExId);

    self.orderStatus = self.$componentParent.summary().status;
    self.weight = params.weight;
    self.minWeight = params.minWeight;
    self.isHazmat = params.isHazmat;
    self.driverCarrier = params.driverCarrier;
    self.tractorExId = params.tractorExId;
    self.tractorId = params.tractorId;

    self.showLoadingWheel = function (val) {
      self.$componentParent.isLoading(val);
    };
    self.showLoadingWheel(true);

    self.orderEntryErrors = params.orderEntryErrors;

    self.orderMovements = ko.observableArray();
    self.nextMovement = ko.observable(undefined);
    self.tractorCurrentMovements = ko.observableArray();

    //Exposing orderMovements to order-entry.js so that it can access trailers.
    params.orderMovements = self.orderMovements;

    //These are just references back to the self.movements and self.tractorCurrentMovements arrays
    //Leaving the original orderMovements array intact to minimize changes and bugs for GE-659.
    self.movementPairsDisplay = ko.observableArray();

    self.alternateErrorModalError = ko.observable(); //There's 1 specific error that the showmessage method doesn't work for.  Using this instead.

    //In LME, movements can be placed in available status and stops left as voided.  Block dispatch for all stops when this happens.
    self.voidedStopMovements = ko.observable();
    self.loadMovements = function () {
      self.dispatchService
        .getMovements(self.orderId)
        .then(function (data) {
          //Tractor Current Movement (shown before available movements, when first movementStatus == 'Available')

          var indexCounter = 0;
          data.movementPairs.forEach(function (movementPair) {
            //The sequence that comes from the database isn't reliable, we must make our own.
            movementPair.orderMovement.index = indexCounter;

            var om = new Movement(movementPair.orderMovement, self);
            self.orderMovements()[indexCounter] = om;

            if (movementPair.tractorCurrentMovement) {
              movementPair.tractorCurrentMovement.index = indexCounter;
              var tcm = new Movement(movementPair.tractorCurrentMovement, self);
              self.tractorCurrentMovements()[indexCounter] = tcm;
              self.movementPairsDisplay.push(
                new MovementPair(
                  self.orderMovements()[indexCounter],
                  self.tractorCurrentMovements()[indexCounter]
                )
              );
            } else {
              self.movementPairsDisplay.push(
                new MovementPair(self.orderMovements()[indexCounter])
              );
            }

            indexCounter++;
          });

          self.voidedStopMovements(
            data.movementPairs
              .filter(function (move) {
                return move.orderMovement.stops.some(function (stop) {
                  return stop.stopStatus == "Voided";
                });
              })
              .map(function (move) {
                return move.movementExternalId;
              })
          );

          if (data.tractorNextMovement) {
            var m = new Movement(data.tractorNextMovement[0], self);
            m.isTractorNextMovement(true);
            self.nextMovement(m);
          }

          if (scrollToDispatchSection) {
            $("html, body").animate(
              {
                scrollTop:
                  $("#orderEntryDispatchDiv").first().offset().top - 120,
              },
              "slow"
            );
          }
          self.showLoadingWheel(false);

          //If orderStatus is delivered check to see if we have any non delivered movements.  Display error if we do.
          if (
            self.orderStatus() == "Delivered" &&
            self.orderMovements().some((x) => x.movementStatus() != "Delivered")
          ) {
            showmessage(
              "There is a problem with your order.  The order is delivered but the movements aren't.  Please contact helpdesk."
            );
          }

          //If we have delivered movements, make sure all Stops are delivered and display an error if not.
          if (
            self
              .orderMovements()
              .some(
                (x) =>
                  x.movementStatus() == "Delivered" &&
                  x.stops().some((x) => x.stopStatus() != "Delivered")
              )
          ) {
            showmessage(
              "There is a problem with your order.  The movements are delivered but 1 or more of the stops aren't.  Please contact helpdesk."
            );
          }

          self.loadTrackingMovements(self.orderMovements());
          self.orderMovements.subscribe(self.loadTrackingMovements);
        })
        .catch(function (err) {
          self.showLoadingWheel(false);
          console.error(err);
          showmessage("An error has occurred.");
          if (err != "An error has occurred.") {
            dataModel.addClientSideLog(err); //We should've already caught and dealt with this message elsewhere.  No need to log it again
          }
        });
    };

    self.addTractorNextMovement = function (tractorid) {
      self.dispatchService
        .getTractorNextMovement(tractorid)
        .then(function (data) {
          if (data && data[0]) {
            var movement = new Movement(data[0], self);
            movement.isTractorNextMovement(true);
            self.nextMovement(movement);
          }
        })
        .catch(function (err) {
          self.showLoadingWheel(false);
          console.error(err);
          showmessage("An error has occurred.");
          if (err != "An error has occurred.") {
            dataModel.addClientSideLog(err);
          }
        });
    };

    self.nextOrderSearchOrder = ko.observable();

    self.nextOrderSearch = function () {
      if (
        self.nextOrderSearchOrder() &&
        self.nextOrderSearchOrder() != self.orderId
      ) {
        self.showLoadingWheel(true);
        self.dispatchService
          .getNextOrderMovement(self.nextOrderSearchOrder())
          .then(function (movement) {
            self.showLoadingWheel(false);
            self.nextOrderSearchOrder(null);

            movement.tractorId = self
              .orderMovements()
              [self.orderMovements().length - 1].tractor();
            movement.tractorExId = self
              .orderMovements()
              [self.orderMovements().length - 1].tractorExId();
            movement.driver1Id = self
              .orderMovements()
              [self.orderMovements().length - 1].driver1();
            movement.driver1ExId = self
              .orderMovements()
              [self.orderMovements().length - 1].driver1ExId();
            movement.driver2Id = self
              .orderMovements()
              [self.orderMovements().length - 1].driver2();
            movement.driver2ExId = self
              .orderMovements()
              [self.orderMovements().length - 1].driver2ExId();
            movement.trailerId = self
              .orderMovements()
              [self.orderMovements().length - 1].trailer();
            movement.trailerExId = self
              .orderMovements()
              [self.orderMovements().length - 1].trailerExId();

            var av = new Movement(movement, self);
            av.isTractorNextMovement(true);
            self.nextMovement(av);
          });
      }
    };

    self.validationOverridePayMethodIds = () => {
      return ko.validation.group(
        [
          self.orderMovements().map((x) => {
            return x.overridePayMethodId;
          }),
          self.tractorCurrentMovements().map((x) => {
            if (x) {
              return x.overridePayMethodId;
            }
          }),
          self.nextMovement()?.overridePayMethodId,
        ]
          .filter((x) => {
            return x;
          })
          .flat()
      );
    };

    self.saveDispatchObj = function () {
      //Theres nothing wrong with how we're checking for driver errors below, don't fix it.
      //No equipment should be saved if we have an issue with one of the drivers.
      var errors = [];
      var movements = self.orderMovements().map(function (move) {
        if (move.driver1() && move.blockDriver1Save()) {
          errors.push(
            "Driver 1 is invalid on movement " +
              move.movementExternalId() +
              ".  Equipment was not saved."
          );
        }
        if (move.driver2() && move.blockDriver2Save()) {
          errors.push(
            "Driver 2 is invalid on movement " +
              move.movementExternalId() +
              ".  Equipment was not saved."
          );
        }
        return {
          movementId: move.movementId(),
          tractorId: (function () {
            if (
              (move.driver1() && move.blockDriver1Save()) ||
              (move.driver2() && move.blockDriver2Save())
            ) {
              return null;
            } else if (typeof move.tractor() != "number") {
              return null;
            } else {
              return move.tractor();
            }
          })(),
          driver1Id: (function () {
            if (
              (move.driver1() && move.blockDriver1Save()) ||
              (move.driver2() && move.blockDriver2Save())
            ) {
              return null;
            } else if (typeof move.driver1() != "number") {
              return null;
            } else {
              return move.driver1();
            }
          })(),
          driver2Id: (function () {
            if (
              (move.driver1() && move.blockDriver1Save()) ||
              (move.driver2() && move.blockDriver2Save())
            ) {
              return null;
            } else if (typeof move.driver2() != "number") {
              return null;
            } else {
              return move.driver2();
            }
          })(),
          trailerId: (function () {
            if (
              (move.driver1() && move.blockDriver1Save()) ||
              (move.driver2() && move.blockDriver2Save())
            ) {
              return null;
            } else if (typeof move.trailer() != "number") {
              return null;
            } else {
              return move.trailer();
            }
          })(),
          equipmentGroupId: move.equipmentGroupId(),
          containerReturned: move.containerReturned(),
          trailerDrop: move.trailerDrop(),
          overridePayAmount: move.overridePayAmount(),
          overridePayRate: move.overridePayRate(),
          overrideUnits: move.overrideUnits(),
          overridePayMethodId: move.overridePayMethodId()
            ? move.overridePayMethodId().value ?? move.overridePayMethodId()
            : 0,
          overridePayeeId: move.overridePayeeId(),
          movementUIType: "orderMovement",
        };
      });

      if (self.nextMovement()) {
        if (
          self.nextMovement().driver1() &&
          self.nextMovement().blockDriver1Save()
        ) {
          errors.push(
            "Driver 1 is invalid on movement " +
              self.nextMovement().movementExternalId() +
              ".  Equipment was not saved."
          );
        }
        if (
          self.nextMovement().driver2() &&
          self.nextMovement().blockDriver2Save()
        ) {
          errors.push(
            "Driver 2 is invalid on movement " +
              self.nextMovement().movementExternalId() +
              ".  Equipment was not saved."
          );
        }
        movements.push({
          movementId: self.nextMovement().movementId(),
          tractorId: (function () {
            if (
              (self.nextMovement().driver1() &&
                self.nextMovement().blockDriver1Save()) ||
              (self.nextMovement().driver2() &&
                self.nextMovement().blockDriver2Save())
            ) {
              return null;
            } else if (typeof self.nextMovement().tractor() != "number") {
              return null;
            } else {
              return self.nextMovement().tractor();
            }
          })(),
          driver1Id: (function () {
            if (
              (self.nextMovement().driver1() &&
                self.nextMovement().blockDriver1Save()) ||
              (self.nextMovement().driver2() &&
                self.nextMovement().blockDriver2Save())
            ) {
              return null;
            } else if (typeof self.nextMovement().driver1() != "number") {
              return null;
            } else {
              return self.nextMovement().driver1();
            }
          })(),
          driver2Id: (function () {
            if (
              (self.nextMovement().driver1() &&
                self.nextMovement().blockDriver1Save()) ||
              (self.nextMovement().driver2() &&
                self.nextMovement().blockDriver2Save())
            ) {
              return null;
            } else if (typeof self.nextMovement().driver2() != "number") {
              return null;
            } else {
              return self.nextMovement().driver2();
            }
          })(),
          trailerId: (function () {
            if (
              (self.nextMovement().driver1() &&
                self.nextMovement().blockDriver1Save()) ||
              (self.nextMovement().driver2() &&
                self.nextMovement().blockDriver2Save())
            ) {
              return null;
            } else if (typeof self.nextMovement().trailer() != "number") {
              return null;
            } else {
              return self.nextMovement().trailer();
            }
          })(),
          equipmentGroupId: self.nextMovement().equipmentGroupId(),
          containerReturned: self.nextMovement().containerReturned(),
          trailerDrop: self.nextMovement().trailerDrop(),
          overridePayAmount: self.nextMovement().overridePayAmount(),
          overridePayRate: self.nextMovement().overridePayRate(),
          overrideUnits: self.nextMovement().overrideUnits,
          overridePayMethodId: self.nextMovement().overridePayMethodId()
            ? self.nextMovement().overridePayMethodId().value
            : 0,
          overridePayeeId: self.nextMovement().overridePayeeId(),
          movementUIType: "nextMovement",
        });
      }

      self.tractorCurrentMovements().forEach(function (value) {
        if (value) {
          if (value.driver1() && value.blockDriver1Save()) {
            errors.push(
              "Driver 1 is invalid on movement " +
                value.movementExternalId() +
                ".  Equipment was not saved."
            );
          }
          if (value.driver2() && value.blockDriver2Save()) {
            errors.push(
              "Driver 2 is invalid on movement " +
                value.movementExternalId() +
                ".  Equipment was not saved."
            );
          }
          movements.push({
            movementId: value.movementId(),
            tractorId: (function () {
              if (
                (value.driver1() && value.blockDriver1Save()) ||
                (value.driver2() && value.blockDriver2Save())
              ) {
                return null;
              } else if (typeof value.tractor() != "number") {
                return null;
              } else {
                return value.tractor();
              }
            })(),
            driver1Id: (function () {
              if (
                (value.driver1() && value.blockDriver1Save()) ||
                (value.driver2() && value.blockDriver2Save())
              ) {
                return null;
              } else if (typeof value.driver1() != "number") {
                return null;
              } else {
                return value.driver1();
              }
            })(),
            driver2Id: (function () {
              if (
                (value.driver1() && value.blockDriver1Save()) ||
                (value.driver2() && value.blockDriver2Save())
              ) {
                return null;
              } else if (typeof value.driver2() != "number") {
                return null;
              } else {
                return value.driver2();
              }
            })(),
            trailerId: (function () {
              if (
                (value.driver1() && value.blockDriver1Save()) ||
                (value.driver2() && value.blockDriver2Save())
              ) {
                return null;
              } else if (typeof value.trailer() != "number") {
                return null;
              } else {
                return value.trailer();
              }
            })(),
            equipmentGroupId: value.equipmentGroupId(),
            containerReturned: value.containerReturned(),
            trailerDrop: value.trailerDrop(),
            overridePayAmount: value.overridePayAmount(),
            overridePayRate: value.overridePayRate(),
            overrideUnits: value.overrideUnits,
            overridePayMethodId: value.overridePayMethodId()
              ? value.overridePayMethodId().value
              : 0,
            overridePayeeId: value.overridePayeeId(),
            movementUIType: "tractorCurrentMovement",
          });
        }
      });

      if (errors.length > 0) {
        dataModel.addClientSideLog(JSON.stringify(errors));
        showmessage(errors);
      }

      return movements;
    };

    //Putting this on the params object so we can access on the orderEntry page.
    params.saveOrderEntryDispatch = function (skipRefresh) {
      let payMethodIdValidation = self.validationOverridePayMethodIds();
      try {
        return new Promise(function (resolve, reject) {
          if (payMethodIdValidation().length > 0) {
            showmessage(payMethodIdValidation()[0]);
            reject();
          } else {
            self.dispatchService
              .saveDispatch(self.saveDispatchObj())
              .then(function (value) {
                if (value && value.errors) {
                  const readyToBillError = value.readyToBillError;
                  const errorOnOrderExId = value.errorOnOrderExId;

                  if (
                    !readyToBillError ||
                    (readyToBillError && self.orderExId == errorOnOrderExId)
                  ) {
                    showmessage(value.errors);
                  }
                }
                if (!skipRefresh) {
                  Promise.all([
                    self.orderMovements().map(function (element) {
                      return element.refreshEquipment();
                    }),
                  ]).then(function () {
                    resolve();
                  });

                  if (self.nextMovement()) {
                    self.nextMovement().refreshEquipment();
                  }
                }
                resolve();
              })
              .catch((err) => {
                if (err) {
                  showmessage(err);
                }
              });
          }
        });
      } catch (err) {
        if (err) {
          showmessage("An error has occured");
          console.error(err);
          if (err != "An error has occurred.") {
            dataModel.addClientSideLog(err);
          }
        }
      }
    };

    //Need this to create the preassignment when delivering out the prior movement.
    self.saveOrderEntryDispatch = params.saveOrderEntryDispatch;

    self.fuelLimit = self.$componentParent.summary().fuelLimit;

    self.showMovementElement = function (elem) {
      if (elem[1].nodeType === 1) {
        $(elem[1]).hide().slideDown(1000);
      }
    };

    self.hideMovementElement = function (elem) {
      if (elem.nodeType === 1) {
        $(elem).slideUp(1000, function () {
          $(elem).remove();
        });
      }
    };

    self.loadMovements();
    self.tractorPreassignmentModal = ko.observable();

    self.passwordDialog = ko.observable();
    self.orderEntryDispatchCancelDispatchModal = ko.observable();
    self.pTADateModal = ko.observable();

    self.loadTrackingMovements = (movements = []) => {
      const moves = ko.toJS(movements);

      (moves ?? []).forEach((x) => {
        TNTContext.loadTrackingForMovement(x.movementExternalId, {
          movementStatus: x.movementStatus,
          movementSequence: x.movementSequence,
          movementId: x.movementId,
          tracking: {
            orderId: self.orderExId,
            driver1Name: x.driver1ExId,
            trucklineAssignment: {
              assignedTractor: x.tractorExId,
              hasELD: x.tractorHasELD,
            },
            carrierAssignment: {}
          },
        });
      });
    };
  }
}

class MovementPair {
  constructor(om, tcm) {
    this.orderMovement = ko.observable(om);
    this.tractorCurrentMovement = ko.observable(tcm);
    return this;
  }
}

//Data Classes
class Movement {
  hazmat;
  tractor;
  tractorExId;
  driver1ExId;
  driver1;
  weight;
  minWeight;
  /**@type { OrderEntryDispatchViewModel } */
  $parent;
  /**@type { ObservableArray<Stop> } */
  stops;
  /**@type { Observable<boolean> } */
  includeInactiveDrivers;
  /**@type { Observable<Driver> } */
  driver1InfoObject;
  /**@type { Observable<Driver> } */
  driver2InfoObject;
  /**@type { Computed<boolean> } */
  areDriversInputsDisabled;

  constructor(movementData, parent) {
    var self = this;
    self.$parent = parent;
    self.dispatchAction = useDispatch();

    self.$tntContext = TNTContext;
    self.tractorHasELD = movementData.tractorHasELD;

    self.index = movementData.index; //0 based
    self.movementId = ko.observable(movementData.id);
    self.movementExternalId = ko.observable(movementData.movementExternalId);
    self.movementStatus = ko.observable();

    self.movementStatus.subscribe((val) => {
      TNTContext.updateTrackedMovementStatus(self.movementExternalId(), val);

      // So stops can listen for this update
      document.dispatchEvent(new CustomEvent('orderEntryMovementStatusChanged', {
        detail: { movementId: self.movementId(), movementStatus: val }
      }))
    });

    self.showTrackingForMovement = ko.pureComputed(() => {
      if (self.$tntContext.isTrackingRequired()) {
        return true;
    }
      if (
        ["In Progress", "Available", "Covered"].indexOf(
          self.movementStatus()
        ) === -1
      ) {
        return false;
      }

      return self.isPartOfViewingOrder() && self.$tntContext.isTrackingOn();
    });

    self.movementStatus(movementData.movementStatus);

    self.originalMovementStatus = ko.observable(movementData.movementStatus);
    self.tractorPreviousUnclearedMovementExId = ko.observable(
      movementData.tractorPreviousUnclearedMovementExId
    );
    // Building this link in the html doesn't work
    self.tractorPreviousUnclearedOrderExId = ko.observable(
      movementData.tractorPreviousUnclearedOrderExId !=
        self.$parent.viewingOrderExId
        ? "<a target='_blank' href='/NewOrder/" +
            movementData.tractorPreviousUnclearedOrderExId +
            "'>" +
            movementData.tractorPreviousUnclearedOrderExId +
            "<a>"
        : null
    );
    self.isCurrentTractorMovement = ko.observable(
      movementData.isCurrentTractorMovement
    );
    self.isTractorNextMovement = ko.observable();

    self.movementSequence = ko.observable(movementData.sequence);
    self.tractorCurrentEquipmentGroupId = ko.observable();
    self.tractorLastLocation = ko.observable();

    self.driverPlanningComment = ko.observable();
    self.tractorPlanningComment = ko.observable();
    self.trailerPlanningComment = ko.observable();

    self.tractorPreassignments = ko.observable();
    self.driver1InfoObject = ko.observable();
    self.driver2InfoObject = ko.observable();
    self.loaded = ko.observable(movementData.loaded);
    self.moveDistance = ko.observable(movementData.moveDistance);
    self.containerReturned = ko.observable(
      movementData.containerReturned || false
    );
    self.trailerDrop = ko.observable(movementData.trailerDropped || false);
    self.equipmentGroupId = ko.observable(movementData.equipmentGroupId);
    self.equipmentGroupCurrentMovementId = ko.observable(
      movementData.equipmentGroupCurrentMovementId
    );
    self.sequence = ko.observable(movementData.sequence);
    self.isLastMovementInSequence = ko.observable(
      movementData.isLastMovementInSequence
    );
    self.isFirstMovementInSequence = ko.observable(
      movementData.isFirstMovementInSequence
    );
    self.tractorsPreviousMovementId = ko.observable();
    self.tractorsPreviousMovementCleared = ko.observable();
    self.manifestExId = ko.observable(movementData.manifestExId);
    self.includeInactiveDrivers = ko.observable(true);

    self.orderExIds = ko.observable(
      movementData.movementOrders
        .map(function (value) {
          return value.orderExId;
        })
        .filter(function (value, index, self) {
          return self.indexOf(value) === index;
        })
    );
    self.orderIds = ko.observable(
      movementData.movementOrders
        .map(function (value) {
          return value.orderId;
        })
        .filter(function (value, index, self) {
          return self.indexOf(value) === index;
        })
    );
    self.tractorOwnerId = ko.observable();
    self.tableIdentifier = self.movementExternalId();
    self.previousEmptyMovementEquipmentGroupId = ko.observable(
      movementData.previousEmptyMovementEquipmentGroupId
    );
    self.previousEmptyMovementId = ko.observable(
      movementData.previousEmptyMovementId
    );

    self.tendered = ko.observable(movementData.tendered);

    self.isPartOfViewingOrder = ko.observable(
      self.orderIds().some(function (value) {
        return value == ko.unwrap(self.$parent.orderId);
      })
    );

    self.fuelLimit = ko.observable(movementData.fuelLimit);
    if (self.index == 0 && self.isPartOfViewingOrder()) {
      self.fuelLimit = self.$parent.fuelLimit;
    } else {
      self.fuelLimit = self.$parent.orderMovements()[0].fuelLimit;
    }

    self.fuelCardType = ko.observable();

    self.driver1 = ko.observable(
      movementData.driver1ExId ? movementData.driver1ExId.trim() : undefined
    );
    self.driver2 = ko.observable(
      movementData.driver2ExId ? movementData.driver2ExId.trim() : undefined
    );
    self.trailer = ko.observable(
      movementData.trailerExId ? movementData.trailerExId.trim() : undefined
    );

    self.driver2ExId = ko.observable(movementData.driver2ExId);
    self.trailerExId = ko.observable(movementData.trailerExId);
    self.tractorOwnerId = ko.observable();
    self.tractorSelectedValue = ko.observable();
    self.driver1SelectedValue = ko.observable();
    self.trailerSelectedValue = ko.observable();
    self.trailerSelectedValue.subscribe(function (tr) {
      tr = tr || {};
      self.trailerExId(tr.code);

      self.$parent.dispatchService.getPlanningComments(tr.code).then((data) => {
        if (data) {
          self.trailerPlanningComment(data[0]);
        }
      });
    });
    self.areDriversInputsDisabled = ko.computed(() => {
      return !self.tractorSelectedValue();
    });

    if (self.isPartOfViewingOrder()) {
      self.hazmat = self.$parent.isHazmat;
      self.weight = ko.observable(movementData.weight);
      self.minWeight = ko.observable(movementData.minWeight);

      if (self.index == 0) {
        self.tractor = self.$parent.tractorId ?? ko.observable();
        self.tractorExId =
          self.$parent.$componentParent.summary().tractorExId ??
          ko.observable();
        self.driver1ExId =
          self.$parent.$componentParent.driverCarrier ?? ko.observable();
      } else {
        self.tractor = ko.observable();
        self.tractorExId = ko.observable();
        self.driver1ExId = ko.observable();
      }
      self.tractor(
        movementData.tractorExId ? movementData.tractorExId.trim() : undefined
      );

      self.tractorExId(movementData.tractorExId);
      self.tractorSelectedValue.subscribe(function (t) {
        if (t) {
          self.tractorExId(t.code);
          self.tractorOwnerId(t.ownerId);
          self.$parent.dispatchService
            .getPlanningComments(t.code)
            .then((data) => {
              if (data) {
                self.trailerPlanningComment(data[0]);
              }
            });
        } else {
          self.tractorExId(undefined);
          self.tractorOwnerId(undefined);
          self.tractorPlanningComment(undefined);
        }

        const trackedMove = TNTContext.selectTrackedMovement(
          self.movementExternalId()
        );

        TNTContext.upsertTrackedMovement(trackedMove().movementExId, {
          tracking: {
            trucklineAssignment: {
              assignedTractor: t?.code,
              hasELD: t?.hasELD,
            },
            carrierAssignment: {}
          },
        });
      });

      self.driver1ExId(movementData.driver1ExId);
      let driver1Loaded = false;
      self.driver1SelectedValue.subscribe(function (d1) {
        if (d1) {
          self.driver1ExId(d1.code);

          dataModel
            .ajaxRequest("EquipmentGroup/PlanningComments", "GET", {
              DriverExId: d1.code,
            })
            .done(function (data) {
              if (data) self.driverPlanningComment(data[0]);
            });

          efsService
            .getFuelCardsByDriver(d1.id)
            .then((result) => self.fuelCardType(result.data.cardType));
        } else {
          self.driver1ExId(undefined);
          self.driverPlanningComment(undefined);
        }

        const trackedMove = TNTContext.selectTrackedMovement(
          self.movementExternalId()
        );

        if(!movementData.tractorHasELD
            && TNTContext.isTrackingOn()
            && d1
            && driver1Loaded
            && trackedMove().tracking.driver1Name !== (d1.code ?? d1.externalId))  {
          TNTContext.updateSlice({sliceKey: "showDriverPhoneModal", payload: true})
        }

        TNTContext.upsertTrackedMovement(trackedMove().movementExId, {
          tracking: {
            driver1Name: d1?.code ?? d1?.externalId,
            driver1Phone: "",
          },
        });

        driver1Loaded = true;
      });

    } else {
      self.hazmat = ko.observable(
        movementData.movementOrders.some(function (value) {
          return value.hazmat == true;
        })
      );
      self.tractor = ko.observable(
        movementData.tractorExId ? movementData.tractorExId.trim() : undefined
      );
      self.tractorExId = ko.observable(movementData.tractorExId);
      self.driver1ExId = ko.observable(movementData.driver1ExId);
      self.driver1 = ko.observable(
        movementData.driver1ExId ? movementData.driver1ExId.trim() : undefined
      );
      self.weight = parent.weight;
      self.minWeight = parent.minWeight;
    }

    self.tractor.extend({
      required: {
        message: () => {
          return "Tractor is required for Dispatch.";
        },
        validator: true,
      },
    });
    self.driver1.extend({
      required: {
        message: () => {
          return "Driver 1 is required for Dispatch.";
        },
        validator: true,
      },
    });

    self.blockDriver1Save = ko.computed(function () {
      if (!self.driver1() || typeof self.driver1() == "string") {
        return true;
      } else if (
        self.driver1InfoObject() &&
        self.driver1InfoObject().blockDispatch() == true
      ) {
        return true;
      } else if (
        self.driver1InfoObject() &&
        self.driver1InfoObject().canHaulHazmat() == false &&
        self.hazmat() == true
      ) {
        return true;
      } else {
        return false;
      }
    });

    self.blockDriver2Save = ko.computed(function () {
      if (!self.driver2() || typeof self.driver2() == "string") {
        return true;
      } else if (
        self.driver2InfoObject() &&
        self.driver2InfoObject().blockDispatch() == true
      ) {
        return true;
      } else if (
        self.driver2InfoObject() &&
        self.driver2InfoObject().canHaulHazmat() == false &&
        self.hazmat() == true
      ) {
        return true;
      } else {
        return false;
      }
    });

    self.readyToBill = ko.observable(movementData.readyToBill);

    self.currentOrAvailableMovementDisplayText = ko.computed(function () {
      if (self.isCurrentTractorMovement() == true) {
        return "Current";
      } else if (self.isTractorNextMovement()) {
        return "Available";
      } else {
        if (self.movementStatus() != "Available") {
          return "Current";
        } else {
          return "Available";
        }
      }
    });

    self.preassignmentSequence = ko.computed(function () {
      if (!self.tractorPreassignments()) {
        return null;
      } else {
        var preassignment = self.tractorPreassignments().filter(function (pre) {
          return pre.movementId == self.movementId();
        })[0];
        if (preassignment) {
          return preassignment.sequence;
        } else {
          return null;
        }
      }
    });

    self.showMovementNotFirstPreassigned = ko
      .computed(function () {
        if (!self.tractor()) {
          return false;
        }

        if (
          self.movementStatus() == "In Progress" ||
          self.movementStatus() == "Delivered"
        ) {
          return false;
        }

        if (
          self.tractorPreassignments() &&
          self.tractorPreassignments().length != 0
        ) {
          var firstPreassignedMovement = self
            .tractorPreassignments()
            .filter(function (x) {
              return x.sequence == 1;
            });
          if (
            firstPreassignedMovement[0] &&
            firstPreassignedMovement[0].movementId == self.movementId()
          ) {
            return false;
          } else {
            return true;
          }
        } else {
          return false;
        }
      })
      .extend({ rateLimit: 1000 });

    self.isTractorReadOnly = ko.computed(function () {
      if (
        self.movementStatus() == "In Progress" ||
        self.movementStatus() == "Delivered"
      ) {
        return true;
      } else {
        return false;
      }
    });

    self.isDriversReadOnly = ko.computed(function () {
      if (
        self.movementStatus() == "In Progress" ||
        self.movementStatus() == "Delivered"
      ) {
        return true;
      } else {
        return false;
      }
    });

    self.isTrailerReadOnly = ko.computed(function () {
      if (self.movementStatus() == "Delivered") {
        return true;
      } else {
        return false;
      }
    });

    self.overridePayRate = ko.observable(movementData.overridePayRate);
    self.overridePayeeId = ko.observable(movementData.overridePayeeId);
    self.overridePayMethodId = ko
      .observable(movementData.overridePayMethod)
      .extend({
        required: {
          params: true,
          message: "If you enter an override pay you must select a pay method.",
          onlyIf: () => {
            return self.overridePayRate() != null && self.overridePayRate() > 0;
          },
        },
      });
    self.overridePayMethodId(movementData.overridePayMethod);

    self.overrideUnitsInput = ko.observable(movementData.overridePayUnits);
    self.overrideUnits = ko.computed(function () {
      if (self.overridePayMethodId()) {
        let overridePayMethodValue =
          self.overridePayMethodId().value ?? self.overridePayMethodId();
        if (overridePayMethodValue) {
          if (overridePayMethodValue == 1) {
            //CWT
            if (
              (self.weight() > self.minWeight()
                ? self.weight()
                : self.minWeight()) > 0
            ) {
              return (
                (self.weight() > self.minWeight()
                  ? self.weight()
                  : self.minWeight()) / 100
              );
            } else {
              return 1;
            }
          } else if (overridePayMethodValue == 2) {
            //Distance
            return self.moveDistance();
          } else if (overridePayMethodValue == 3) {
            //Flat
            return 1;
          } else if (overridePayMethodValue == 4) {
            //Tons
            if (
              (self.weight() > self.minWeight()
                ? self.weight()
                : self.minWeight()) > 0
            ) {
              return (
                (self.weight() > self.minWeight()
                  ? self.weight()
                  : self.minWeight()) / 2000
              );
            } else {
              return 1;
            }
          } else if (overridePayMethodValue == 5) {
            //Other
            return self.overrideUnitsInput();
          }
        } else {
          return 0;
        }
      }
    });

    self.overridePayeeIdSelectedValue = ko.observable();

    self.overridePayeeLoadedPayRate = ko.observable();
    self.overridePayeeMinimumPay = ko.observable();
    self.overridePayeeIdSelectedValue.subscribe((x) => {
      if (
        x &&
        self.$parent.orderMovements().length == 1 &&
        x.defaultPayMethod == "Percentage" &&
        x.loadedPayRate
      ) {
        let freightCharge = this.$parent.$componentParent
          .billing()
          .freightCharge();
        let percDecimal = x.loadedPayRate / 100;
        self.overridePayeeLoadedPayRate(x.loadedPayRate);
        self.overridePayeeMinimumPay(freightCharge * percDecimal);
        if (!self.overridePayRate() && self.overridePayeeMinimumPay()) {
          self.overridePayRate(self.overridePayeeMinimumPay());
          self.overridePayMethodId(3);
        }
      } else {
        self.overridePayeeMinimumPay(undefined);
        self.overridePayeeLoadedPayRate(undefined);
      }
    });

    self.overrideUnits.subscribe(function (value) {
      if (self.overridePayMethodId() && self.overridePayMethodId().value != 5) {
        self.overrideUnitsInput(value);
      }
    });

    self.overridePayAmount = ko.computed(function () {
      var charge = self.overridePayRate() * self.overrideUnits();
      return isNaN(charge) ? 0 : charge;
    });

    self.contractedRateMessage = () => {
      return `The contracted rate for this payee is ${self.overridePayeeLoadedPayRate()}%.
                    You cannot pay less than $${self.overridePayeeMinimumPay()}. 
                    If you have any questions please contact the Settlement department.`;
    };

    if (!self.isCurrentTractorMovement() && !self.isTractorNextMovement()) {
      self.overridePayAmount.extend({
        min: {
          params: self.overridePayeeMinimumPay,
          onlyIf: () => {
            return !!self.overridePayeeMinimumPay();
          },
          message: self.contractedRateMessage,
        },
      });
    }

    //Putting an extend on the computed above doesn't work.
    self.showOverridePayRateRequiredMessage = ko.computed(() => {
      return (
        self.overridePayRate() != null &&
        self.overridePayRate() > 0 &&
        self.overridePayMethodId() == null
      );
    });

    self.isPayUnitsReadOnly = ko.computed(function (value) {
      if (!self.overridePayMethodId()) {
        return true;
      } else if (self.overridePayMethodId().value != "5") {
        return true;
      } else {
        return false;
      }
    });

    self.clearAssignments = async function () {
      if (TNTContext.isTrackingOn() && TNTContext.isMovementTracking(this.movementExternalId())) {
        const yes = await showconfirm(
          `Tracking is turned on for this movement. Clearing Assignment will cancel tracking. Do you want to continue?`
        );
        if (yes === false) {
          return;
        }
      }

      await TNTContext.cancelMovementTracking(self.movementExternalId());

      self.$parent.showLoadingWheel(true);

      self.tractor(null);
      self.driver1(null);
      self.driver2(null);
      self.trailer(null);
      self.overridePayeeId(null);
      self.overridePayRate(null);
      self.overridePayMethodId(null);
      var obj = self.$parent.saveDispatchObj();
      obj.overrideUnits = null;
      self.stops()[0].actualArrival(undefined);
      self.tractorPreassignments(undefined);

      self.$parent.dispatchService
        .saveDispatch(obj)
        .then(function (value) {
          if (value && value.errors) {
            showmessage("An error has occurred.");
          }
          self.$parent.showLoadingWheel(false);
          //self.equipmentUnitMessagesModel(undefined);
        })
        .catch(function (err) {
          if (err) {
            showmessage(err);
          }
          self.$parent.showLoadingWheel(false);
        });
    };

    self.isNextOrderSearchVisible = ko.computed(function () {
      var doesNextOrCurrentMovementExist =
        self.$parent.tractorCurrentMovements().length > 0 ||
        self.$parent.nextMovement() != null;
      return (
        !doesNextOrCurrentMovementExist &&
        (self.preassignmentSequence() == 1 ||
          self.preassignmentSequence() == null) &&
        self.movementStatus() == "Delivered" &&
        self.isLastMovementInSequence()
      );
    });

    self.isClearAssignmentsButtonVisible = ko.computed(function () {
      if (self.movementStatus() != "Available") {
        return false;
      } else if (
        self.$parent.$componentParent.orderLockComponent() &&
        self.$parent.$componentParent.orderLockComponent().lockOrderControls()
      ) {
        return false;
      } else if (
        self.tractor() ||
        self.driver1() ||
        self.trailer() ||
        self.driver2()
      ) {
        return true;
      } else {
        return false;
      }
    });

    self.isTrailerDropReadOnly = ko.computed(function () {
      if (self.movementStatus() == "Delivered") {
        return true;
      } else {
        return false;
      }
    });

    self.showDriversNotQualifiedForHazmat = ko.computed(function () {
      if (self.hazmat()) {
        if (!self.driver1InfoObject() && !self.driver2InfoObject()) {
          return false;
        } else if (self.driver1InfoObject() && !self.driver2InfoObject()) {
          if (self.driver1InfoObject().canHaulHazmat() == false) {
            return true;
          }
        } else if (!self.driver1InfoObject() && self.driver2InfoObject()) {
          if (self.driver2InfoObject().canHaulHazmat() == false) {
            return true;
          }
        } else if (self.driver1InfoObject() && self.driver2InfoObject()) {
          if (
            self.driver2InfoObject().canHaulHazmat() == false ||
            self.driver2InfoObject().canHaulHazmat() == false
          ) {
            return true;
          }
        }
      } else {
        return false;
      }
    });

    self.isChangePreassignmentButtonVisible = ko.computed(function () {
      if (
        self.$parent.$componentParent.orderLockComponent() &&
        self.$parent.$componentParent.orderLockComponent().lockOrderControls()
      ) {
        return false;
      } else {
        return self.showMovementNotFirstPreassigned();
      }
    });

    self.showTractorPreassignmentModal = function () {
      var errors = [];
      if (!self.tractor()) {
        errors.push("Tractor can't be blank.");
      }
      if (!self.driver1()) {
        errors.push("Driver can't be blank.");
      }
      if (self.driver1() & self.driver2() && self.driver1() == self.driver2()) {
        errors.push("Driver 1 can't be same as driver 2.");
      }
      if (errors.length > 0) {
        showmessage(errors);
        return;
      }
      self.$parent.dispatchService
        .getTractorPreassignments(self.tractor())
        .then(function (value) {
          if (value && value.error) {
            showmessage(value.error);
          } else {
            if (
              value.data.filter(function (pr) {
                return pr.movementId == self.movementId();
              }).length == 0
            ) {
              //If the tractor's preassignments doesn't contain the viewing movement, add it to the screen, to be added upon saving.
              var newPreA = {
                date: self.stops()[0].earliestArrival(),
                destination: self.stops()[self.stops().length - 1].location(),
                distance: self.moveDistance(),
                movementId: self.movementId(),
                orderExId: self.orderExIds()[0],
                orderId: self.orderIds()[0],
                origin: self.stops()[0].location(),
                sequence:
                    (value.data ?? [])
                    .map(function (v) {
                      return v.sequence;
                    })
                    .reduce(function (a, b) {
                      return Math.max(a, b);
                    },0) + 1,
                driver1Id: self.driver1(),
                driver2Id: self.driver2(),
                tractorId: self.tractor(),
                trailerId: self.trailer(),
              };
              value.data.push(newPreA);
            }

            var model = {
              preassignments: value.data.sort(function (a, b) {
                return a.sequence > b.sequence ? 1 : -1;
              }),
              result: ko.observable(),
            };
            self.$parent.tractorPreassignmentModal(model);
            model.result.subscribe(function (value) {
              self.$parent.showLoadingWheel(true);
              self.$parent.dispatchService
                .updateTractorPreassignments(value, self.tractor())
                .then(function (result) {
                  if (result && result.error) {
                    showmessage(value.error);
                  } else {
                    self.$parent.orderMovements().forEach(function (move) {
                      move.refreshEquipment();
                      self.$parent.showLoadingWheel(false);
                    });
                  }
                })
                .catch(function (err) {
                  if (err) {
                    showmessage(err);
                  }
                  self.$parent.showLoadingWheel(false);
                });
            });
          }
        });
    };

    self.saveDispatchSingleMovementObj = function () {
      return [
        {
          movementId: self.movementId(),
          tractorId: typeof self.tractor() == "number" ? self.tractor() : null,
          driver1Id: self.blockDriver1Save() ? null : self.driver1(),
          driver2Id: self.blockDriver2Save() ? null : self.driver2(),
          trailerId: typeof self.trailer() == "number" ? self.trailer() : null,
          equipmentGroupId: self.equipmentGroupId(),
          containerReturned: self.containerReturned(),
          trailerDrop: self.trailerDrop(),
          overridePayAmount: self.overridePayAmount(),
          overridePayRate: self.overridePayRate(),
          overrideUnits: self.overrideUnits,
          overridePayMethodId: self.overridePayMethodId()
            ? self.overridePayMethodId().value
            : 0,
          overridePayeeId: self.overridePayeeId(),
          movementUIType: self.isCurrentTractorMovement()
            ? "tractorCurrentMovement"
            : self.isTractorNextMovement()
            ? "nextMovement"
            : "orderMovement",
        },
      ];
    };

    self.refreshEquipment = function () {
      return new Promise(function (resolve, reject) {
        Promise.all([
          self.$parent.dispatchService.getTractorEquipment(self.tractor()),
          self.$parent.dispatchService.getDriverInfo(self.driver1()),
          self.$parent.dispatchService.getDriverInfo(self.driver2()),
          self.$parent.dispatchService.getOrderMovementData({
            movementId: self.movementId(),
          }),
        ]).then(function (lotsOfStuff) {
          var tractorEquipment = lotsOfStuff[0];
          var driver1info = lotsOfStuff[1];
          var driver2info = lotsOfStuff[2];
          var orderMovementData = lotsOfStuff[3];
          if (tractorEquipment) {
            tractorEquipment.previousMovement =
              tractorEquipment.previousMovement || {};
            self.tractorsPreviousMovementCleared(
              tractorEquipment.previousMovement.isMovementCleared
            );
            self.tractorsPreviousMovementId(
              tractorEquipment.previousMovement.previousMovementId
            );
            self.tractorOwnerId(tractorEquipment.tractorOwnerId);
            if (tractorEquipment.lastLocation) {
              self.tractorLastLocation(
                tractorEquipment.lastLocation.city +
                  ", " +
                  tractorEquipment.lastLocation.state +
                  " " +
                  (tractorEquipment.lastLocation.zip || "")
              );
            }
            self.tractorPreassignments(tractorEquipment.preassignments);
            self.tractorCurrentEquipmentGroupId(
              tractorEquipment.equipment.equipmentGroupId
            );
          }
          if (driver1info) {
            self.driver1InfoObject(new Driver(driver1info));
          }
          if (driver2info) {
            self.driver2InfoObject(new Driver(driver2info));
          }
          self.fuelLimit(orderMovementData.fuelLimit);
          self.equipmentGroupCurrentMovementId(
            orderMovementData.equipmentGroupCurrentMovementId
          );
          self.equipmentGroupId(orderMovementData.equipmentGroupId);
          // if(self.isPartOfViewingOrder() && !self.equipmentUnitMessagesModel() && self.tractor() && self.equipmentGroupId()) {
          //     self.equipmentUnitMessagesModel({ equipmentGroupId: self.equipmentGroupId(), useDispatchRecords: true});
          // }
          self.previousEmptyMovementEquipmentGroupId(
            orderMovementData.previousEmptyMovementEquipmentGroupId
          );
          self.previousEmptyMovementId(
            orderMovementData.previousEmptyMovementId
          );
          resolve();
        });
      });
    };

    self.handleCreateWire = async function () {
      try {
        const { wireAdvances } = await self.dispatchAction(getAdvanceAbility());
        const ability = wireAdvances.advanceAbility;

        if (
          !wireAdvances ||
          !ability ||
          (ability &&
            (ability.advanceAbilityEnabled == false ||
              ability.advanceAbilityLockedOut))
        ) {
          showmessage(`You do not have the ability to give advances.`);
          return false;
        }

        self.dispatchAction(isLoading(true));
        const result = await efsService.qualifyWireAdvance({
          orderId: self.orderIds()[0],
          driverId: self.driver1InfoObject().driverId(),
          payeeId: self.driver1InfoObject().payeeId(),
        });
        self.dispatchAction(isLoading(false));

        if (result.isSuccess() == false) {
          let error = result.errors[0];
          showmessage(error);
          return false;
        }

        const wireData = new SendWireData({
          orderExId: self.orderExIds()[0],
          orderId: self.orderIds()[0],
          driverExId: self.driver1InfoObject().externalId(),
          driverId: self.driver1InfoObject().driverId(),
          payeeExId: self.driver1InfoObject().payeeExId(),
          payeeId: self.driver1InfoObject().payeeId(),
          aopLimit: ability.aopLimit,
          fundingLimit: ability.fundingLimit,
          onAddWire: self.handleCreateWireComplete,
          onCancelWire: self.handleCreateWireComplete,
        });

        self.dispatchAction(sendWire(wireData));
      } catch (e) {
        console.error(e);
      }
    };

    self.handleCreateWireComplete = async ({
      wireCode = undefined,
      orderId = undefined,
    }) => {
      if (
        ["F", "X"].some((x) => x == wireCode) &&
        orderId == self.$parent.orderId
      ) {
        // can be 0, so check for undefined
        let fundAmounts = await efsService.getFundAmounts(self.$parent.orderId);

        if (fundAmounts.orderFuelLimit != undefined) {
          self.$parent.fuelLimit(fundAmounts.orderFuelLimit);
          self.refreshEquipment();
        }
      }
    };

    self.isTenderLoadBtnsVisible = ko.pureComputed(() => {
      if (
        self.$parent.$componentParent.orderLockComponent() &&
        !self.$parent.$componentParent.orderLockComponent().lockOrderControls()
      ) {
        return false;
      } else {
        const preassigned = (self.tractorPreassignments() || []).filter(
          (pre) => pre.movementId == self.movementId()
        )[0];
        return (
          self.movementStatus() == "Available" &&
          preassigned &&
          !self.manifestExId() &&
          self.driver1InfoObject()
        );
      }
    });

    self.isCreateWireVisible = ko.computed(function () {
      if (
        self.$parent.$componentParent.orderLockComponent() &&
        !self.$parent.$componentParent.orderLockComponent().lockOrderControls()
      ) {
        return false;
      } else {
        return (
          self.movementStatus() == "In Progress" &&
          !self.manifestExId() &&
          self.driver1InfoObject()
        );
      }
    });

    self.isCancelDispatchVisible = ko
      .computed(function () {
        // as long as it is the current movement for the tractors and drivers AND it has not been marked ready to bill.
        //AND tractors.currentequipmentgroupid = equipmentgroup.id, equipmentgroup.currentmovementid = m.id
        var driver1CurrentEquipmentGroupId = self.driver1InfoObject()
          ? self.driver1InfoObject().equipmentGroupId()
          : undefined;
        var driver2CurrentEquipmentGroupId = self.driver2InfoObject()
          ? self.driver2InfoObject().equipmentGroupId()
          : undefined;
        if (self.readyToBill() == true) {
          return false;
        } else if (
          self.$parent.$componentParent.orderLockComponent() &&
          self.$parent.$componentParent.orderLockComponent().lockOrderControls()
        ) {
          return false;
        } else if (
          !self.previousEmptyMovementId() &&
          self.movementStatus() == "Available"
        ) {
          return false;
        } else if (
          self.tractorCurrentEquipmentGroupId() &&
          self.tractorCurrentEquipmentGroupId() != self.equipmentGroupId() &&
          self.previousEmptyMovementEquipmentGroupId() !=
            self.tractorCurrentEquipmentGroupId()
        ) {
          return false;
        } else if (
          driver1CurrentEquipmentGroupId &&
          driver1CurrentEquipmentGroupId != self.equipmentGroupId() &&
          self.previousEmptyMovementEquipmentGroupId() !=
            driver1CurrentEquipmentGroupId
        ) {
          return false;
        } else if (
          driver2CurrentEquipmentGroupId &&
          driver2CurrentEquipmentGroupId != self.equipmentGroupId() &&
          self.previousEmptyMovementEquipmentGroupId() !=
            driver2CurrentEquipmentGroupId
        ) {
          return false;
        } else if (
          self.equipmentGroupCurrentMovementId() != self.movementId() &&
          self.equipmentGroupCurrentMovementId() !=
            self.previousEmptyMovementId()
        ) {
          return false;
        } else {
          return true;
        }
      })
      .extend({ rateLimit: 500 });

    self.cancelDispatch = async function () {
      const executeCancelDispatch = async (data) => {
        self.$parent.showLoadingWheel(true);
        self.$parent.dispatchService
          .cancelDispatch(data)
          .then(function (result) {
            self.$parent.showLoadingWheel(false);
            if (result && result.errors) {
              showmessage(result.errors);
            } else {
              /////////////// Cancel latest stop ///////////////
              if (
                data.cancelLatestStop &&
                data.moveMovementToAvailableStatus == false
              ) {
                var latestStop = self
                  .stops()
                  .slice()
                  .reverse()
                  .find(function (value) {
                    return (
                      value.stopStatus() == "Delivered" ||
                      value.stopStatus() == "In Progress"
                    );
                  });
                if (latestStop) {
                  latestStop.timeFilledIn = false;
                  latestStop.stopStatus("Available");
                  latestStop.actualArrival(null);
                  latestStop.actualDeparture(null);

                  if (latestStop.sequence() == 1) {
                    //First Stop
                    self.movementStatus("Available");
                    if (
                      self.isFirstMovementInSequence() &&
                      self.isPartOfViewingOrder()
                    ) {
                      self.$parent.orderStatus("Available");
                    }
                  } else if (latestStop.sequence() == self.stops().length) {
                    //Last Stop
                    self.movementStatus("In Progress");
                    if (
                      self.isLastMovementInSequence() &&
                      self.isPartOfViewingOrder()
                    ) {
                      self.$parent.orderStatus("In Progress");
                    }
                    var nextMovement =
                      self.$parent.orderMovements()[self.index + 1];
                    if (nextMovement) {
                      nextMovement.stops()[0].actualArrival(null);
                    }
                  }
                  if (self.stops()[latestStop.sequence()]) {
                    //Actual arrival of current stop might've had a date already so need to clear that also
                    self
                      .stops()
                      [latestStop.sequence()].actualArrival(undefined);
                  }

                  self.$parent.orderMovements().forEach(function (move) {
                    move.refreshEquipment();
                  });

                  self.$parent
                    .tractorCurrentMovements()
                    .forEach(function (move) {
                      if (move) {
                        move.refreshEquipment();
                      }
                    });

                  if (self.$parent.nextMovement()) {
                    self.$parent.nextMovement().refreshEquipment();
                  }
                }
              }

              /////////////////// Move Movement to Available Status /////////////////////////
              else if (
                data.moveMovementToAvailableStatus == true &&
                data.cancelDispatchAndRemoveEquipment == false
              ) {
                self.stops().forEach(function (stop) {
                  stop.stopStatus("Available");
                  stop.actualArrival(null);
                  stop.actualDeparture(null);
                });

                self.movementStatus("Available");

                self.$parent.orderMovements().forEach(function (movement) {
                  movement.refreshEquipment();
                });

                if (self.isPartOfViewingOrder()) {
                  if (self.index == 0) {
                    self.$parent.orderStatus("Available");
                  } else {
                    self.$parent.orderStatus("In Progress");
                  }
                }
              }

              /////////////////// Cancel Dispatch And Remove Equipment /////////////////////////
              else if (data.cancelDispatchAndRemoveEquipment) {
                for (let si = 0; si < self.stops().length; si++) {
                  self.stops()[si].stopStatus("Available");
                  self.stops()[si].actualArrival(null);
                  self.stops()[si].actualDeparture(null);
                }

                self.tractor(null);
                self.driver1(null);
                self.driver2(null);
                self.trailer(null);
                self.overridePayeeId(null);
                self.overridePayRate(null);
                self.overrideUnitsInput(null);
                self.overridePayMethodId(null);

                self.$parent.orderMovements().forEach(function (movement) {
                  movement.refreshEquipment();
                });

                self.movementStatus("Available");
                if (self.isPartOfViewingOrder()) {
                  if (self.index == 0) {
                    self.$parent.orderStatus("Available");
                  } else {
                    self.$parent.orderStatus("In Progress");
                  }
                }
              }
            }
            self.$parent
              .orderEntryTracking()
              .methods()
              .saveUpdateTrackingAndRefreshTimestamp();
          })
          .catch(function (err) {
            self.$parent.showLoadingWheel(false);
          });
      };

      let resultObs = ko.observable();

      let cancelDispatchObj = {
        result: resultObs,
        isPartOfViewingOrder: self.isPartOfViewingOrder(),
        cancelingOrderExId: self.orderExIds()[0],
        cancelingMovementOrigin: self.stops()[0].location(),
        cancelingMovementDestination: self
          .stops()
          [self.stops().length - 1].location(),
      };

      // TODO: see about cancelling a non viewing order tracking
      if (!self.isPartOfViewingOrder()) {
        let viewingMovement = self.$parent.orderMovements()[self.index];
        Object.assign(cancelDispatchObj, {
          viewingOrderExId: viewingMovement.orderExIds()[0],
          viewingMovementOrigin: viewingMovement.stops()[0].location(),
          viewingMovementDestination: viewingMovement
            .stops()
            [viewingMovement.stops().length - 1].location(),
        });
      }
      self.$parent.orderEntryDispatchCancelDispatchModal(cancelDispatchObj);
      resultObs.subscribe(async (result) => {
        var data = {
          cancelDispatchAndRemoveEquipment:
            result.cancelDispatchAndRemoveEquipment(),
          cancelEmptyMovement: result.cancelEmptyMovement()
            ? result.cancelEmptyMovement().value == "Yes"
            : false,
          cancelLatestStop: result.cancelLatestStop(),
          moveMovementToAvailableStatus: result.moveMovementToAvailableStatus(),
          movementId: self.movementId(),
          orderId: self.orderIds()[0],
          orderTracking: self.$parent.orderEntryTracking().data.toJSON(),
        };

        if (TNTContext.isTrackingOn() &&
          TNTContext.isMovementTracking(this.movementExternalId()) &&
          data.cancelDispatchAndRemoveEquipment
        ) {
          const cancelTracking = await showconfirm(
            `Tracking is turned on for this movement. Cancelling dispatch will also cancel tracking. Do you want to continue?`
          );

          if (cancelTracking) {
            await TNTContext.cancelMovementTracking(self.movementExternalId());
            await executeCancelDispatch(data);
          }
        } else {
          await executeCancelDispatch(data);
        }
      });
    };

    Promise.all([
      self.$parent.dispatchService.getTractorEquipment(movementData.tractorId),
      self.$parent.dispatchService.getDriverInfo(movementData.driver1Id),
      self.$parent.dispatchService.getDriverInfo(movementData.driver2Id),
    ]).then(function (lotsOfStuff) {
      var tractorEquipment = lotsOfStuff[0];
      var driver1info = lotsOfStuff[1];
      var driver2info = lotsOfStuff[2];
      if (tractorEquipment) {
        tractorEquipment.previousMovement =
          tractorEquipment.previousMovement || {};
        self.tractorOwnerId(tractorEquipment.tractorOwnerId);
        if (tractorEquipment.lastLocation) {
          self.tractorLastLocation(
            tractorEquipment.lastLocation.city +
              ", " +
              tractorEquipment.lastLocation.state +
              " " +
              (tractorEquipment.lastLocation.zip || "")
          );
        }
        self.tractorPreassignments(tractorEquipment.preassignments);
        self.tractorCurrentEquipmentGroupId(
          tractorEquipment.equipment.equipmentGroupId
        );
        self.tractorsPreviousMovementCleared(
          tractorEquipment.previousMovement.isMovementCleared
        );
        self.tractorsPreviousMovementId(
          tractorEquipment.previousMovement.previousMovementId
        );
      }
      if (driver1info) {
        self.driver1InfoObject(new Driver(driver1info));
        if (
          self.driver1InfoObject().blockDispatch() &&
          self.isPartOfViewingOrder()
        ) {
          showmessage(self.driver1InfoObject().blockReason());
        }
      }
      if (driver2info) {
        self.driver2InfoObject(new Driver(driver2info));
        if (
          self.driver2InfoObject().blockDispatch() &&
          self.isPartOfViewingOrder()
        ) {
          showmessage(self.driver2InfoObject().blockReason());
        }
      }

      //Unfortunately with the dropdowns and how they work, we can't get around not using a setTimeout here. :(
      setTimeout(function () {
        self.driver1.subscribe(function (driverId) {
          if (driverId && typeof driverId == "number") {
            self.$parent.dispatchService
              .getDriverInfo(driverId)
              .then(function (driverInfo) {
                self.includeInactiveDrivers(false);
                if (driverInfo) {
                  self.driver1InfoObject(new Driver(driverInfo));
                  if (
                    self.driver1InfoObject().blockDispatch() &&
                    self.isPartOfViewingOrder()
                  ) {
                    showmessage(self.driver1InfoObject().blockReason());
                  }

                } else {
                  self.driver1InfoObject(null);
                }
              });
          } else {
            self.includeInactiveDrivers(false);
            self.driver1InfoObject(null);
          }
        });

        self.driver2.subscribe(function (driverId) {
          if (driverId) {
            self.$parent.dispatchService
              .getDriverInfo(driverId)
              .then(function (driverInfo) {
                if (driverInfo) {
                  self.driver2InfoObject(new Driver(driverInfo));
                  if (
                    self.driver2InfoObject().blockDispatch() &&
                    self.isPartOfViewingOrder()
                  ) {
                    showmessage(self.driver2InfoObject().blockReason());
                  }
                } else {
                  self.driver2InfoObject(null);
                }
              });
          } else {
            self.driver2InfoObject(null);
          }
        });

        self.tractor.subscribe(function (tractorId) {
          self
            .getTractorEquipmentAndAssignments(tractorId) //This clears the appropriate data if tractorId is null;
            .then(function () {
              if (tractorId) {
                var doesPreviousMovementExistOnScreen = self.$parent
                  .orderMovements()
                  .some(function (value) {
                    return (
                      value.movementId() == self.tractorsPreviousMovementId()
                    );
                  });
                if (doesPreviousMovementExistOnScreen == false) {
                  self.addTractorCurrentMovement(tractorId);
                }
              } else {
                self.removeTractorCurrentMovement();
                self.$parent.nextMovement(undefined);
              }
            });
        });
      }, 2500);
    });

    self.addTractorCurrentMovement = function (tractorid) {
      self.$parent.showLoadingWheel(true);
      self.$parent.dispatchService
        .getTractorCurrentMovement(tractorid)
        .then(function (movement) {
          self.$parent.showLoadingWheel(false);
          if (
            movement &&
            movement.orderMovements[0] &&
            movement.orderMovements[0].movementOrders.some(function (x) {
              return x.orderId == self.$parent.orderId;
            }) == false
          ) {
            var tcmMovement = new Movement(
              movement.orderMovements[0],
              self.$parent
            );
            tcmMovement.index = self.index;
            self.$parent.tractorCurrentMovements()[self.index] = tcmMovement;
            self.$parent
              .movementPairsDisplay()
              [self.index].tractorCurrentMovement(
                self.$parent.tractorCurrentMovements()[self.index]
              );
          }
        });
    };

    self.removeTractorCurrentMovement = function () {
      self.$parent.tractorCurrentMovements()[self.index] = undefined;
      if (self.$parent.movementPairsDisplay()[self.index]) {
        self.$parent
          .movementPairsDisplay()
          [self.index].tractorCurrentMovement(undefined);
      }
    };

    self.getTractorEquipmentAndAssignments = function (tractorId) {
      tractorId = tractorId || self.tractor();
      return new Promise(function (resolve, reject) {
        if (tractorId) {
          self.$parent.showLoadingWheel(true);
          self.$parent.dispatchService
            .getTractorEquipment(tractorId)
            .then(function (info) {
              self.includeInactiveDrivers(true);
              self.$parent.showLoadingWheel(false);
              info.previousMovement = info.previousMovement || {};
              self.tractorOwnerId(info.tractorOwnerId);
              if (info.lastLocation)
                self.tractorLastLocation(
                  info.lastLocation.city +
                    ", " +
                    info.lastLocation.state +
                    " " +
                    (info.lastLocation.zip || "")
                );
              self.driver1(
                info.equipment.driver1ExId
                  ? info.equipment.driver1ExId.trim()
                  : undefined
              );
              self.driver1ExId(info.equipment.driver1ExId);
              self.driver2(
                info.equipment.driver2ExId
                  ? info.equipment.driver2ExId.trim()
                  : undefined
              );
              self.trailer(
                info.equipment.trailerExId
                  ? info.equipment.trailerExId.trim()
                  : undefined
              );
              self.trailerDrop(info.equipment.trailerDrop);
              self.tractorCurrentEquipmentGroupId(info.equipmentGroupId);
              self.tractorPreassignments(info.preassignments);
              self.tractorsPreviousMovementCleared(
                info.previousMovement.isMovementCleared
              );
              self.tractorsPreviousMovementId(
                info.previousMovement.previousMovementId
              );
              resolve();
            });
        } else {
          self.driver1(undefined);
          self.driver2(undefined);
          self.tractorOwnerId(undefined);
          self.tractorLastLocation(undefined);
          self.tractorCurrentEquipmentGroupId(undefined);
          self.tractorPreassignments(undefined);
          self.tractorsPreviousMovementCleared(undefined);
          self.tractorsPreviousMovementId(undefined);
          //self.equipmentUnitMessagesModel(undefined);
          resolve();
        }
      });
    };

    var stops = movementData.stops
      .sort(function (a, b) {
        return a.sequence > b.sequence ? 1 : -1;
      })
      .map(function (stop) {
        return new Stop(stop, self);
      });
    self.stops = ko.observableArray(stops);

    self.movementOrders = ko.observable(
      movementData.movementOrders.map(function (mo) {
        return new MovementOrder(mo);
      })
    );
  }

  handleStartTracking = async () => {
    try {
      await this.$parent.$componentParent.initiateSaveOrderProcess();
      await TNTContext.startMovementTracking(this.movementExternalId());
    } catch (err) {
      console.error(err);
    }
  };
}

class Stop {
  index;
  /** @param { Movement } movement */
  constructor(stop, movement) {
    var self = this;

    const dispatchAction = useDispatch();

    self.updateComputedVariables = ko.observable();
    self.actualArrival = ko.observable(stop.actualArrival);
    self.actualDeparture = ko.observable(stop.actualDeparture);

    self.earliestArrival = ko.observable(stop.earliestArrival);
    self.latestArrival = ko.observable(stop.latestArrival);

    self.fuelLimit = ko.observable(stop.fuelLimit);
    self.id = ko.observable(stop.id);
    self.location = ko.observable(stop.location);
    self.movementId = ko.observable(stop.movementId);
    self.orderExId = ko.observable(stop.orderExId);
    self.sequence = ko.observable(stop.sequence);
    self.stopStatus = ko.observable(stop.stopStatus);

    if (
      stop.stopStatus == "Available" &&
      stop.actualArrival &&
      !stop.actualDeparture
    ) {
      self.stopStatus("In Progress");
    }

    self.isShipper = ko.observable(stop.isShipper);
    self.isConsignee = ko.observable(stop.isConsignee);

    self.stopType = ko.observable(stop.stopType);

    self.getDriverPTADate = function (lastStop) {
      //This method used to hit the server, that's why it returns a promise.
      // if the truck has no pre-assignments, use the final stop or
      // currenttime if > than scheduled arrival/departure. if there
      //are pre-assignments, use the last preassignement arrival/departure at the last stop
      return new Promise(function (resolve, reject) {
        var preassignmentsExcludingThisOne = movement
          .tractorPreassignments()
          .filter(function (pre) {
            return pre.movementId != movement.movementId();
          });

        if (preassignmentsExcludingThisOne.length < 1) {
          var finalStop = movement.stops()[movement.stops().length - 1];

          if (new Date() > new Date(finalStop.earliestArrival())) {
            resolve(datetimeUTC(new Date()));
          } else {
            resolve(datetimeUTC(finalStop.earliestArrival()));
          }
        } else {
          let d = datetimeUTC(
            movement.tractorPreassignments().reduce(function (prev, current) {
              return prev.sequence > current.sequence ? prev : current;
            }).lastStopPTADate
          );
          resolve(d);
        }
      });
    };

    self.completeStop = function () {
      dispatchAction(isLoading(true)); // using page level loader

      const isDispatchOnlyShown =
        movement.$parent.$componentParent.showDispatchOnly();

      let overridepaymethodiderrors =
        movement.$parent.validationOverridePayMethodIds();
      var stopRequest = {
        movementId: movement.movementId(),
        tractorId: movement.tractor(),
        driver1Id: movement.driver1(),
        driver2Id: movement.driver2(),
        trailerId: movement.trailer(),
        equipmentGroupId: movement.equipmentGroupId(),
        containerReturned: movement.containerReturned(),
        trailerDrop: movement.trailerDrop(),
        stops: [
          {
            actualArrival: self.actualArrival(),
            actualDeparture: self.actualDeparture(),
            stopId: self.id(),
            stopUIType: movement.isCurrentTractorMovement()
              ? "tractorCurrentMovement"
              : movement.isTractorNextMovement()
              ? "nextMovement"
              : "orderMovement",
          },
        ],
        movementUIType: movement.isCurrentTractorMovement()
          ? "tractorCurrentMovement"
          : movement.isTractorNextMovement()
          ? "nextMovement"
          : "orderMovement",
      };

      // movement.$parent.orderEntryErrors -> is undefined when dispatch only.
      // Since dispatch only doesn't need all the other sections, then skip this.
      if (
        !isDispatchOnlyShown &&
        movement.$parent.orderEntryErrors()().length > 0
      ) {
        showmessage(movement.$parent.orderEntryErrors()());
        dispatchAction(isLoading(false));
      }
      else if (overridepaymethodiderrors().length > 0) {
        showmessage(overridepaymethodiderrors()[0]);
        dispatchAction(isLoading(false));
      } else if (!self.actualArrival()) {
        showmessage("Actual arrival is required.");
        dispatchAction(isLoading(false));
      } else if (!self.actualDeparture()) {
        //If no actual departure, just save the actualArrival and don't do anything else except put stop in In Progress status
        self.completeActualArrivalOnly(stopRequest);
      } else if (self.actualArrival() > self.actualDeparture()) {
        var d = dayjs(self.actualArrival());
        showmessage(
          "Actual Departure cannot come before " + d.format("MM/DD/YYYY HH:mm")
        );
        dispatchAction(isLoading(false));
      } else if (movement.isTractorNextMovement()) {
        //This movement show BELOW current order movements
        self.completeTractorNextMovement(stopRequest);
      } else if (movement.isCurrentTractorMovement()) {
        //This movement shows ABOVE current order movements
        dispatchAction(isLoading(false));
        self.completeCurrentTractorMovement(stopRequest);
      } else {
        if(movement.$parent && movement.$parent.$componentParent && !movement.$parent.$componentParent.isContainer() && !stopRequest.trailerId && movement.stops().length == self.sequence()) {
          dispatchAction(isLoading(false));
          showmessage("Please enter a valid trailer number. The trailer number is required to complete the dispatch");
        }
        else {
          //These are Movements on this Order
          dispatchAction(isLoading(false));
          self.completeMovementStop(stopRequest);
        }

      }
    };

    self.completeActualArrivalOnly = async function (stopRequest) {
      const sendSaveDispatchMove = async (move) => {
        try {
          const saveResponse =
            await movement.$parent.dispatchService.saveDispatch([move]);

          if (saveResponse && saveResponse.errors) {
            const readyToBillError = saveResponse.readyToBillError;
            const errorOnOrderExId = saveResponse.errorOnOrderExId;

            if (
              !readyToBillError ||
              (readyToBillError && self.orderExId == errorOnOrderExId)
            ) {
              showmessage(saveResponse.errors);
            }
          }

          if (stopRequest.movementId === move.movementId) {
            const completeStopResponse =
              await movement.$parent.dispatchService.completeStop(stopRequest);

            if (completeStopResponse && completeStopResponse.errors) {
              showmessage(completeStopResponse.errors);
            } else {
              if (completeStopResponse != "") {
                showmessage(completeStopResponse);
              }
              self.timeFilledIn = false;
              self.stopStatus("In Progress"); //This is only being used for the FrontEnd, there's no "In Progress" stop status
              if (
                self.sequence() == 1 ||
                self.sequence() == movement.stops().length
              ) {
                if (movement.$parent.orderMovements()[movement.index]) {
                  movement.$parent
                    .orderMovements()
                    [movement.index].refreshEquipment();
                }
                if (
                  movement.$parent.tractorCurrentMovements()[movement.index]
                ) {
                  movement.$parent
                    .tractorCurrentMovements()
                    [movement.index].refreshEquipment();
                }
              }
            }
          }
        } catch (err) {
          if (err) {
            showmessage(err);
          }
        }
      };

      const moves = [
        movement.$parent.orderMovements()[movement.index]
          ? movement.$parent
              .orderMovements()
              [movement.index].saveDispatchSingleMovementObj()[0]
          : undefined,
        movement.$parent.tractorCurrentMovements()[movement.index]
          ? movement.$parent
              .tractorCurrentMovements()
              [movement.index].saveDispatchSingleMovementObj()[0]
          : undefined,
        movement.$parent.nextMovement()
          ? movement.$parent.nextMovement().saveDispatchSingleMovementObj()[0]
          : undefined,
      ].filter((x) => x);

      dispatchAction(isLoading(true));
      for (let i = 0; i < moves.length; i++) {
        await sendSaveDispatchMove(moves[i]);
      }
      dispatchAction(isLoading(false));

      // movement.$parent.showLoadingWheel(true);
      // //Need to save to create the preassignment first, this prevents issues downstream.
      // movement.$parent.dispatchService.saveDispatch([
      //     movement.$parent.orderMovements()[movement.index] ? movement.$parent.orderMovements()[movement.index].saveDispatchSingleMovementObj()[0] : undefined,
      //     movement.$parent.tractorCurrentMovements()[movement.index] ? movement.$parent.tractorCurrentMovements()[movement.index].saveDispatchSingleMovementObj()[0] : undefined,
      //     movement.$parent.nextMovement() ? movement.$parent.nextMovement().saveDispatchSingleMovementObj()[0] : undefined,
      // ]).then(function(value) {
      //     if(value && value.errors) {
      //         showmessage(value.errors);
      //     }
      //     movement.$parent.dispatchService.completeStop(stopRequest)
      //     .then(function(result) {
      //         movement.$parent.showLoadingWheel(false);
      //         if(result && result.errors) {
      //             showmessage(result.errors);
      //         } else {
      //             if(result != "") {
      //                 showmessage(result);
      //             }
      //             self.timeFilledIn = false;
      //             self.stopStatus("In Progress")  //This is only being used for the FrontEnd, there's no "In Progress" stop status
      //             if(self.sequence() == 1 || self.sequence() == movement.stops().length) {
      //                 if(movement.$parent.orderMovements()[movement.index]) {
      //                     movement.$parent.orderMovements()[movement.index].refreshEquipment();
      //                 }
      //                 if(movement.$parent.tractorCurrentMovements()[movement.index]) {
      //                     movement.$parent.tractorCurrentMovements()[movement.index].refreshEquipment();
      //                 }
      //             }
      //         }
      //     })
      // }).catch(function (err) {
      //     if(err) {
      //         showmessage(err)
      //     }
      //     movement.$parent.showLoadingWheel(false);
      // });
    };

    self.completeTractorNextMovement = function (stopRequest) {
      //Doesn't belong to this order(in green).  In this case, if we complete the movement, navigate to that order.
      //If this is the first or last stop, get the PTA date first
      if (movement.stops().length == self.sequence() || self.sequence() == 1) {
        dispatchAction(isLoading(false));

        self.getDriverPTADate().then(function (result) {
          var pta = new PTAModel(result);
          movement.$parent.pTADateModal(pta);
          pta.result().then(function (ptaDate) {
            if (!ptaDate) {
              return; //They didnt ever a PTA date, just return and don't save anything.
            } else {
              //Need to save to create the preassignment first, this prevents issues downstream.
              stopRequest.stops[0].driverPTADate = ptaDate;

              dispatchAction(isLoading(true));

              movement.$parent.dispatchService
                .saveDispatch(movement.saveDispatchSingleMovementObj())
                .then(function (value) {
                  if (value && value.errors) {
                    const readyToBillError = value.readyToBillError;
                    const errorOnOrderExId = value.errorOnOrderExId;

                    if (
                      !readyToBillError ||
                      (readyToBillError && self.orderExId == errorOnOrderExId)
                    ) {
                      showmessage(value.errors);
                    }
                  }
                  movement.$parent.dispatchService
                    .completeStop(stopRequest)
                    .then(function (result) {
                      dispatchAction(isLoading(false));
                      if (result && result.errors) {
                        showmessage(result.errors);
                      } else {
                        if (result != "") {
                          showmessage(result);
                        }
                        router.navigate(
                          "/NewOrder/" +
                            movement.orderExIds()[0] +
                            "?dispatch=true"
                        );
                      }
                    });
                })
                .catch(function (err) {
                  if (err) {
                    showmessage(err);
                  }
                  dispatchAction(isLoading(false));
                });
            }
          });
        });
      } else {
        //If this is NOT the first or last stop.
        dispatchAction(isLoading(true));

        movement.$parent.dispatchService
          .completeStop(stopRequest)
          .then(function (result) {
            dispatchAction(isLoading(false));

            if (result.errors) {
              showmessage(result.errors);
            } else {
              if (result != "") {
                showmessage(result);
              }
              self.stopStatus("Delivered");
            }
          });
      }
    };

    self.completeCurrentTractorMovement = function (stopRequest) {
      //Doesn't belong to this order. Shows above the Available Movement(in green).
      if (movement.stops().length == self.sequence() || self.sequence() == 1) {
        //If this is the first or last stop, get the PTA date first
        self.getDriverPTADate().then(function (result) {
          var pta = new PTAModel(result);
          movement.$parent.pTADateModal(pta);
          pta.result().then(function (ptaDate) {
            if (!ptaDate) {
              return; //They didnt ever a PTA date, just return and don't save anything.
            } else {
              dispatchAction(isLoading(true));
              movement.$parent.dispatchService
                .saveDispatch([
                  movement.$parent.orderMovements()[movement.index]
                    ? movement.$parent
                        .orderMovements()
                        [movement.index].saveDispatchSingleMovementObj()[0]
                    : undefined,
                  movement.$parent.tractorCurrentMovements()[movement.index]
                    ? movement.$parent
                        .tractorCurrentMovements()
                        [movement.index].saveDispatchSingleMovementObj()[0]
                    : undefined,
                ])
                .then(function (value) {
                  if (value && value.errors) {
                    const readyToBillError = value.readyToBillError;
                    const errorOnOrderExId = value.errorOnOrderExId;

                    if (
                      !readyToBillError ||
                      (readyToBillError && self.orderExId == errorOnOrderExId)
                    ) {
                      showmessage(value.errors);
                    }
                  }
                  stopRequest.stops[0].driverPTADate = ptaDate;
                  if (self.sequence() == 1) {
                    //First stop of the movement

                    movement.$parent.dispatchService
                      .completeStop(stopRequest)
                      .then(function (result) {
                        dispatchAction(isLoading(false));
                        if (result.errors) {
                          showmessage(result.errors);
                        } else {
                          if (result != "") {
                            showmessage([result]);
                          }
                          movement.movementStatus("In Progress");
                          if (
                            movement.$parent.tractorCurrentMovements()[
                              self.index
                            ]
                          ) {
                            movement.$parent.tractorCurrentMovements()[
                              self.index
                            ] = undefined;
                            movement.$parent.movementPairsDisplay()[
                              self.index
                            ] = undefined;
                          }
                        }
                      })
                      .catch(() => dispatchAction(isLoading(false)));
                  } else if (movement.stops().length == self.sequence()) {
                    //Last stop of the movement

                    movement.$parent.dispatchService
                      .completeStop(stopRequest)
                      .then(function (result) {
                        dispatchAction(isLoading(false));
                        if (result && result.errors) {
                          showmessage(result.errors);
                        } else {
                          if (result != "") {
                            showmessage(result);
                          }
                          self.stopStatus("Delivered");
                          movement.movementStatus("Delivered");
                        }
                        movement.$parent
                          .orderMovements()
                          [movement.index].refreshEquipment();
                        if (
                          movement.$parent.tractorCurrentMovements()[
                            movement.index
                          ]
                        ) {
                          movement.$parent
                            .tractorCurrentMovements()
                            [movement.index].refreshEquipment();
                        }
                      })
                      .catch(() => dispatchAction(isLoading(false)));
                  }
                })
                .catch(function (err) {
                  dispatchAction(isLoading(false));
                  if (err) {
                    showmessage(err);
                  }
                });
            }
          });
        });
      } else {
        //If this is NOT the first or last stop.
        dispatchAction(isLoading(true));
        movement.$parent.dispatchService
          .saveDispatch([
            movement.$parent.orderMovements()[movement.index]
              ? movement.$parent
                  .orderMovements()
                  [movement.index].saveDispatchSingleMovementObj()[0]
              : undefined,
            movement.$parent.tractorCurrentMovements()[movement.index]
              ? movement.$parent
                  .tractorCurrentMovements()
                  [movement.index].saveDispatchSingleMovementObj()[0]
              : undefined,
          ])
          .then(function (x) {
            if (x && x.errors) {
              const readyToBillError = x.readyToBillError;
              const errorOnOrderExId = x.errorOnOrderExId;

              if (
                !readyToBillError ||
                (readyToBillError && self.orderExId == errorOnOrderExId)
              ) {
                showmessage(x.errors);
              }
            }

            movement.$parent.dispatchService
              .completeStop(stopRequest)
              .then(function (result) {
                dispatchAction(isLoading(false));
                if (result.errors) {
                  showmessage(result.errors);
                } else {
                  if (result != "") {
                    showmessage(result);
                  }
                  self.stopStatus("Delivered");
                }
              });
          })
          .catch(function (err) {
            dispatchAction(isLoading(false));
            if (err) {
              showmessage("An error has occured.");
            }
            console.error(err);
          });
      }
    };

    self.completeMovementStop = function (stopRequest) {
      //If it's the first or last stop of the Movement.  We'll need to first show the modal for the PTA date and then change the movementStatus
      if (movement.stops().length == self.sequence() || self.sequence() == 1) {
        self.getDriverPTADate().then(function (result) {
          var pta = new PTAModel(result);
          movement.$parent.pTADateModal(pta);
          pta.result().then(function (ptaDate) {
            if (!ptaDate) {
              return; //They didnt enter a PTA date, just return and don't save anything.
            } else {
              stopRequest.stops[0].driverPTADate = ptaDate;
              dispatchAction(isLoading(true));

              //Need to save to create the preassignment first, this prevents issues downstream.
              movement.$parent.dispatchService
                .saveDispatch(movement.saveDispatchSingleMovementObj())
                .then(function (value) {
                  if (value && value.errors) {
                    const readyToBillError = value.readyToBillError;
                    const errorOnOrderExId = value.errorOnOrderExId;

                    if (
                      !readyToBillError ||
                      (readyToBillError && self.orderExId == errorOnOrderExId)
                    ) {
                      showmessage(value.errors);
                    }
                  }
                  movement.$parent.dispatchService
                    .completeStop(stopRequest)
                    .then(function (result) {
                      dispatchAction(isLoading(false));

                      if (result.errors) {
                        movement.$parent.alternateErrorModalError(
                          _.isArray(result.errors)
                            ? result.errors[0]
                            : result.errors
                        );
                      } else {
                        if (result != "") {
                          showmessage(result);
                        }

                        if (
                          self.isConsignee() == true &&
                          movement.isPartOfViewingOrder() == true
                        ) {
                          movement.$parent.orderStatus("Delivered");
                        } else if (
                          self.isShipper() == true &&
                          movement.isPartOfViewingOrder() == true
                        ) {
                          movement.$parent.orderStatus("In Progress");
                        }

                        if (movement.stops().length == self.sequence()) {
                          //Last stop of the Movement.
                          self.stopStatus("Delivered");
                          movement.movementStatus("Delivered");
                          if (movement.loaded() == "Loaded") {
                            if (movement.isLastMovementInSequence()) {
                              if (!movement.$parent.nextMovement()) {
                                movement.$parent.addTractorNextMovement(
                                  movement.tractor()
                                );
                              }
                            }
                          }
                        } else if (self.sequence() == 1) {
                          //First Stop
                          movement.removeTractorCurrentMovement();
                          movement.movementStatus("In Progress");
                          self.stopStatus("Delivered");
                        } else {
                          self.stopStatus("Delivered");
                        }
                      }

                      movement.$parent
                        .orderMovements()
                        .forEach(function (move) {
                          move.refreshEquipment();
                        });
                    });
                })
                .catch(function (err) {
                  if (err) {
                    showmessage("An error has occured.");
                  }
                  console.error(err);
                  dispatchAction(isLoading(false));
                });
            }
          });
        });
      } else {
        //If it's not the first or last stop of the movement.  Just complete the stop.
        dispatchAction(isLoading(true));
        movement.$parent.dispatchService
          .completeStop(stopRequest)
          .then(function (result) {
            dispatchAction(isLoading(false));
            if (result && result.errors) {
              showmessage(result.errors);
            } else {
              if (result != "") {
                showmessage(result);
              }
              self.stopStatus("Delivered");
              if (
                self.isConsignee() == true &&
                movement.isPartOfViewingOrder() == true
              ) {
                movement.$parent.orderStatus("Delivered");
              } else if (
                self.isShipper() == true &&
                movement.isPartOfViewingOrder() == true
              ) {
                movement.$parent.orderStatus("In Progress");
              }
            }
          });
      }
    };

    self.completeButtonText = ko.computed(function () {
      if (
        self.stopStatus() == "Available" ||
        self.stopStatus() == "In Progress"
      ) {
        return "Complete";
      } else {
        return "Completed";
      }
    });

    //If any logic needs to be added to this, ONLY return true and save any
    //false returns for the last conditional so that we get a prefilled in date when a stop becomes editable.
    self.blockCompleteStopReason = ko.observable();
    self.blockCompleteStopFunction = function () {
      if (
        movement.$parent.voidedStopMovements() &&
        movement.$parent.voidedStopMovements().length > 0
      ) {
        self.blockCompleteStopReason("Movement has voided stops");
        return true;
      } else if (
        movement.$parent.$componentParent.orderLockComponent() &&
        !movement.$parent.$componentParent.showDispatchOnly() &&
        movement.$parent.$componentParent.orderLockComponent().locked &&
        movement.$parent.$componentParent
          .orderLockComponent()
          .lockOrderControls()
      ) {
        self.blockCompleteStopReason("Order is locked");
        return true;
      }

      //Missing or invalid Tractor
      else if (!movement.tractor() || movement.tractor() == "string") {
        self.blockCompleteStopReason("Tractor is null or invalid");
        return true;
      }

      //Missing or invalid Driver1
      else if (!movement.driver1() || typeof movement.driver1() == "string") {
        //typeof(movement.driver1()) == "string" means that we don't have a valid driver.
        self.blockCompleteStopReason("Driver 1 is null or invalid");
        return true;
      }

      //Invalid Driver2
      else if (movement.driver2() && typeof movement.driver2() == "string") {
        self.blockCompleteStopReason("Driver 2 is invalid");
        return true;
      } else if (
        movement.tractorsPreviousMovementCleared() == false &&
        movement.tractorsPreviousMovementId() != movement.movementId()
      ) {
        self.blockCompleteStopReason("Tractor's previous movement not cleared");
        return true;
      } else if (
        movement.showDriversNotQualifiedForHazmat() == true &&
        movement.showDriversNotQualifiedForHazmat() == true
      ) {
        self.blockCompleteStopReason("Driver 1 or 2 not qualified");
        return true;
      } else if (
        (movement.driver1InfoObject() &&
          movement.driver1InfoObject().blockDispatch() == true) ||
        (movement.driver2InfoObject() &&
          movement.driver2InfoObject().blockDispatch() == true)
      ) {
        self.blockCompleteStopReason("Driver 1 block dispatch");
        return true;
      } else if (
        movement.index > 0 &&
        movement.$parent
          .orderMovements()
          [movement.index - 1].movementStatus() != "Delivered" &&
        movement.isPartOfViewingOrder()
      ) {
        self.blockCompleteStopReason("Previous order movement not delivered");
        return true;
      } else if (self.stopStatus() == "Delivered") {
        self.blockCompleteStopReason("Stop status delivered");
        return true;
      } else if (movement.movementStatus() == "Delivered") {
        self.blockCompleteStopReason("Movement is delivered");
        return true;
      } else if (
        movement.$parent.orderStatus() == "Delivered" &&
        movement.isPartOfViewingOrder() == true
      ) {
        self.blockCompleteStopReason("Order is delivered");
        return true;
      } else if (self.stopStatus() == "Available") {
        if (movement.showMovementNotFirstPreassigned() == true) {
          self.blockCompleteStopReason(
            "Stop Status Available - movement not first preassigned"
          );
          return true;
        }

        var previousStop = movement.stops().filter(function (stop) {
          return self.sequence() - 1 == stop.sequence();
        })[0];

        if (
          previousStop &&
          (previousStop.stopStatus() == "Available" ||
            previousStop.stopStatus() == "In Progress")
        ) {
          self.blockCompleteStopReason(
            "Stop Status Available - Previous stop available or in progress"
          );
          return true;
        }

        if (
          movement.$parent.tractorCurrentMovements()[movement.index] &&
          movement.$parent
            .tractorCurrentMovements()
            [movement.index].movementStatus() != "Delivered" &&
          movement.$parent
            .tractorCurrentMovements()
            [movement.index].movementId() != movement.movementId()
        ) {
          self.blockCompleteStopReason(
            "Stop Status Available - Tractors current movement not delivered"
          );
          return true;
        }
      }

      //Fill in date if we don't have one
      if (!self.actualArrival() && self.timeFilledIn == false) {
        self.actualArrival(datetimeUTC(new Date().setSeconds(0, 0)));
        self.timeFilledIn = true;
        self.blockCompleteStopReason("None");
        return false;
      } else if (!self.actualDeparture() && self.timeFilledIn == false) {
        self.actualDeparture(datetimeUTC(new Date().setSeconds(0, 0)));
        self.timeFilledIn = true;
        self.blockCompleteStopReason("None");
        return false;
      } else {
        self.blockCompleteStopReason("None");
        return false;
      }
    };

    self.blockCompleteStop = ko
      .pureComputed(self.blockCompleteStopFunction)
      .extend({ rateLimit: 1000 });

    self.timeFilledIn = false;

    self.isActualArrivalReadOnly = ko.computed(function () {
      if (self.stopStatus() == "In Progress") {
        return true;
      } else {
        return self.blockCompleteStop();
      }
    });
  }
}

class Driver {
  constructor(info) {
    this.driverId = ko.observable(info.driverId);
    if (info.city && info.state) {
      this.homeLocation = ko.observable(info.city + ", " + info.state);
    } else if (info.state) {
      this.homeLocation = ko.observable(info.city + ", " + info.state);
    } else {
      this.homeLocation = ko.observable();
    }
    this.equipmentGroupId = ko.observable(info.equipmentGroupId);
    this.ptaDate = ko.observable();
    if (info.ptaDate) {
      var ptads = new Date(info.ptaDate);
      this.ptaDate = ko.observable(
        ptads.toLocaleDateString() + " " + ptads.toLocaleTimeString()
      );
    }
    this.hazmat = ko.observable(info.hazmat);
    this.hM126CDate = ko.observable(info.hM126CDate);
    this.hazmatDate = ko.observable(info.hazmatDate);
    this.externalId = ko.observable(info.externalId);
    this.canHaulHazmat = ko.observable(
      info.hazmat == true &&
        new Date(info.hazmatDate) > new Date() &&
        new Date(info.hM126CDate) > new Date()
    );
    this.blockDispatch = ko.observable(info.blockDispatch);
    this.blockReason = ko.observable(info.blockReason);
    this.payeeId = ko.observable(info.payeeId);
    this.payeeExId = ko.observable(info.payeeExId);
  }
}

class MovementOrder {
  constructor(mo) {
    var self = this;
    self.agencyId = ko.observable(mo.agencyId);
    self.fuelLimit = ko.observable(mo.fuelLimit);
    self.hazmat = ko.observable(mo.hazmat);
    self.orderBrokered = ko.observable(mo.orderBrokered);
    self.orderExId = ko.observable(mo.orderExId);
    self.orderId = ko.observable(mo.orderId);
    self.movementId = ko.observable(mo.movementId);
  }
}

class PTAModel {
  constructor(ptaDate) {
    var self = this;
    self.ptaDate = ko.observable(ptaDate);
    self.ok = function () {};
    self.cancel = function () {};
    self.result = function () {
      return new Promise(function (resolve, reject) {
        self.ok = function () {
          $("#ptaDateModal").find(".close").click();
          resolve(self.ptaDate());
        };

        self.cancel = function () {
          $("#ptaDateModal").find(".close").click();
          resolve(false);
        };
      });
    };
  }
}

//Service Methods
class DispatchService {
  /** @type { Observable<OrderEntryTracking> } */
  orderEntryTracking;
  constructor(orderEntryTracking) {
    this.orderEntryTracking = orderEntryTracking;
  }

  getMovements = (orderId) => {
    return new Promise((resolve, reject) => {
      dataModel
        .ajaxRequest("Dispatch/GetMovements", "GET", { orderId: orderId })
        .done((value) => {
          resolve(value);
        })
        .fail((value) => {
          reject({ errors: ["An error has occurred."] });
          if (
            value.responseJSON &&
            value.responseJSON.message != "An error has occurred."
          ) {
            dataModel.addClientSideLog(value);
          }
        });
    });
  };

  getNextOrderMovement = (orderId) => {
    return new Promise((resolve, reject) => {
      dataModel
        .ajaxRequest("Dispatch/GetNextOrderMovement", "GET", {
          orderId: orderId,
        })
        .done((value) => {
          resolve(value);
        })
        .fail((value) => {
          resolve({ errors: ["An error has occurred."] });
          if (
            value.responseJSON &&
            value.responseJSON.message != "An error has occurred."
          ) {
            dataModel.addClientSideLog(value);
          }
        });
    });
  };

  getTractorEquipment = (tractorId) => {
    return new Promise((resolve, reject) => {
      if (tractorId) {
        dataModel
          .ajaxRequest("Dispatch/GetTractorEquipment", "GET", {
            tractorId: tractorId,
          })
          .done((value) => {
            resolve(value);
          })
          .fail((value) => {
            resolve({ errors: ["An error has occurred."] });
            if (
              value.responseJSON &&
              value.responseJSON.message != "An error has occurred."
            ) {
              dataModel.addClientSideLog(value);
            }
          });
      } else {
        resolve();
      }
    });
  };

  cancelDispatch = (cdObj) => {
    return new Promise((resolve, reject) => {
      dataModel
        .ajaxRequest("Dispatch/CancelDispatch", "POST", cdObj)
        .done((value) => {
          resolve(value);
        })
        .fail((value) => {
          if (value.status == 409 && value.responseJSON) {
            this.orderEntryTracking()
              .methods()
              .displayConflictNotifyMessage(value.responseJSON);
            reject();
          } else if (value.status == 412) {
            // Order Locked
            showmessage(value.responseJSON.message);
            reject(value.responseJSON.message);
          } else {
            resolve({ errors: ["An error has occurred."] });
            if (
              value.responseJSON &&
              value.responseJSON.message != "An error has occurred."
            ) {
              dataModel.addClientSideLog(value);
            }
          }
        });
    });
  };

  getDriverInfo = (driverId) => {
    return new Promise((resolve, reject) => {
      if (driverId) {
        dataModel
          .ajaxRequest("Dispatch/GetDriverInfo", "GET", {
            driverId: typeof driverId == "number" ? driverId : 0,
            driverExternalId: driverId.toString(),
          })
          .done((value) => {
            resolve(value);
          })
          .fail((value) => {
            resolve({ errors: ["An error has occurred."] });
            if (
              value.responseJSON &&
              value.responseJSON.message != "An error has occurred."
            ) {
              dataModel.addClientSideLog(value);
            }
          });
      } else {
        resolve();
      }
    });
  };

  getTractorPreassignments = (tractorId) => {
    return new Promise((resolve, reject) => {
      dataModel
        .ajaxRequest("Dispatch/GetTractorPreassignments", "GET", {
          tractorId: tractorId,
        })
        .done((value) => {
          resolve(value);
        })
        .fail((value) => {
          resolve({ errors: ["An error has occurred."] });
          if (
            value.responseJSON &&
            value.responseJSON.message != "An error has occurred."
          ) {
            dataModel.addClientSideLog(value);
          }
        });
    });
  };

  updateTractorPreassignments = (data, tractorId) => {
    var payload = {
      preassignments: data,
      orderTracking: this.orderEntryTracking().data.toJSON(),
    };

    return new Promise((resolve, reject) => {
      dataModel
        .ajaxRequest(
          "Dispatch/UpdateTractorPreassignments/" + tractorId,
          "POST",
          payload
        )
        .done((value) => {
          this.orderEntryTracking()
            .methods()
            .saveUpdateTrackingAndRefreshTimestamp();
          resolve(value);
        })
        .fail((value) => {
          if (value.status == 409 && value.responseJSON) {
            this.orderEntryTracking()
              .methods()
              .displayConflictNotifyMessage(value.responseJSON);
            reject();
          } else if (value.status == 412) {
            // Order Locked
            reject(value.responseJSON.message);
          } else {
            reject("An error has occurred.");
            if (
              value.responseJSON &&
              value.responseJSON.message != "An error has occurred."
            ) {
              dataModel.addClientSideLog(value);
            }
          }
        });
    });
  };

  getTractorCurrentMovement = (tractorid) => {
    return new Promise((resolve, reject) => {
      dataModel
        .ajaxRequest("Dispatch/GetTractorCurrentMovement", "GET", {
          tractorId: tractorid,
        })
        .done((value) => {
          resolve(value);
        })
        .fail((value) => {
          resolve({ errors: ["An error has occurred."] });
          if (value.responseJSON && value.responseJSON.message) {
            if (
              value.responseJSON &&
              value.responseJSON.message != "An error has occurred."
            ) {
              dataModel.addClientSideLog(value);
            }
          }
        });
    });
  };

  getTractorNextMovement = (tractorid) => {
    return new Promise((resolve, reject) => {
      dataModel
        .ajaxRequest("Dispatch/GetTractorNextMovement", "GET", {
          tractorId: tractorid,
        })
        .done((value) => {
          resolve(value);
        })
        .fail((value) => {
          resolve({ errors: ["An error has occurred."] });
          if (
            value.responseJSON &&
            value.responseJSON.message != "An error has occurred."
          ) {
            dataModel.addClientSideLog(value);
          }
        });
    });
  };

  saveDispatch = (dispatchObj) => {
    dispatchObj = dispatchObj.filter((x) => x);
    var payload = {
      clientData: dispatchObj,
      orderTracking: this.orderEntryTracking().data.toJSON(),
    };

    return new Promise((resolve, reject) => {
      dataModel
        .ajaxRequest("Dispatch/SaveTrucklineDispatch", "POST", payload)
        .done((value) => {
          this.orderEntryTracking()
            .methods()
            .saveUpdateTrackingAndRefreshTimestamp();
          resolve(value);
        })
        .fail((value) => {
          if (value.status == 409 && value.responseJSON) {
            this.orderEntryTracking()
              .methods()
              .displayConflictNotifyMessage(value.responseJSON);
            reject();
          } else if (value.status == 412) {
            // Order Locked
            reject(value.responseJSON.message);
          } else {
            reject({ errors: "An error has occured." });
          }
          if (
            value.responseJSON &&
            value.responseJSON.message != "An error has occurred."
          ) {
            dataModel.addClientSideLog(value.responseJSON);
          }
        });
    });
  };

  completeStop = (stop) => {
    return new Promise((resolve, reject) => {
      dataModel
        .ajaxRequest("Dispatch/CompleteStop", "POST", stop)
        .done((value) => {
          resolve(value);
        })
        .fail((value) => {
          resolve({ errors: ["An error has occurred."] });
          if (
            value.responseJSON &&
            value.responseJSON.message != "An error has occurred."
          ) {
            dataModel.addClientSideLog(value);
          }
        });
    });
  };

  checkDriverWireStatus = (data) => {
    return new Promise((resolve, reject) => {
      dataModel
        .ajaxRequest("Dispatch/CheckDriverWireStatus", "POST", data)
        .done((value) => {
          resolve(value);
        })
        .fail((value) => {
          resolve({ errors: ["An error has occurred."] });
          if (
            value.responseJSON &&
            value.responseJSON.message != "An error has occurred."
          ) {
            dataModel.addClientSideLog(value);
          }
        });
    });
  };

  getOrderMovementData = (data) => {
    return new Promise((resolve, reject) => {
      dataModel
        .ajaxRequest("Dispatch/getOrderMovementData", "GET", data)
        .done((value) => {
          resolve(value);
        })
        .fail((value) => {
          dataModel.addClientSideLog(value);
          if (
            value.responseJSON &&
            value.responseJSON.message != "An error has occurred."
          ) {
            dataModel.addClientSideLog(value);
          }
        });
    });
  };

  getPlanningComments = (tr) => {
    return new Promise((resolve, reject) => {
      dataModel
        .ajaxRequest("EquipmentGroup/PlanningComments", "GET", {
          TrailerExId: tr,
        })
        .done((data) => {
          resolve(data);
        });
    });
  };
}

export { Movement, Stop };
export default { viewModel: OrderEntryDispatchViewModel, template: template };
