﻿import $ from "jquery";
import ko from "knockout";
window.jQuery = $;
window.$ = $;
import "bootstrap/dist/js/bootstrap";
import "!style-loader!css-loader!bootstrap/dist/css/bootstrap.min.css";
import "!style-loader!css-loader!notyf/notyf.min.css";

// appStore
import "./dataStore"; // dataStore/index.js builds the store and creates/binds ko.bindingProvider

import "./ko.jqx.init";
import userProfile from "./user-profile";
import router from "./router";
import "./ko.webpack-component-loader";
import "./home/shared-components/ko-great-edge-components/ko.great-edge"; //Binding handlers and such.
import "./home/shared-components/ko-great-edge-components/ko.ge-auto-complete";
import moment from "moment"; // Required by fullcalendar - possible future updates will have dayjs support.
import "fullcalendar";

// get instances loaded.
import storageManager from "./utils/storageManager";
import broadcastAPI from "./utils/broadcastAPI";

import NavBannerLinksComponent from "./home/shared-components/navbanner-links-component/navbanner-links-component";
ko.components.register("navbanner-links-component", NavBannerLinksComponent);

import MainHeaderComponent from "./home/main-header-component/main-header-component";
import MainHeaderLoginComponent from "./home/main-header-component/main-header-login-component/main-header-login-component";
import MainHeaderNavigationComponent from "./home/main-header-component/main-header-navigation-component/main-header-navigation-component";
import MainHeaderMenuComponent from "./home/main-header-component/main-header-menu-component/main-header-menu-component";
ko.components.register("main-header-component", MainHeaderComponent);
ko.components.register("main-header-login-component", MainHeaderLoginComponent);
ko.components.register(
  "main-header-navigation-component",
  MainHeaderNavigationComponent
);
ko.components.register("main-header-menu-component", MainHeaderMenuComponent);

import DefaultPage from "./home/pages/default-page/default-page";
import DefaultPageLoggedInComponent from "./home/pages/default-page/default-page-logged-in-component/default-page-logged-in-component";
import DefaultPageLoggedOutComponent from "./home/pages/default-page/default-page-logged-out-component/default-page-logged-out-component";
ko.components.register("default-page", DefaultPage);
ko.components.register(
  "default-page-logged-in-component",
  DefaultPageLoggedInComponent
);
ko.components.register(
  "default-page-logged-out-component",
  DefaultPageLoggedOutComponent
);

import GridOptionComponentViewModel from "./home/shared-components/jqx.grid-options-component/jqx.grid-options-component";
import GridStateComponentViewModel from "./home/shared-components/jqx.grid-state-component/jqx.grid-state-component";
ko.components.register("jqx.grid-state-component", GridStateComponentViewModel);
ko.components.register(
  "jqx.grid-options-component",
  GridOptionComponentViewModel
);

import ShowMessageDialogComponent from "./home/shared-components/dialog-components/show-message-dialog-component/show-message-dialog-component";
ko.components.register(
  "show-message-dialog-component",
  ShowMessageDialogComponent
);
import ShowConfirmDialogComponent from "./home/shared-components/dialog-components/show-confirm-dialog-component/show-confirm-dialog-component";
ko.components.register(
  "show-confirm-dialog-component",
  ShowConfirmDialogComponent
);
import ShowPasswordDialogComponent from "./home/shared-components/dialog-components/show-password-dialog-component/show-password-dialog-component";
ko.components.register(
  "show-password-dialog-component",
  ShowPasswordDialogComponent
);
import MustDismissDialogComponent from "./home/shared-components/dialog-components/show-must-dismiss-dialog-component/must-dismiss-dialog-component";
ko.components.register(
  "must-dismiss-dialog-component",
  MustDismissDialogComponent
);

import RatingPopupWindowComponent from "./home/pages/rating-page/rating-popup-window/rating-popup-window";
ko.components.register("rating-popup-window", RatingPopupWindowComponent);
import RatingPageComponent from "./home/pages/rating-page/rating-page";
ko.components.register("rating-page-component", RatingPageComponent);
import RateEntryPanelComponent from "./home/pages/rating-page/rate-entry-panel-component/rate-entry-panel-component";
ko.components.register("rate-entry-panel-component", RateEntryPanelComponent);
import RateEntryModalComponent from "./home/pages/rating-page/rate-entry-panel-component/rate-entry-modal/rate-entry-modal";
ko.components.register("rate-entry-modal-component", RateEntryModalComponent);
import RateEntryLanesComponent from "./home/pages/rating-page/rate-entry-panel-component/lane-rates-component/lane-rates-component";
ko.components.register("rate-lane-rates-component", RateEntryLanesComponent);
import RateEntryAccessorialComponent from "./home/pages/rating-page/rate-entry-panel-component/accessorial-component/accessorial-component";
ko.components.register(
  "rate-accessorial-component",
  RateEntryAccessorialComponent
);
import RateEntryBatchRateLanesComponent from "./home/pages/rating-page/rate-entry-panel-component/batch-lanerate-import-component/batch-lanerate-import-component";
ko.components.register(
  "rate-batch-lanerate-import-component",
  RateEntryBatchRateLanesComponent
);
import TieredStopsPanelComponent from "./home/pages/rating-page/tiered-stops-panel-component/tiered-stops-panel-component";
ko.components.register(
  "tiered-stops-panel-component",
  TieredStopsPanelComponent
);
import ApiTendersComponent from "./home/shared-components/tenders-panel-component/tenders-panel-component";
ko.components.register("api-tenders-panel-component", ApiTendersComponent);

import OrdersPageViewModel from "./home/pages/orders-page/orders-page";
ko.components.register("orders-page", OrdersPageViewModel);

import MainFooterComponent from "./home/main-footer-component/main-footer-component";
ko.components.register("main-footer-component", MainFooterComponent);

import MainFooterPriorityAlertComponent from "./home/main-footer-component/main-footer-priority-alert-component/main-footer-priority-alert-component";
ko.components.register(
  "main-footer-priority-alert-component",
  MainFooterPriorityAlertComponent
);

import TwoFactorComponent from "./home/shared-components/two-factor-components/two-factor-component";
ko.components.register("two-factor-component", TwoFactorComponent);
import TwoFactorSettingsComponent from "./home/shared-components/two-factor-components/two-factor-settings-component/two-factor-settings-component";
ko.components.register(
  "two-factor-settings-component",
  TwoFactorSettingsComponent
);

// the modal (includes the form) - basically just a modal for the form.
import TwoFactorConfirmationComponent from "./home/shared-components/two-factor-components/two-factor-confirmation-component/two-factor-confirmation-component";
ko.components.register(
  "two-factor-confirmation-component",
  TwoFactorConfirmationComponent
);

// the form + methods --> (input, resend button, sending/validating methods) *not restricted to a modal*
import TwoFactorConfirmationFormComponent from "./home/shared-components/two-factor-components/two-factor-confirmation-component/two-factor-confirmation-form-component/two-factor-confirmation-form-component";
ko.components.register(
  "two-factor-confirmation-form-component",
  TwoFactorConfirmationFormComponent
);
import WireSettingsPasswordModalComponent from "./home/pages/my-profile-page/wire-settings-password-modal-component/wire-settings-password-modal-component";
ko.components.register(
  "wire-settings-password-modal-component",
  WireSettingsPasswordModalComponent
);

// OrderEntry
import OrderEntryPage from "./home/pages/order-entry-page/order-entry-page";
ko.components.register("order-entry-page", OrderEntryPage);
import OrderEntryOrderStopsArrangementComponent from "./home/pages/order-entry-page/order-entry-order-stops-arrangement-component/order-entry-order-stops-arrangement-component";
ko.components.register(
  "order-entry-order-stops-arrangement-component",
  OrderEntryOrderStopsArrangementComponent
);
import OrderEntryDispatchTruckline from "./home/pages/order-entry-page/order-entry-dispatch/order-entry-dispatch-truckline-component/order-entry-dispatch-truckline-component";
ko.components.register(
  "order-entry-dispatch-truckline",
  OrderEntryDispatchTruckline
);
import OrderEntryDuplicateOrderComponent from "./home/pages/order-entry-page/order-entry-duplicate-order-component/order-entry-duplicate-order-component";
ko.components.register(
  "order-entry-duplicate-order-component",
  OrderEntryDuplicateOrderComponent
);
import OrderEntryDispatchCancelDispatch from "./home/pages/order-entry-page/order-entry-dispatch/order-entry-dispatch-cancel-dispatch/order-entry-dispatch-cancel-dispatch";
ko.components.register(
  "order-entry-dispatch-cancel-dispatch-component",
  OrderEntryDispatchCancelDispatch
);
import OrderEntryDispatchBrokerage from "./home/pages/order-entry-page/order-entry-dispatch/order-entry-dispatch-brokerage-component/order-entry-dispatch-brokerage-component";
ko.components.register(
  "order-entry-dispatch-brokerage",
  OrderEntryDispatchBrokerage
);
import RateConfirmationComponent from "./home/shared-components/rate-confirmation-component/rate-confirmation-component";
ko.components.register(
  "rate-confirmation-component",
  RateConfirmationComponent
);
import CallInRecordsComponent from "./home/shared-components/call-in-records-component/call-in-records-component";
ko.components.register("call-in-records-component", CallInRecordsComponent);
import OrderEntryAdvancedLocationSearchComponent from "./home/pages/order-entry-page/order-entry-advanced-location-search/order-entry-advanced-location-search";
ko.components.register(
  "order-entry-advanced-location-search-component",
  OrderEntryAdvancedLocationSearchComponent
);
import OrderEntryGeoLocationsComponent from "./home/pages/order-entry-page/order-entry-geo-locations-component/order-entry-geo-locations-component";
ko.components.register(
  "order-entry-geo-locations-component",
  OrderEntryGeoLocationsComponent
);
import OrderEntryTrackingMapComponent from "./home/pages/order-entry-page/order-entry-tracking-map-component/order-entry-tracking-map-component";
ko.components.register(
  "order-entry-tracking-map-component",
  OrderEntryTrackingMapComponent
);
import OrderEntryStopsComponent from "./home/pages/order-entry-page/order-entry-stops-component/order-entry-stops-component";
ko.components.register("order-entry-stops-component", OrderEntryStopsComponent);
import OrderEntryDispatchTractorPreassignmentModal from "./home/pages/order-entry-page/order-entry-dispatch/order-entry-dispatch-tractor-preassignment-modal/order-entry-dispatch-tractor-preassignment-modal";
ko.components.register(
  "order-entry-dispatch-tractor-preassignment-modal",
  OrderEntryDispatchTractorPreassignmentModal
);
import OrderEntryDispatchCreateWireModal from "./home/pages/order-entry-page/order-entry-dispatch/order-entry-dispatch-create-wire-modal/order-entry-dispatch-create-wire-modal";
ko.components.register(
  "order-entry-dispatch-create-wire-modal",
  OrderEntryDispatchCreateWireModal
);
import OrderEntryDispatchCarrierExtraPayModal from "./home/pages/order-entry-page/order-entry-dispatch/order-entry-dispatch-carrier-extra-pay-modal/order-entry-dispatch-carrier-extra-pay-modal";
ko.components.register(
  "order-entry-dispatch-carrier-extra-pay-modal",
  OrderEntryDispatchCarrierExtraPayModal
);
import OrderEntryTemplateComponents from "./home/pages/order-entry-page/order-entry-template-component/order-entry-template-component";
ko.components.register(
  "order-entry-template-component",
  OrderEntryTemplateComponents
);
import OrderEntrySummaryComponent from "./home/pages/order-entry-page/order-entry-summary-component/order-entry-summary-component";
ko.components.register(
  "order-entry-summary-component",
  OrderEntrySummaryComponent
);
import OrderEntryGeneralComponent from "./home/pages/order-entry-page/order-entry-general-component/order-entry-general-component";
ko.components.register(
  "order-entry-general-component",
  OrderEntryGeneralComponent
);
import OrderEntryContainerComponent from "./home/pages/order-entry-page/order-entry-container-component/order-entry-container-component";
ko.components.register(
  "order-entry-container-component",
  OrderEntryContainerComponent
);
import OrderEntryContainerTrackingComponent from "./home/pages/order-entry-page/order-entry-container-component/order-entry-container-tracking-component/order-entry-container-tracking-component";
ko.components.register(
  "order-entry-container-tracking-component",
  OrderEntryContainerTrackingComponent
);
import OrderEntryOrderImagesComponent from "./home/pages/order-entry-page/order-entry-order-images-component/order-entry-order-images-component";
ko.components.register(
  "order-entry-order-images-component",
  OrderEntryOrderImagesComponent
);
import OrderEntryOrderGemUploadsComponent from "./home/pages/order-entry-page/order-entry-order-gem-uploads-component/order-entry-order-gem-uploads-component";
ko.components.register(
  "order-entry-order-gem-uploads-component",
  OrderEntryOrderGemUploadsComponent
);
import OrderEntryBillingComponent from "./home/pages/order-entry-page/order-entry-billing-component/order-entry-billing-component";
ko.components.register(
  "order-entry-billing-component",
  OrderEntryBillingComponent
);
import OrderEntryInvoiceAndSettlementDetailsComponent from "./home/pages/order-entry-page/order-entry-invoice-and-settlement-details-component/order-entry-invoice-and-settlement-details-component";
ko.components.register(
  "order-entry-invoice-and-settlement-details-component",
  OrderEntryInvoiceAndSettlementDetailsComponent
);

import OrderEntryBillingAdditionalChargesComponent from "./home/pages/order-entry-page/order-entry-billing-component/additional-charges-component/additional-charges-component";
ko.components.register(
  "order-entry-billing-additional-charges-component",
  OrderEntryBillingAdditionalChargesComponent
);
import OrderEntryBillingAddPayModalComponent from "./home/pages/order-entry-page/order-entry-billing-component/additional-charges-component/add-pay-modal-component/add-pay-modal-component";
ko.components.register(
  "order-entry-billing-add-pay-modal-component",
  OrderEntryBillingAddPayModalComponent
);

import OrderEntryDeliveryReceiptComponent from "./home/pages/order-entry-page/order-entry-delivery-receipt-component/order-entry-delivery-receipt-component";
ko.components.register(
  "order-entry-delivery-receipt-component",
  OrderEntryDeliveryReceiptComponent
);

import OrderEntryOrderLockComponent from "./home/pages/order-entry-page/order-entry-order-lock-component/order-entry-order-lock-component";
ko.components.register(
  "order-entry-order-lock-component",
  OrderEntryOrderLockComponent
);
import { OrderLockComponentModals } from "./home/pages/order-entry-page/order-entry-order-lock-component/order-entry-order-lock-component";
ko.components.register(
  "order-entry-order-lock-component-modals",
  OrderLockComponentModals
);

import OrderEntryDispatchTrackingComponent from "./home/pages/order-entry-page/order-entry-dispatch/order-entry-dispatch-tracking-component/order-entry-dispatch-tracking-component";
ko.components.register(
  "order-entry-dispatch-tracking-component",
  OrderEntryDispatchTrackingComponent
);

import OrderEntryTrackTraceComponent from "./home/pages/order-entry-page/order-entry-track-trace-component/order-entry-track-trace-component";
ko.components.register(
  "order-entry-track-trace-component",
  OrderEntryTrackTraceComponent
);

import OrderEntryTrackTraceStopCommentComponent from "./home/pages/order-entry-page/order-entry-track-trace-component/components/tnt-stop-comment-component/tnt-stop-comment-component";
ko.components.register(
  "order-entry-tnt-stop-comment-component",
  OrderEntryTrackTraceStopCommentComponent
);

import OrderEntryTrackTraceDriverPhoneModalComponent from "./home/pages/order-entry-page/order-entry-track-trace-component/components/driver-phone-modal-component/driver-phone-modal-component";
ko.components.register(
  "order-entry-tnt-driver-phone-modal-component",
  OrderEntryTrackTraceDriverPhoneModalComponent
);

import OrderEntryTrackTraceDriverContactListComponent from "./home/pages/order-entry-page/order-entry-track-trace-component/components/driver-contact-list-component/driver-contact-list-component";
ko.components.register(
  "order-entry-tnt-driver-contact-list-component",
  OrderEntryTrackTraceDriverContactListComponent
);

import OrderEntryStopsEdiDelayComponent from "./home/pages/order-entry-page/order-entry-stops-component/order-entry-stops-edi-delay-component/order-entry-stops-edi-delay-component";
ko.components.register(
  "order-entry-stops-edi-delay-component",
  OrderEntryStopsEdiDelayComponent
);
import OrderEntryStopsEdiDelayEditor from "./home/pages/order-entry-page/order-entry-stops-component/order-entry-stops-edi-delay-component/order-entry-stops-edi-delay-code-editor";
ko.components.register(
  "order-entry-stops-edi-delay-editor",
  OrderEntryStopsEdiDelayEditor
);

// Order Tracking Page
import OrderTrackingPage from "./home/pages/order-tracking-page/order-tracking-page";
ko.components.register("order-tracking-page", OrderTrackingPage);

// Analytics Page
import AnalyticsPage from "./home/pages/analytics-page/analytics-page";
ko.components.register("analytics-page", AnalyticsPage);

// EFS Advance Modal + Components
import EfsAdvanceModalComponent from "./home/shared-components/efs-advance-component/efs-advance-modal-component/efs-advance-modal-component";
ko.components.register("efs-advance-modal-component", EfsAdvanceModalComponent);
import EfsPriorAdvancesGridComponent from "./home/shared-components/efs-advance-component/efs-advance-modal-component/efs-prior-advances-grid-component/efs-prior-advances-grid-component";
ko.components.register(
  "efs-prior-advances-grid-component",
  EfsPriorAdvancesGridComponent
);
import EfsEntryFormComponent from "./home/shared-components/efs-advance-component/efs-advance-modal-component/efs-entry-form-component/efs-entry-form-component";
ko.components.register("efs-entry-form-component", EfsEntryFormComponent);
import EfsActionBtnRowComponent from "./home/shared-components/efs-advance-component/efs-advance-modal-component/efs-action-btn-row-component/efs-action-btn-row-component";
ko.components.register(
  "efs-action-btn-row-component",
  EfsActionBtnRowComponent
);
import EfsSelectAdvanceTypePopupComponent from "./home/shared-components/efs-advance-component/efs-advance-modal-component/efs-select-advance-type-popup-component/efs-select-advance-type-popup-component";
ko.components.register(
  "efs-select-advance-type-popup",
  EfsSelectAdvanceTypePopupComponent
);

// Order Planning
import OrderPlanningPage from "./home/pages/order-planning-page/order-planning-page";
ko.components.register("order-planning-page", OrderPlanningPage);
import BatchLoadImportComponent from "./home/shared-components/batch-import-component/batch-load-import-component";
ko.components.register("batch-load-import-component", BatchLoadImportComponent);
import ProjectPostingComponent from "./home/shared-components/project-posting-component/project-posting-component";
ko.components.register("project-posting-component", ProjectPostingComponent);
import OrderPlanningTractorGridComponent from "./home/pages/order-planning-page/tractor-grid-component/tractor-grid-component";
ko.components.register(
  "order-planning-tractor-grid-component",
  OrderPlanningTractorGridComponent
);
import OrderPlanningOrderGridComponent from "./home/pages/order-planning-page/order-grid-component/order-grid-component";
ko.components.register(
  "order-planning-order-grid-component",
  OrderPlanningOrderGridComponent
);
import OrderPlanningHeaderActionButtonsComponent from "./home/pages/order-planning-page/header-action-buttons-component/header-action-buttons-component";
ko.components.register(
  "order-planning-header-action-buttons-component",
  OrderPlanningHeaderActionButtonsComponent
);
import OrderPlanningTractorDetailsComponent from "./home/pages/order-planning-page/tractor-grid-component/tractor-details-component/tractor-details-component";
ko.components.register(
  "order-planning-tractor-details-component",
  OrderPlanningTractorDetailsComponent
);
import PostTractorComponent from "./home/shared-components/post-tractor-component/post-tractor-component";
ko.components.register("post-tractor-component", PostTractorComponent);
import OrderPlanningOrderPreassignmentComponent from "./home/pages/order-planning-page/header-action-buttons-component/order-preassignment-component/order-preassignment-component";
ko.components.register(
  "order-planning-order-preassignment-component",
  OrderPlanningOrderPreassignmentComponent
);
import AlertSettingsComponent from "./home/shared-components/alert-settings-component/alert-settings-component";
ko.components.register("alert-settings-component", AlertSettingsComponent);
import AdvancedTractorAlertSettingsComponent from "./home/shared-components/advanced-tractor-alert-settings-component/advanced-tractor-alert-settings-component";
ko.components.register(
  "advanced-tractor-alert-settings-component",
  AdvancedTractorAlertSettingsComponent
);
import OrderPlanningOrderDetailsComponent from "./home/pages/order-planning-page/order-grid-component/order-details-component/order-details-component";
ko.components.register(
  "order-planning-order-details",
  OrderPlanningOrderDetailsComponent
);
import ExpireLoadsComponent from "./home/shared-components/expire-loads-component/expire-loads-component";
ko.components.register("expire-loads-component", ExpireLoadsComponent);
import EmptyMoveComponent from "./home/pages/order-planning-page/tractor-grid-component/empty-move-component/empty-move-component";
ko.components.register("empty-move-component", EmptyMoveComponent);
import OrderPlanningFooterComponent from "./home/pages/order-planning-page/footer-component/footer-component";
ko.components.register(
  "order-planning-footer-component",
  OrderPlanningFooterComponent
);

//Loadboard - basically a copy of OrderPlanning
import LoadBoardPage from "./home/pages/order-planning-page/loadboard-page";
ko.components.register("loadboard-page", LoadBoardPage);

import LoadboardTractorGridComponent from "./home/pages/order-planning-page/tractor-grid-component/loadboard-tractor-grid-component";
ko.components.register(
  "loadboard-tractor-grid-component",
  LoadboardTractorGridComponent
);

import LoadboardOrderGridComponent from "./home/pages/order-planning-page/order-grid-component/loadboard-order-grid-component";
ko.components.register(
  "loadboard-order-grid-component",
  LoadboardOrderGridComponent
);

//OrderEntry & OrderPlanning
import PostLoadComponent from "./home/shared-components/post-load-component/post-load-component";
ko.components.register("post-load-component", PostLoadComponent);
import MoveTrailerComponent from "./home/pages/order-planning-page/order-grid-component/move-trailer-component/move-trailer-component";
ko.components.register("move-trailer-component", MoveTrailerComponent);

// Tractors
import TractorsPage from "./home/pages/tractors-page/tractors-page";
ko.components.register("tractors-page", TractorsPage);
import UnitMessagesComponent from "./home/shared-components/unit-messages-component/unit-messages-component";
ko.components.register("unit-messages-component", UnitMessagesComponent);
import EquipmentIssuedComponent from "./home/shared-components/equipment-issued-component/equipment-issued-component";
ko.components.register("equipment-issued-component", EquipmentIssuedComponent);

import TransferListComponent from "./home/shared-components/transfer-list-component/transfer-list-component";
ko.components.register("transfer-list-component", TransferListComponent);

// My Agency
import MyAgencyPage from "./home/pages/my-agency-page/my-agency-component";
ko.components.register("my-agency-page", MyAgencyPage);
import AgencyContactComponent from "./home/pages/my-agency-page/agency-contact-component/agency-contact-component";
ko.components.register("agency-contact-component", AgencyContactComponent);
import AgencyRequiredFieldsComponent from "./home/pages/my-agency-page/agency-required-fields-component/agency-required-fields-component";
ko.components.register(
  "agency-required-fields-component",
  AgencyRequiredFieldsComponent
);
import EditAgencyRequiredFieldsComponent from "./home/pages/my-agency-page/agency-required-fields-component/edit-agency-required-fields-component/edit-agency-required-fields-component";
ko.components.register(
  "edit-agency-required-fields-component",
  EditAgencyRequiredFieldsComponent
);
import CustomerRequiredFieldsComponent from "./home/pages/my-agency-page/customer-required-fields-component/customer-required-fields-component";
ko.components.register(
  "customer-required-fields-component",
  CustomerRequiredFieldsComponent
);
import EditCustomerRequiredFieldsComponent from "./home/pages/my-agency-page/customer-required-fields-component/edit-customer-required-fields-component/edit-customer-required-fields-component";
ko.components.register(
  "edit-customer-required-fields-component",
  EditCustomerRequiredFieldsComponent
);
import AgencyUsersComponent from "./home/pages/my-agency-page/agency-users-component/agency-users-component";
ko.components.register("agency-users-component", AgencyUsersComponent);
import EditAgencyUsersComponent from "./home/pages/my-agency-page/agency-users-component/edit-agency-users-component/edit-agency-users-component";
ko.components.register("edit-agency-users-component", EditAgencyUsersComponent);
import CarrierTractorComponent from "./home/pages/my-agency-page/carrier-tractor-component/carrier-tractor-component";
ko.components.register("carrier-tractors-component", CarrierTractorComponent);
import ChangePasswordComponent from "./home/pages/my-agency-page/change-password-component/change-password-component";
ko.components.register("change-password-component", ChangePasswordComponent);
import CustomFieldNamesComponent from "./home/pages/my-agency-page/custom-field-names-component/custom-field-names-component";
ko.components.register(
  "custom-field-names-component",
  CustomFieldNamesComponent
);
import DefaultOrderOptionsComponent from "./home/pages/my-agency-page/default-order-options-component/default-order-options-component";
ko.components.register(
  "default-order-options-component",
  DefaultOrderOptionsComponent
);
import EDIPreferencesComponent from "./home/pages/my-agency-page/edi-preferences-component/edi-preferences-component";
ko.components.register("edi-preferences-component", EDIPreferencesComponent);
import EDITenderSetupComponent from "./home/pages/my-agency-page/edi-tender-setup-component/edi-tender-setup-component";
ko.components.register("edi-tender-setup-component", EDITenderSetupComponent);
import LoadboardOptionsComponent from "./home/pages/my-agency-page/loadboard-options-component/loadboard-options-component";
ko.components.register(
  "loadboard-options-component",
  LoadboardOptionsComponent
);
import MyFavoritsComponent from "./home/pages/my-agency-page/my-favorites-component/my-favorites-component";
ko.components.register("my-favorites-component", MyFavoritsComponent);
import OrderEntryArrangementsComponent from "./home/pages/my-agency-page/order-entry-arrangements-component/order-entry-arrangements-component";
ko.components.register(
  "order-entry-arrangements-component",
  OrderEntryArrangementsComponent
);
import EditOrderEntryArrangementsComponent from "./home/pages/my-agency-page/order-entry-arrangements-component/edit-order-entry-arrangement-component/edit-order-entry-arrangement-component";
ko.components.register(
  "edit-order-entry-arrangements-component",
  EditOrderEntryArrangementsComponent
);
import OrderTemplatesComponent from "./home/pages/my-agency-page/order-templates-component/order-templates-component";
ko.components.register("order-templates-component", OrderTemplatesComponent);
import PendingPartnerRequestsComponent from "./home/pages/my-agency-page/pending-partner-requests-component/pending-partner-requests-component";
ko.components.register(
  "pending-partner-requests-component",
  PendingPartnerRequestsComponent
);
import MyAgencyRateConfirmationComponent from "./home/pages/my-agency-page/rate-confirmation-component/rate-confirmation-component";
ko.components.register(
  "my-ageny-rate-confirmation-component",
  MyAgencyRateConfirmationComponent
);
import RegionManagementComponent from "./home/pages/my-agency-page/region-management-component/region-management-component";
ko.components.register(
  "region-management-component",
  RegionManagementComponent
);
import EditRegionManagementComponent from "./home/pages/my-agency-page/region-management-component/edit-region-management-component/edit-region-management-component";
ko.components.register(
  "edit-region-management-component",
  EditRegionManagementComponent
);

import EditRegionManagementAssignmentsComponent from "./home/pages/my-agency-page/region-management-component/edit-region-management-component/assignments-component/assignments-component";
ko.components.register(
  "edit-region-management-assignments-component",
  EditRegionManagementAssignmentsComponent
);
import EditRegionManagementStatesComponent from "./home/pages/my-agency-page/region-management-component/edit-region-management-component/states-component/states-component";
ko.components.register(
  "edit-region-management-states-component",
  EditRegionManagementStatesComponent
);
import EditRegionManagementAssignmentTypeDDLComponent from "./home/pages/my-agency-page/region-management-component/edit-region-management-component/assignments-type-ddl-component/assignments-type-ddl-component";
ko.components.register(
  "edit-region-management-assignment-type-ddl-component",
  EditRegionManagementAssignmentTypeDDLComponent
);
import MyAgencyOrderLockComponent from "./home/pages/my-agency-page/order-lock-component/order-lock-component";
ko.components.register(
  "my-agency-order-lock-component",
  MyAgencyOrderLockComponent
);

import MyAgencyCarrierRelationshipComponent from "./home/pages/my-agency-page/carrier-relationship-component/carrier-relationship-component";
ko.components.register(
  "carrier-relationship-component",
  MyAgencyCarrierRelationshipComponent
);

import EDIPage from "./home/pages/edi-page/edi-page";
ko.components.register("edi-page", EDIPage);

import MyProfilePage from "./home/pages/my-profile-page/my-profile-page";
ko.components.register("my-profile-page", MyProfilePage);

import LaneAnalysisPage from "./home/pages/lane-analysis-page/lane-analysis-page";
ko.components.register("lane-analysis-page", LaneAnalysisPage);

import TrainingVideosPage from "./home/pages/training-videos-page/training-videos-page";
ko.components.register("training-videos-page", TrainingVideosPage);
import ImageUploadComponent from "./home/shared-components/image-upload-component/image-upload-component";
ko.components.register("image-upload-component", ImageUploadComponent);

import SearchOrdersPage from "./home/pages/search-orders-page/search-orders-page";
ko.components.register("search-orders-page", SearchOrdersPage);

// Not Authorized
import NotAuthorized from "./home/pages/not-authorized-page/not-authorized-page";
ko.components.register("not-authorized-page", NotAuthorized);

// Reporting
import Reporting from "./home/pages/reporting-page/reporting-page";
ko.components.register("reporting-page", Reporting);
import MovementReport from "./home/pages/reporting-page/movement-report-page/movement-report-page";
ko.components.register("movement-report-page", MovementReport);

// EmployeeCalendar
import EmployeeCalendar from "./home/pages/employee-calendar-page/employee-calendar-page";
ko.components.register("employee-calendar-page", EmployeeCalendar);

// Employee Agency List
import EmployeeAgencyList from "./home/pages/employee-agency-list-page/employee-agency-list-page";
ko.components.register("employee-agency-list-page", EmployeeAgencyList);

import GEMTendersPage from "./home/pages/gem-tenders-page/gem-tenders-page";
ko.components.register("gem-tenders-page", GEMTendersPage);

import CustomerOrdersPage from "./home/pages/customer-orders-page/customer-orders-page";
ko.components.register("customer-orders-page", CustomerOrdersPage);

import CustomerOrderDetailComponent from "./home/pages/customer-orders-page/customer-order-detail-component/customer-order-detail-component";
ko.components.register(
  "customer-order-detail-component",
  CustomerOrderDetailComponent
);

import LoadImportPage from "./home/pages/load-import-page/load-import-page";
ko.components.register("load-import-page", LoadImportPage);
// Field Occurrence Recaps
import FieldOccurrenceRecapsPage from "./home/pages/field-occurrence-recaps-page/field-occurrence-recaps-page";
ko.components.register(
  "field-occurrence-recaps-page",
  FieldOccurrenceRecapsPage
);
import EditFieldOccurrenceRecapsPage from "./home/pages/field-occurrence-recaps-page/edit-field-occurrence-page/edit-field-occurrence-page";
ko.components.register(
  "edit-field-occurrence-page",
  EditFieldOccurrenceRecapsPage
);

// Route Calculator
import RouteCalculatorPage from "./home/pages/route-calculator-page/route-calculator-page";
ko.components.register("route-calculator-page", RouteCalculatorPage);

// Challenge Page
import ChallengePage from "./home/pages/challenge-page/challenge-page";
ko.components.register("challenge-page", ChallengePage);
import DATAQChallengePage from "./home/pages/challenge-page/dataq-challenge-entry-page/dataq-challenge-entry-page";
ko.components.register("dataq-challenge-entry-page", DATAQChallengePage);

// Trailers Page
import TrailersPage from "./home/pages/trailers-page/trailers-page";
ko.components.register("trailers-page", TrailersPage);

// Settlements Page(s)
import SettlementsPage from "./home/pages/settlements-pages/settlements-page";
ko.components.register("settlements-page", SettlementsPage);
import AgentSettlementsPage from "./home/pages/settlements-pages/agent-settlements-page/agent-settlements-page";
ko.components.register("agent-settlements-page", AgentSettlementsPage);
import DriverSettlementsPage from "./home/pages/settlements-pages/driver-settlements-page/driver-settlements-page";
ko.components.register("driver-settlements-page", DriverSettlementsPage);
import SalespersonSettlementsPage from "./home/pages/settlements-pages/salesperson-settlements-page/salesperson-settlements-page";
ko.components.register(
  "salesperson-settlements-page",
  SalespersonSettlementsPage
);

// Audit Log
import AuditLog from "./home/pages/auditlog-page/auditlog-page";
ko.components.register("auditlog-page", AuditLog);

// Alerts Page(s)
import AlertsPage from "./home/pages/alerts-page/alerts-page";
ko.components.register("alerts-page", AlertsPage);
import AlertsEntryComponent from "./home/pages/alerts-page/alerts-entry-component/alerts-entry-component";
ko.components.register("alerts-entry-component", AlertsEntryComponent);
import AlertsViewPage from "./home/pages/alerts-page/alerts-view-page/alerts-view-page";
ko.components.register("alerts-view-page", AlertsViewPage);

// Agencies
import AgenciesPage from "./home/pages/agencies-page/agencies-page";
ko.components.register("agencies-page", AgenciesPage);

// Customer Credit
import CustomerCredit from "./home/pages/customer-credit-page/customer-credit-page";
ko.components.register("customer-credit-page", CustomerCredit);

// Locations
import LoationsPage from "./home/pages/locations-page/locations-page";
ko.components.register("locations-page", LoationsPage);
import LoationsEntryComponent from "./home/pages/locations-page/location-entry-component/location-entry-component";
ko.components.register("locations-entry-component", LoationsEntryComponent);

// Violation Lookup
import ViolationLookupPage from "./home/pages/violation-lookup-page/violation-lookup-page";
ko.components.register("violation-lookup-page", ViolationLookupPage);

// Create Account
import CreateAccountPage from "./home/pages/create-account-page/create-account-page";
ko.components.register("create-account-page", CreateAccountPage);

// CSA Exceptions
import CSAExceptionsPage from "./home/pages/csa-exceptions-page/csa-exceptions-page";
ko.components.register("csa-exceptions-page", CSAExceptionsPage);

// Roadside Entry
import RoadsideEntryPage from "./home/pages/roadside-entry-page/roadside-entry-page";
ko.components.register("roadside-entry-page", RoadsideEntryPage);

// New Agenct Signon Checksheet
import NewAgentSignOnCheckSheetPage from "./home/pages/newagent-signon-checksheet-page/newagent-signon-checksheet-page";
ko.components.register(
  "newagent-signon-checksheet-page",
  NewAgentSignOnCheckSheetPage
);

// Priority Alerts
import PriorityAlertsPage from "./home/pages/priority-alerts-pages/priority-alerts-page";
ko.components.register("priority-alerts-page", PriorityAlertsPage);
import PriorityAlertsEntryComponent from "./home/pages/priority-alerts-pages/priority-alert-entry-component/priority-alert-entry-component";
ko.components.register(
  "priority-alerts-entry-component",
  PriorityAlertsEntryComponent
);

// Tracking Tractor
import TractorTrackingPage from "./home/pages/tractor-tracking-page/tractor-tracking-page";
ko.components.register("tractor-tracking-page", TractorTrackingPage);

// Rates Page (Carriers, Customers)
import RatesPage from "./home/pages/rates-page/rates-page";
ko.components.register("rates-page", RatesPage);
import CarrierRatesComponent from "./home/pages/rates-page/carrier-rates-component/carrier-rates-component";
ko.components.register("carrier-rates-component", CarrierRatesComponent);

// Inspection Lookup Page
import InspectionLookupPage from "./home/pages/inspection-lookup-page/inspection-lookup-page";
ko.components.register("inspection-lookup-page", InspectionLookupPage);

// Load Alerts Page
import LoadAlertsPage from "./home/pages/load-alerts-page/load-alerts-page";
ko.components.register("load-alerts-page", LoadAlertsPage);

// Documentation Page
import DocumentationPage from "./home/pages/documentation-page/documentation-page";
ko.components.register("documentation-page", DocumentationPage);

// Driver Portal Register Page
import DriverPortalRegisterPage from "./home/pages/driverportal-register-page/driverportal-register-page";
ko.components.register("driverportal-register-page", DriverPortalRegisterPage);

// Forgot Password
import ForgotPassword from "./home/pages/forgot-password-page/forgot-password-page";
ko.components.register("forgot-password", ForgotPassword);

// New ExternalBoardPage
import NewExternalBoardPage from "./home/pages/new-external-board-page/new-external-board-page";
ko.components.register("external-board-page-v2", NewExternalBoardPage);
import NewExternalBoardDetailsComponent from "./home/pages/new-external-board-page/external-board-details-component/external-board-details-component";
ko.components.register(
  "external-board-details-component",
  NewExternalBoardDetailsComponent
);
import NewExternalBoardSearchComponent from "./home/pages/new-external-board-page/external-board-search-component/external-board-search-component";
ko.components.register(
  "external-board-search-component",
  NewExternalBoardSearchComponent
);
import NewExternalBoardSearchResultsComponent from "./home/pages/new-external-board-page/external-board-search-results-component/external-board-search-results-component";
ko.components.register(
  "external-board-search-results-component",
  NewExternalBoardSearchResultsComponent
);
import NewExternalBoardRecentSearchesComponent from "./home/pages/new-external-board-page/external-board-recent-searches-component/external-board-recent-searches-component";
ko.components.register(
  "external-board-recent-searches-component",
  NewExternalBoardRecentSearchesComponent
);

// ExternalBoardPage
import ExternalBoardPage from "./home/pages/external-board-page/external-board-page";
ko.components.register("external-board-page", ExternalBoardPage);

// LoadTractorMapPage
import LoadTractorMapPage from "./home/pages/load-tractor-map-page/load-tractor-map-page";
ko.components.register("load-tractor-map-page", LoadTractorMapPage);

// 404 Not Found Page for Routes Not Found
import NotFoundPage from "./home/pages/not-found-page/not-found-page";
ko.components.register("not-found-page", NotFoundPage);

//InspectionLocationPage - Container for below 2 components.
import InspectionLocationPage from "./home/pages/inspection-locations-page/inspection-locations.page";
ko.components.register("inspection-locations-page", InspectionLocationPage);

//InspectionLocationList
import InspectionLocationsListComponent from "./home/pages/inspection-locations-page/inspection-locations-list-component/inspection-locations-list-component";
ko.components.register(
  "inspection-locations-list-component",
  InspectionLocationsListComponent
);

//EditInspectionLocation
import InspectionLocationsEditComponent from "./home/pages/inspection-locations-page/inspection-locations-edit-component/inspection-locations-edit-component";
ko.components.register(
  "inspection-locations-edit-component",
  InspectionLocationsEditComponent
);

import DriverCallInRecordsPage from "./home/pages/driver-call-in-records-page/driver-call-in-records-page";
ko.components.register("driver-call-in-records-page", DriverCallInRecordsPage);

import CarrierCallInRecordsPage from "./home/pages/carrier-call-in-records-page/carrier-call-in-records-page";
ko.components.register(
  "carrier-call-in-records-page",
  CarrierCallInRecordsPage
);

import ImagingPage from "./home/pages/imaging-page/imaging-page";
ko.components.register("imaging-page", ImagingPage);

import CarriersPage from "./home/pages/carriers-page/carriers-page";
ko.components.register("carriers-page", CarriersPage);
import CarriersSendCarrierPacketComponent from "./home/pages/carriers-page/carriers-send-carrier-packet-component/carriers-send-carrier-packet-component";
ko.components.register(
  "carriers-send-carrier-packet-component",
  CarriersSendCarrierPacketComponent
);

import DriversPage from "./home/pages/drivers-page/drivers-page";
ko.components.register("drivers-page", DriversPage);
import DriverDetailModalComponent from "./home/pages/drivers-page/driver-details-modal-component/driver-details-modal-component";
ko.components.register(
  "driver-details-modal-component",
  DriverDetailModalComponent
);

import AccessorialRestrictionPage from "./home/pages/accessorial-restriction-page/accessorial-restriction-page";
ko.components.register(
  "accessorial-restriction-page",
  AccessorialRestrictionPage
);

import ComdataCardPage from "./home/pages/comdata-card-page/comdata-card-page";
ko.components.register("comdata-card-page", ComdataCardPage);

import CarrierEntryPage from "./home/pages/carrier-entry-page/carrier-entry-page";
ko.components.register("carrier-entry-page", CarrierEntryPage);

import UserSecurityPage from "./home/pages/user-security-page/user-security-page";
ko.components.register("user-security-page", UserSecurityPage);

import RoleManagementPage from "./home/pages/role-management-page/role-management-page";
ko.components.register("role-management-page", RoleManagementPage);

import LoadTractorPostingPage from "./home/pages/load-tractor-postings-page/load-tractor-posting-page";
ko.components.register("load-tractor-posting-page", LoadTractorPostingPage);
import LoadPostingsComponent from "./home/pages/load-tractor-postings-page/load-postings-component/load-postings-component";
ko.components.register("load-postings-component", LoadPostingsComponent);
import TractorPostingsComponent from "./home/pages/load-tractor-postings-page/tractor-postings-component/tractor-postings-component";
ko.components.register("tractor-postings-component", TractorPostingsComponent);

import AgentDefaultRatesPage from "./home/pages/agent-default-rates-page/agent-default-rates-page";
ko.components.register("agent-default-rates-page", AgentDefaultRatesPage);
import AgentDefaultRatesGridComponent from "./home/pages/agent-default-rates-page/agent-default-rates-grid-component/agent-default-rates-grid-component";
ko.components.register(
  "agent-default-rates-grid-component",
  AgentDefaultRatesGridComponent
);
import AgentDefaultRatesModalComponenet from "./home/pages/agent-default-rates-page/agent-default-rates-modal-component/agent-default-rates-modal-component";
ko.components.register(
  "agent-default-rates-modal-component",
  AgentDefaultRatesModalComponenet
);

import OwnerOperatorRatesPage from "./home/pages/owner-operator-rates-page/owner-operator-rates-page";
ko.components.register("owner-operator-rates-page", OwnerOperatorRatesPage);
import OwnerOperatorRatesGridComponent from "./home/pages/owner-operator-rates-page/owner-operator-rates-grid-component/owner-operator-rates-grid-component";
ko.components.register(
  "owner-operator-rates-grid-component",
  OwnerOperatorRatesGridComponent
);
import OwnerOperatorRatesModalComponent from "./home/pages/owner-operator-rates-page/owner-operator-rates-modal-component/owner-operator-rates-modal-component";
ko.components.register(
  "owner-operator-rates-modal-component",
  OwnerOperatorRatesModalComponent
);

import WiresPageComponent from "./home/pages/wires-page/wires-page-component";
ko.components.register("wires-page-component", WiresPageComponent);

import ContactListManagementPage from "./home/pages/contact-list-management-page/contact-list-management-page";
ko.components.register(
  "contact-list-management-page",
  ContactListManagementPage
);

import ContactsPage from "./home/pages/contacts-page/contacts-page";
ko.components.register("contact-page", ContactsPage);

/*=== MISC | Shared Components */
import QuickOrderSearchComponent from "./home/shared-components/quick-order-search-component/quick-order-search-component";
ko.components.register(
  "quick-order-search-component",
  QuickOrderSearchComponent
);

import CurrentAgencyDDLComponent from "./home/shared-components/current-agency-ddl-component/current-agency-ddl-component";
ko.components.register(
  "current-agency-ddl-component",
  CurrentAgencyDDLComponent
);

import CallLeadsEntryComponent from "./home/shared-components/call-leads-entry-component/call-leads-entry-component";
ko.components.register("call-leads-entry-component", CallLeadsEntryComponent);

import RadiusSearchComponent from "./home/shared-components/radius-search-component/radius-search-component";
ko.components.register("radius-search-component", RadiusSearchComponent);

import CurrentAgencyAutocompleteComponent from "./home/shared-components/current-agency-autocomplete-component/current-agency-autocomplete-component";
ko.components.register(
  "current-agency-autocomplete-component",
  CurrentAgencyAutocompleteComponent
);

import TenderLoadComponent from "./home/shared-components/tender-load-component/tender-load-component";
ko.components.register("tender-load-component", TenderLoadComponent);

import CreateOrderFromLoadComponent from "./home/pages/load-tractor-postings-page/subcomponents/create-order-from-load-component/create-order-from-load-component";
ko.components.register(
  "create-order-from-load-component",
  CreateOrderFromLoadComponent
);

import SearchFilterSavesComponent from "./home/shared-components/SearchFilter-Saves-Component/SearchFilter-Saves-Component";
ko.components.register(
  "search-filter-saves-component",
  SearchFilterSavesComponent
);

import deleteGridFilters from "./home/pages/my-agency-page/delete-grid-filters-component/delete-grid-filters";
ko.components.register("delete-grid-filters-component", deleteGridFilters);

import CarrierFraudContactsComponent from "./home/shared-components/carrier-fraud-contacts-component/carrier-fraud-contacts-component";
ko.components.register(
  "carrier-fraud-contacts-component",
  CarrierFraudContactsComponent
);

import TPIViewRatesModalComponent from "./home/shared-components/tpi-view-rates-modal-component/tpi-view-rates-modal-component";
ko.components.register(
  "tpi-view-rates-modal-component",
  TPIViewRatesModalComponent
);

import TendersPage from "./home/pages/tenders-page/tenders-page";
ko.components.register("tenders-page-component", TendersPage);

import CustomerApiSetupModal from "./home/pages/tenders-page/components/customer-api-setup-modal/customer-api-setup-modal";
ko.components.register("customer-api-setup-modal", CustomerApiSetupModal);
import TenderManualBidModal from "./home/pages/tenders-page/components/tender-bid-modal/tender-bid-modal";
ko.components.register("tender-bid-modal", TenderManualBidModal);

import GridAutoRefreshComponent from "./home/shared-components/grid-auto-refresh-component/grid-auto-refresh-component";
ko.components.register(
    "grid-auto-refresh-component",
    GridAutoRefreshComponent
);


//This is how you lazy load a module.  Webpack will produce a seperate chunk for this page if done
//like this.  We don't need to do it for our pages now but may in the future.
// ko.components.register('orders-page', require('bundle-loader?lazy!./home/orders-page/orders-page'))

// Start the application. Pass default selected route.  This context will be used in _Layout.cshtml and Index.cshtml.
var displayPage = ko.computed(function () {
  return (
    (router.currentRoute().allowGuest || userProfile.loggedIn()) &&
    userProfile.userProfileInitialized()
  );
});

ko.applyBindings(
  {
    route: router.currentRoute,
    userProfileInitialized: userProfile.userProfileInitialized,
    loggedIn: userProfile.loggedIn,
    displayPage: displayPage, // check if the page can be viewed by users who are not logged in
  },
  document.getElementById("ge-app")
);

console.info("app started");
