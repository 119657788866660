import ko from 'knockout';
import template from './order-planning-page.html';
import OrderEntryTracking from '../order-entry-page/order-entry-tracking-component/order-entry-tracking-component';
import userProfile from 'user-profile';
import dayjs from 'dayjs';
import dayjsDuration from 'dayjs/plugin/duration';
import * as $ from 'jquery';
import {Observable} from 'knockout'
import { OrderPlanningOrderGrid } from './order-grid-component/order-grid-component';
import { OrderPlanningTractorGrid } from './tractor-grid-component/tractor-grid-component';
import {loadGridState} from './tractor-grid-component/tractor-grid-component';
import {loadSavedSearches} from "../../shared-components/SearchFilter-Saves-Component/SearchFilter-Saves-Component";
import GridStateComponentViewModel from 'jqx.grid-state-component';
dayjs.extend(dayjsDuration);


class OrderPlanningViewModel {
    //These should be the models that they're pointing to.
    //Currently don't have a value but when ko creates them,
    /**@type {OrderPlanningOrderGrid} */
    orderGridComponent;
    /**@type {OrderPlanningTractorGrid} */
    tractorGridComponent;
    headerActionButtonsComponent;
    footerComponent;
    //This will store the params object the router passes in.
    params;

    errors = ko.observableArray();
    isLoading = ko.observable(false);
    customDataFields = ko.observable({});
    carrierPreassignment = ko.observable();
    matchTractorId = ko.observable();
    callInListModal = ko.observable();
    newCallInModal = ko.observable();
    callInModals = { callInListModal: this.callInListModal, newCallInModal: this.newCallInModal }
    isOwnerOperator = userProfile.isOwnerOperator;
    isLoadboard = false;
    clearSelectedSavedSearchDDL = ko.observable(false);
    refreshSavedSearchDDL = ko.observable("");
   
    ordersGridLoaded = false;
    tractorsGridLoaded = false;
    showOrdersGrid = ko.observable(true); // used to hide the date range sliders
    _currentDefaultGridToggleOptions = {
        timestamp: undefined, 
        showOrdersGrid: true, 
        showTractorsGrid: true
    };
    defaultGridToggleOptions = ko.pureComputed({
        read: () => this._currentDefaultGridToggleOptions,
        write: ({timestamp, showOrdersGrid, showTractorsGrid}) => {
            const _current = this._currentDefaultGridToggleOptions;

            if(_current.timestamp == null || timestamp > _current.timestamp) {
                this._currentDefaultGridToggleOptions = {timestamp, showOrdersGrid, showTractorsGrid};
            }
        }
    })
    

    /**@type { Observable<OrderEntryTracking | undefined> } */
    orderEntryTracking = ko.observable();  //new'd up in the header-action-buttons-component

    constructor(params) {
        this.params = params;
        this.params[0] = this.params[0] || {};
        
        if ((userProfile.isOwnerOperator || userProfile.isUserDriver) &&
            userProfile.currentAgencyId() == userProfile.userAgencyId &&
            userProfile.restrictedLoadboard) {
                userProfile.notAuthorized();
        }
    }

    // getSavedSearches() {
    //     return new Promise(function (resolve, reject) {
    //         dataModel
    //             .ajaxRequest("UserProfile/GetUserSearchFilterList", "get", {
    //                 name: "jqxOrderGrid",
    //                 pageName: window.location.pathname,
    //             })
    //             .done(function (data) {
    //                 if (data) {
    //                     var obj = data.map((x) => {
    //                         let search = JSON.parse(x.search);
    //                         return {
    //                             text: search.userSearchName || "",
    //                             search: new NamedGridStateObject(search),
    //                             id: x.id,
    //                         };
    //                     });
    //                     resolve(obj);
    //                 } else {
    //                     resolve(undefined);
    //                 }
    //             })
    //             .fail(function () {
    //                 reject();
    //             });
    //     });
    // }
    renderToolBar = (toolbar) => {
        var vm1 = new GridStateComponentViewModel.viewModel(this.pageName);
        vm1.actions.push("Saved Search");
        this.grid = $('#jqxOrderGrid');
        vm1.clearFilters = () => {
            // Handle reset select filter list
            this.clearSelectedSavedSearchDDL(true);
                this.grid.jqxGrid('clearfilters');
                this.clearSelectedSavedSearchDDL(true);
        };
        vm1.loadSaveSearchModalOverride = async () => {
            const savedSearches = await loadSavedSearches("jqxOrderGrid");

            var filters = {
                defaultGridToggleOptions: { ...this.defaultGridToggleOptions(), timestamp: Date.now() },
                isDefault: false
            };

            vm1.loadSaveSearchModal(
                this.grid, 
                (val) => {
                    if(val && val.searchname) {
                        this.refreshSavedSearchDDL(val.searchname)
                    }
                },
                savedSearches.filter(x => x.searchName).map(x => ({id: x.id, text: x.searchName})),
                filters,
                true
            );
            
        

                vm1.setDefaultSearchOverride = async () => {
        
                    var filters = {
                        defaultGridToggleOptions: { ...this.defaultGridToggleOptions(), timestamp: Date.now() },
                        isDefault: true
                    };
        
                    vm1.loadSaveSearchModal(
                        this.grid, 
                        (val) => {
                            if(val && val.searchname) {
                                this.refreshSavedSearchDDL(val.searchname)
                            }
                        },
                        savedSearches.filter(x => x.searchName).map(x => ({id: x.id, text: x.searchName})),
                        filters,
                        true
                    );
                    
                };

            }

        };

            // vm1.setDefaultSearchOverride = async () => {
            //     const savedSearches = await loadSavedSearches("jqxOrderGrid");
                
    
            //     var filters = {
            //         defaultGridToggleOptions: { ...this.defaultGridToggleOptions(), timestamp: Date.now() },
            //         isDefault: true
            //     };
    
            //     vm1.loadSaveSearchModal(
            //         this.grid, 
            //         (val) => {
            //             if(val && val.searchname) {
            //                 this.refreshSavedSearchDDL(val.searchname)
            //             }
            //         },
            //         savedSearches.filter(x => x.searchName).map(x => ({id: x.id, text: x.searchName})),
            //         filters,
            //         true
            //     );
                
            // };
             

    //This is getting called at the end of the footerComponent constructor
    loadLotsOfStuff() {
        Promise.all([
            this.footerComponent.loadUserManualsAndCustomDataFields(),
            // this.orderGridComponent.loadGridFilters(),
            // this.tractorGridComponent.loadGridFilters(),
            this.headerActionButtonsComponent.getAndLoadRegions()
        ]).then(() => {
            
            const {showOrdersGrid, showTractorsGrid} = this.defaultGridToggleOptions() || {};
            if(this.ordersGridLoaded == false && showOrdersGrid) {

                this.orderGridComponent.initFiltersLoaded.subscribe((yes) => {

                    if(yes && this.ordersGridLoaded === false) {
                        this.orderGridComponent.loadOrders({initHeight: (showOrdersGrid ? 400 : 800)})
                        this.ordersGridLoaded = true;
                    }
                })

            }
            else {
                this.toggleOrders();
            }
            
            if(this.tractorsGridLoaded == false && showTractorsGrid) {
                this.tractorGridComponent.filtersLoaded.subscribe((yes) => {
                    if(this.tractorsGridLoaded === false && yes){
                        this.tractorGridComponent.loadTractors({initHeight: (showOrdersGrid ? 400 : 800)});
                        this.tractorsGridLoaded = true;
                    }
                    
                })
                
            }
            else {
                this.toggleTractors();
            }

            return;
        })
    }

    // loadGrids = async () => {
    //     return Promise.all([this.orderGridComponent.loadOrders(),this.tractorGridComponent.loadTractors()]);
    // }

    toggleOrders = () => {
        var grid = $("#jqxOrderGrid");
        var isToggled = $("#divOrderPanel").is(":visible");
        var $this = $("#toggleOrdersSpan");
        if (isToggled) {
            $this.find('i').removeClass('glyphicon-chevron-up').addClass('glyphicon-chevron-down');

            if(this.tractorsGridLoaded) {
                grid.jqxGrid({ height: 800 });
            }
            
            this._currentDefaultGridToggleOptions.showOrdersGrid = false;
            this.showOrdersGrid(false)
        }
        else {
            $this.find('i').removeClass('glyphicon-chevron-down').addClass('glyphicon-chevron-up');
            
            if(this.tractorsGridLoaded) {
                grid.jqxGrid({ height: 400 });
            }
            
            this._currentDefaultGridToggleOptions.showOrdersGrid = true;
            this.showOrdersGrid(true)
        }

        $("#divOrderPanel").animate({ height: 'toggle' }, "fast", function() {
            const $el = $(this);
            if(isToggled) {
                $el.css({'margin-bottom': '30px'});
            }
            else {
                $el.css({'margin-bottom': '0px'});
            }
        });

        if(this.ordersGridLoaded == false && this._currentDefaultGridToggleOptions.showOrdersGrid) {
            this.orderGridComponent.loadOrders();
            this.ordersGridLoaded = true;
        }

    };

    toggleOrdersFromMap = () => this.toggleOrders();

    toggleTractors = () => {
        var isToggled = $("#divTractorPanel").is(":visible");
        var $this = $('#tractorGridToggle');
        if (isToggled) {
            $this.find('i').removeClass('glyphicon-chevron-up').addClass('glyphicon-chevron-down');
            
            if(this.ordersGridLoaded) {
                this.orderGridComponent.grid.jqxGrid({ height: 800 });
            }

            this._currentDefaultGridToggleOptions.showTractorsGrid = false;
        }
        else {
            $this.find('i').removeClass('glyphicon-chevron-down').addClass('glyphicon-chevron-up');
            
            if(this.ordersGridLoaded) {
                this.orderGridComponent.grid.jqxGrid({ height: 400 });
            }

            this._currentDefaultGridToggleOptions.showTractorsGrid = true;
        }
        $("#divTractorPanel").animate({ height: 'toggle' }, "fast");

        if(this.tractorsGridLoaded == false && this._currentDefaultGridToggleOptions.showTractorsGrid) {
            this.tractorGridComponent.loadTractors();
            this.tractorsGridLoaded = true;
        }
    };
}

export { OrderPlanningViewModel }
export default { viewModel: OrderPlanningViewModel, template: template }