import { Base64 } from "global-functions";

type FetchFunction<T> = () => Promise<T>;

interface CacheEntry<T> {
    data: T;
    expiration: number;
}

class QueryCache {
    private cache: Map<string, CacheEntry<any>> = new Map();
    private storageKeyPrefix: string = " GE_QueryCache_";
    private storageEventListener: ((event: StorageEvent) => void) | null = null;
    private unloadEventListener: (() => void) | null = null;

    constructor(private defaultTTL: number = 1000 * 60 * 5) {
        // Initialize the storage event listener
        this.storageEventListener = this.handleStorageEvent.bind(this);
        window.addEventListener("storage", this.storageEventListener);

        // Attach the unload event listener for cleanup on close or navigation
        this.unloadEventListener = this.destroy.bind(this);
        window.addEventListener("beforeunload", this.unloadEventListener);
    }

    private handleStorageEvent(event: StorageEvent): void {
        if (event.key && event.key.startsWith(this.storageKeyPrefix)) {
            const key = event.key.replace(this.storageKeyPrefix, "");
            const newValue = event.newValue;

            if (newValue) {
                const parsedEntry: CacheEntry<any> = JSON.parse(newValue);
                const now = Date.now();
                // Update in-memory cache if the entry is still valid
                if (parsedEntry.expiration > now) {
                    this.cache.set(key, parsedEntry);
                } else {
                    this.cache.delete(key);
                }
            } else {
                // If localStorage entry is removed, clear the corresponding in-memory cache
                this.cache.delete(key);
            }
        }
    }

    async fetch<T>(key: string, fetchFunction: FetchFunction<T>, ttl?: number): Promise<T> {
        const now = Date.now();

        // Check both in-memory cache and localStorage
        let cachedEntry = this.cache.get(key);
        if (!cachedEntry) {
            const localStorageEntry = localStorage.getItem(this.storageKeyPrefix + key);
            if (localStorageEntry) {
                const decoded = Base64.decode(localStorageEntry);
                cachedEntry = JSON.parse(decoded);
                // Update in-memory cache
                if (cachedEntry.expiration > now) {
                    this.cache.set(key, cachedEntry);
                } else {
                    // Remove expired cache from localStorage
                    localStorage.removeItem(this.storageKeyPrefix + key);
                    cachedEntry = undefined;
                }
            }
        }

        // Return cached data if it's still valid
        if (cachedEntry && cachedEntry.expiration > now) {
            return cachedEntry.data;
        }

        // Fetch new data if not in cache or cache is expired
        try {
            const data = await fetchFunction();
            const expiration = now + (ttl || this.defaultTTL);

            const newCacheEntry = { data, expiration };
            this.cache.set(key, newCacheEntry);

            // Update localStorage
            const encoded = Base64.encode(JSON.stringify(newCacheEntry));
            localStorage.setItem(this.storageKeyPrefix + key, encoded);

            return data;
        } catch (error) {
            throw new Error(`Failed to fetch data for key "${key}": ${error}`);
        }
    }

    clear(key: string): void {
        this.cache.delete(key);
        localStorage.removeItem(this.storageKeyPrefix + key);
    }

    clearAll(): void {
        this.cache.clear();

        // Clear all related entries from localStorage
        for (const key in localStorage) {
            if (key.startsWith(this.storageKeyPrefix)) {
                localStorage.removeItem(key);
            }
        }
    }

    destroy(): void {
        // Remove the storage listener if it exists
        if (this.storageEventListener) {
            window.removeEventListener("storage", this.storageEventListener);
            this.storageEventListener = null;
        }
        // Remove the unload listener if it exists
        if (this.unloadEventListener) {
            window.removeEventListener("beforeunload", this.unloadEventListener);
            this.unloadEventListener = null;
        }
        // Clear the in-memory cache
        this.cache.clear();
    }
}

export default QueryCache;