import dataModel from "data-model";
import ko, { pureComputed } from "knockout";
import {
  getUniqueValues,
} from "global-functions";
import template from "./order-entry-dispatch-brokerage-component.html";
import { showconfirm, showmessage } from "show-dialog-methods";
import dayjs from "dayjs";
import { Observable, ObservableArray } from "knockout";
import { datetimeUTC } from "global-functions";
import { OrderEntryViewModel } from "../../order-entry-page";

import { useDispatch } from "ko-data-store";
import { isLoading } from "../../../../../dataStore/actions/appUI";
import RateService from "../../../../../services/RateService";
import TntContext from "../../order-entry-track-trace-component/trackTraceOrderEntryContext";
import queryCache from "../../../../../utils/queryCache";



//Adding a namespace so we can access our classes/service methods when out of scope.
/**@type { DispatchService } */
var Dispatch;
class OrderEntryDispatchBrokerageViewModel {
  /**@type { OrderEntryViewModel } */
  $componentParent;
  /**@type { Observable<OrderEntryTracking> } */
  orderEntryTracking;
  /**@type { ObservableArray<Movement> } */
  movements = ko.observableArray([]);

  constructor(params) {
    var self = this;

    self.dispatchAction = useDispatch();

    self.$tntContext = TntContext;

    self.orderEntryTracking = params.$orderEntryTracking;
    Dispatch = new DispatchService(self.orderEntryTracking);
    self.orderEntryTracking = params.$orderEntryTracking;
    self.$componentParent = params.$componentParent || {};

    self.$componentParent.beforeSaveCallbacks.push(async (orderData = {}) => {
      const moves = ko.toJS(self.movements());

      // using both flags here as they update at different times.
      // When saving order that is not changed from broker -> truckline then both will be true.
      if (orderData.getIsBrokered && orderData.brokered) {
        (moves ?? []).forEach((x) => {
          if (
            x.movementStatus === "Covered" &&
            x.carrier != null &&
            x.overridePayAmount === 0
          ) {
            throw new Error("Rate is required.");
          }
        });
      }
    });

    params = params.params();
    self.orderId = params.orderId;
    self.orderExId = ko.observable(params.orderExId);
    self.movements = ko.observableArray([]);
    params.orderMovements = self.movements;
    self.showLoadingWheel = function (val) {
      self.$componentParent.isLoading(val);
    };
    self.showLoadingWheel(true);

    //Incoming observables.
    self.params = params;
    self.indexCounter = 0;

    self.loadMovements = function () {
      Dispatch.getMovements(self.orderId).then(function (movements) {
        movements[0].isFirstInSequence = true;
        movements[movements.length - 1].isLastInSequence = true;

        let movementCounter = 1; //The sequence that comes from the database isn't reliable in this case.
        self.movements(
          movements.map(function (move) {
            move.sequence = movementCounter;
            movementCounter++;

            return new Movement(move, self);
          })
        );
        self
          .movements()
          [self.movements().length - 1].carrierSelectedValue.subscribe(
            function (c) {
              if (c) {
                self.$componentParent.summary().driverCarrier(c.code);
              } else {
                self.$componentParent.summary().driverCarrier(undefined);
              }
            }
          );
        self.showLoadingWheel(false);

        self.loadTrackingMovements(self.movements());
        self.movements.subscribe(self.loadTrackingMovements);
      });
    };

    self.loadMovements();
    self.params.saveOrderEntryDispatch = async (skipLockCheck = false) => {
      // Need to wait for each save to finish before resolving this promise,
      // otherwise race conditions could occur on classes that need this saved data from db.
      const result = await self
        .movements()
        .reduce(async (prevAllResults, move) => {
          const allResults = await prevAllResults;
          const moveResult = await move.saveMovement(skipLockCheck);

          allResults.push(moveResult);
          return allResults;
        }, Promise.resolve([]));

      return result;
    };

    self.orderEntryErrors = params.orderEntryErrors;
    self.errorModal = ko.observable();
    self.carrierExtraPayModal = ko.observable();
    self.carrierExtraPayModalHeader = ko.observable();
    self.rateConfirmationModal = ko.observable();
    self.orderEntryDispatchCancelDispatchModal = ko.observable();

    return self;
  }

  loadTrackingMovements = (movements = []) => {
    const moves = ko.toJS(movements);
    (moves ?? []).forEach((x) => {
      TntContext.loadTrackingForMovement(x.movementExId, {
        movementStatus: x.movementStatus,
        movementSequence: x.sequence,
        movementId: x.movementId,
        tracking: {
          orderId: x.orderExId,
          driver1Phone: x.carrierDriver1?.phone,
          driver1Name: x.carrierDriver1?.name,
          carrierAssignment: {
            carrierMC: x.carrierMcNumber,
            carrierContactEmail: x.carrierContactEmail,
            carrierPhone: x.carrierContactPhone,
            carrierTractor: x.carrierTractor,
            carrierTrailer: x.carrierTrailer,
          },
          trucklineAssignment: {}
        }
      });
    });
  };
}

/////////////////////////////////////// Classes //////////////////////////////////////////
class Movement {
  /**@type { OrderEntryDispatchBrokerageViewModel } */
  $parent;
  tpiViewRatesProps = ko.observable();
  brokerageStatusDDTCache = new queryCache(1000 * 60);
  $trackedMove;

  constructor(move, $parent) {
    move = move || {};
    var self = this;
    self.dispatchAction = useDispatch();

    self.$parent = $parent;

    self.vmReady = ko.observable(false);
    self.carrierTractor = ko
      .observable(move.carrierTractor)
      .extend({ maxLength: 12 });
    self.carrierTrailer = ko
      .observable(move.carrierTrailer)
      .extend({ maxLength: 12 });
    self.movementDistance = ko.observable(move.movementDistance);
    self.movementId = ko.observable(move.movementId);
    self.orderId = ko.observable(move.orderId);
    self.orderExId = ko.observable(move.orderExId);
    self.sequence = ko.observable(move.sequence);
    self.readyToBill = ko.observable(move.readyToBill);
    //These 2 variables just make things easier to deal with.
    self.isFirstInSequence = ko.observable(move.isFirstInSequence);
    self.isLastInSequence = ko.observable(move.isLastInSequence);

    self.carrierMcNumber = ko.observable(move.carrierMCNumber);
    self.carrierContactId = ko.observable(move.carrierContactId);
    self.carrierContactName = ko.observable(move.carrierContactName);
    self.carrierContactEmail = ko.observable(move.carrierContactEmail);
    self.carrierContactPhone = ko.observable(move.carrierContactPhone);
    self.carrierContactFax = ko.observable(move.carrierContactFax);

    self.movementExId = ko.observable(move.movementExId);
    self.movementStatus = ko.observable(move.movementStatus);

    this.$trackedMove = TntContext.selectTrackedMovement(self.movementExId());

    self.index = $parent.indexCounter;
    $parent.indexCounter++;

    self.brokerageStatusDDT = ko.observableArray([]);
    this.getBrokerageStatusDDT().then((data) => {
          const mapped = (data ?? []).map(x => ({label: x.code, value: x.id, description: x.description}));

          self.brokerageStatusDDT(mapped);
        }).then(() => {
          self.brokerageStatusId(move.brokerageStatusId);
    });

    self.optionsAfterRender = (el, item) => {
      if (item && item.description) {
        // Set the description as a tooltip attribute on the <option>
        ko.applyBindingsToNode(el, { attr: { title: item.description } }, item);
      }
    }

    // data-bind element used in the UI / html
    self.selectBrokerageStatusTooltip = ko.pureComputed(() => {
      const selection = (self.brokerageStatusDDT() ?? []).find(x => x.value === self.brokerageStatusId());
      return selection?.description ?? null;
    })

    self.showTrackingForMovement = ko.pureComputed(() => {
      if (TntContext.isTrackingRequired()) {
        return true;
      }
      if (
          ["In Progress", "Available", "Covered"].indexOf(
              self.movementStatus()
          ) === -1
      ) {
        return false;
      }

      return TntContext.isTrackingOn();
    });

    self.customerName = ko.observable(
      self.$parent.params.selectedCustomer()
        ? self.$parent.params.selectedCustomer().name
        : undefined
    );
    self.$parent.params.selectedCustomer.subscribe(function (value) {
      if (value) {
        self.customerName(value.name);
      } else {
        self.customerName("");
      }
    });

    self.carrier = ko
      .observable(move.carrierExId)
      .extend({ required: { params: true, message: "Carrier is required." } });

    self.carrierSelectedValue = ko.observable({
      isActive: move.carrierActive,
      mcNumber: move.carrierMCNumber,
      externalId: move.carrierExId,
      code: move.carrierExId,
      name: move.carrierName,
      id: move.carrierId,
    });
    self.carrierHasIntrastateAuthorityOnly = ko.observable();
    self.carrierHasCarrierRelationshipManager = ko.observable();
    self.showCarrierInactiveWarning = ko.pureComputed(
      () =>
        self.carrierSelectedValue() &&
        self.carrierSelectedValue().id > 0 &&
        self.carrierSelectedValue().isActive == false &&
        self.movementStatus() != "Delivered"
    );

    self.includeInactiveCarriersAutocomplete = ko.observable(true);
    self.carrierRelationshipManager = ko.observable();

    self.carrierSelectedValue.subscribe(function (value) {
      self.carrierMcNumber(value && value.mcNumber);
      self.carrierHasCarrierRelationshipManager(false);

      if (value) {
        const mvStatus = self.movementStatus() || move.movementStatus;

        self.movementStatus(
          mvStatus !== "Available" && mvStatus !== "Voided"
            ? mvStatus
            : "Covered"
        );

        self.overridePayeeId(value.code);
        self.brokerageStatusId(1);

        Dispatch.getCarrierIntrastateAuthority({
          carrierId: value.id,
        }).then(function (intra) {
          self.carrierHasIntrastateAuthorityOnly(
            intra.intrastateAuthority ? intra.intrastateAuthority : false
          );
        });

        Dispatch.getCarrierRelationshipManager({
          carrierId: value.id,
          orderAgencyId: move.agencyId,
        }).then(function (crm) {
          self.carrierRelationshipManager(crm.relationshipManager);
          self.carrierHasCarrierRelationshipManager(
            crm.relationshipManager != null &&
              crm.relationshipManager.length > 0
          );
        });

        TntContext.upsertTrackedMovement(self.movementExId(), {
          tracking: {
            carrierAssignment: {
              carrierMC: value.mcNumber,
            },
          },
        });
      } else {
        self.movementStatus("Available");

        TntContext.upsertTrackedMovement(self.movementExId(), {
          tracking: {
            carrierAssignment: {
              carrierMC: undefined,
            },
          },
        });
      }
    });

    if (move.carrierId) {
      Dispatch.getCarrierIntrastateAuthority({
        carrierId: move.carrierId,
      }).then(function (intra) {
        self.carrierHasIntrastateAuthorityOnly(
          intra.intrastateAuthority ? intra.intrastateAuthority : false
        );
      });

      Dispatch.getCarrierRelationshipManager({
        carrierId: move.carrierId,
        orderAgencyId: move.agencyId,
      }).then(function (crm) {
        self.carrierRelationshipManager(crm.relationshipManager);
        self.carrierHasCarrierRelationshipManager(
          crm.relationshipManager != null && crm.relationshipManager.length > 0
        );
        self.stopExtraRequests = false;
      });
    }

    self.vmReady.subscribe(() => {
      self.includeInactiveCarriersAutocomplete(false); //false //Switch this as soon as the page has the chance to load the carrier autocomplete data.

      TntContext.upsertTrackedMovement(self.movementExId(), {
        tracking: {
          carrierAssignment: {
            carrierMC: move.carrierMCNumber,
            carrierContactEmail: move.carrierContactEmail,
            carrierPhone: move.carrierContactPhone,
            carrierTractor: move.carrierTractor,
            carrierTrailer: move.carrierTrailer,
          },
          trucklineAssignment: {}
        },
      });
    });

    self.showCarrierInactiveWarning.subscribe(
      ((init) => {
        return (yes) => {
          if (yes && init) {
            showmessage(`The assigned carrier is inactive and cannot be dispatched. Please contact 
                        <a href="mailto:brokerage@greatwide-tm.com" style="color: blue;">brokerage@greatwide-tm.com</a> if you have any questions.`);

            init = false;
          }
        };
      })(true)
    );

    self.isIntrastateMovement = ko.observable(
      getUniqueValues(move.stops, "state").length > 1
    );

    self.handleCarrierDDLIsLoading = (showLoader) => {
      if (move.carrierExId) {
        self.dispatchAction(isLoading(showLoader));
      }
    };

    self.showCarrierRelationshipManager = ko
      .computed(function () {
        return (
          self.carrierSelectedValue() &&
          self.carrierHasCarrierRelationshipManager()
        );
      })
      .extend({ rateLimit: 500 });

    self.showCarrierIntrastateBlockMessage = ko
      .computed(function () {
        return (
          self.carrierSelectedValue() &&
          self.isIntrastateMovement() &&
          self.carrierHasIntrastateAuthorityOnly()
        );
      })
      .extend({ rateLimit: 500 });

    self.brokerageStatusId = ko.observable(move.brokerageStatusId);

    self.authorized = ko.observable(move.authorized == false ? false : true);
    self.carrierOrderNumber = ko.observable(move.carrierOrderNumber);

    self.salesPerson = ko.observable(
      self.$parent.params.salesPersonSelectedValue()
        ? self.$parent.params.salesPersonSelectedValue().code
        : undefined
    );
    self.$parent.params.salesPersonSelectedValue.subscribe(function (value) {
      if (value) {
        self.salesPerson(value.code);
      } else {
        self.salesPerson("");
      }
    });

    self.carrierDriverUserId = move.carrierDriverUserId;
    self.carrierDriver1 = ko
      .observable(
        new CarrierDriverInfo(
          move.carrierDrivers.find((driver) => driver.isPrimaryDriver) || {
            isPrimaryDriver: true,
            movementId: self.movementId,
          }
        )
      )
      .extend({ notify: "always" });
    self.carrierDriver2 = ko.observable(
      new CarrierDriverInfo(
        move.carrierDrivers.find((driver) => driver.isPrimaryDriver == false)
      )
    );

    self.displayTeamDriver = ko.observable(
      self.carrierDriver2() && self.carrierDriver2().id() > 0
    );
    self.displayTeamDriver.subscribe((val) => {
      self.carrierDriver2(
        val
          ? new CarrierDriverInfo({
              isPrimaryDriver: false,
              movementId: self.movementId,
            })
          : null
      );
    });

    self.stopExtraRequests = false;
    self.overrideDriverName = ko.observable(move.overrideDriverName);

    self.showDriverPhoneMessage = ko.pureComputed(() => {
      return (
        TntContext.isTrackingOn() &&
        (!self.carrierDriver1() || !self.carrierDriver1().phone()) &&
        this.$trackedMove().tracking.byPassTracking != true
      );
    });

    self.showDriver2TrackingPhoneMessage = ko.pureComputed(() => {
      return (
        TntContext.isTrackingOn() &&
        self.displayTeamDriver() &&
        this.$trackedMove().tracking.byPassTracking
      );
    });

    self.handleLoadDriverLastEquipment = () => {
      const { email, phone } = ko.toJS(self.carrierDriver1());
      let userId =
        move.carrierDrivers.length > 0 ? null : move.carrierDriverUserId;

      if (phone || (userId && phone)) {
        self.loadCarrierDriverLastEquipment({
          phone: phone,
          userId: userId,
          email: email,
        });
      }
    };

    self.loadCarrierDriverLastEquipment = ({
      userId = null,
      email = null,
      phone = null,
    }) => {
      Dispatch.getCarrierDriverLastEquipment({
        userId: userId,
        email: email,
        phone: phone,
      }).then(function (equipment) {
        if (equipment) {
          self.carrierDriver1()?.name()
            ? undefined
            : self.carrierDriver1().name(equipment.name);
          self.carrierDriver1()?.phone()
            ? undefined
            : self.carrierDriver1().phone(equipment.phone);
          self.carrierDriver1()?.email()
            ? undefined
            : self.carrierDriver1().email(equipment.email);
          self.overrideDriverName()
            ? undefined
            : self.overrideDriverName(equipment.overrideDriverName);
          self.carrierTrailer()
            ? undefined
            : self.carrierTrailer(equipment.carrierTrailer);
          self.carrierTractor()
            ? undefined
            : self.carrierTractor(equipment.carrierTractor);
        }
      });
    };

    if (move.carrierDrivers.length == 0 && move.carrierDriverUserId > 0) {
      setTimeout(() => {
        self.loadCarrierDriverLastEquipment({
          userId: move.carrierDriverUserId,
        });
      }, 300);
    }
    self.tenderStatus = ko.observable();
    if (move.tenderStatus && move.tenderStatus.completed == false) {
      if (move.tenderStatus.accepted === true) {
        self.tenderStatus = ko.observable("Accepted");
      } else if (move.tenderStatus.accepted === false) {
        self.tenderStatus = ko.observable("Declined");
      } else if (new Date(move.tenderStatus.respondBy) > new Date()) {
        self.tenderStatus = ko.observable("Pending");
      } else {
        self.tenderStatus = ko.observable("Expired");
      }
    }

    // Billing
    self.billingDistance = self.$parent.params.billingDistance;
    self.weight = self.$parent.params.weight;
    self.minWeight = self.$parent.params.minWeight;
    self.pieces = self.$parent.params.pieces;
    self.freightCharge = self.$parent.params.freightCharge;
    self.otherCharges = self.$parent.params.otherCharges;

    self.revDist = ko.computed(function () {
      if (!self.$parent.params.totalCharge() || !self.movementDistance()) {
        return "";
      } else {
        return self.$parent.params.totalCharge() / self.movementDistance();
      }
    });

    self.customerTargetProfit = self.$parent.params.customerTargetProfit;

    self.overridePayMethodId = ko
      .observable(move.overridePayMethodId || 3)
      .extend({ required: true });

    self.overridePayeeId = ko.observable(move.overridePayeeExId);

    self.isOverrideUnitsReadOnly = ko.computed(function (value) {
      if (!self.overridePayMethodId()) {
        return true;
      } else if (self.overridePayMethodId().value != "5") {
        return true;
      } else {
        return false;
      }
    });

    self.overrideUnits = ko.computed(function () {
      if (self.overridePayMethodId()) {
        if (self.overridePayMethodId().value == 1) {
          //CWT
          if (
            (self.weight() > self.minWeight()
              ? self.weight()
              : self.minWeight()) > 0
          ) {
            return (
              (self.weight() > self.minWeight()
                ? self.weight()
                : self.minWeight()) / 100
            );
          } else {
            return 1;
          }
        } else if (self.overridePayMethodId().value == 2) {
          //Distance
          return self.movementDistance();
        } else if (self.overridePayMethodId().value == 3) {
          //Flat
          return 1;
        } else if (self.overridePayMethodId().value == 4) {
          //Tons
          if (
            (self.weight() > self.minWeight()
              ? self.weight()
              : self.minWeight()) > 0
          ) {
            return (
              (self.weight() > self.minWeight()
                ? self.weight()
                : self.minWeight()) / 2000
            );
          } else {
            return 1;
          }
        } else if (self.overridePayMethodId().value == 5) {
          //Other
          return self.overrideUnitsInput();
        }
      } else {
        return 0;
      }
    });

    self.overrideUnits.subscribe(function (value) {
      if (self.overridePayMethodId() && self.overridePayMethodId().value != 5) {
        self.overrideUnitsInput(value);
      }
    });

    self.overrideUnitsInput = ko.observable(move.overrideUnits);

    self.overridePayRate = ko.observable(move.overridePayRate).extend({
      required: { params: true, message: "Rate is required." },
      validation: {
        validator: function (val) {
          return val > 0;
        },
        message: "Rate must be greater than 0",
      },
    });

    self.overridePayAmount = ko.computed(function () {
      var charge = self.overridePayRate() * self.overrideUnits();
      return isNaN(charge) ? 0 : charge;
    });

    //SUM(CarrierExtraPay.Amount) for all movements
    self.otherPay = ko.computed(function () {
      return self.$parent.movements().reduce(function (total, move) {
        return (
          move.carrierExtraPayRecords().reduce(function (moveTotal, extraPay) {
            return moveTotal + extraPay.amount;
          }, 0) + total
        );
      }, 0);
    });

    //(SUM(Movements.OverridePayAmount) + SUM(CarrierExtraPay.Amount))  for all movements.
    self.totalPay = ko.computed(function () {
      return (
        self.$parent.movements().reduce(function (total, movement) {
          return total + movement.overridePayAmount();
        }, 0) + self.otherPay()
      );
    });

    self.profit = ko.computed(function () {
      return self.$parent.params.totalCharge() - self.totalPay();
    });

    self.profitPct = ko.computed(function () {
      return (self.profit() / self.$parent.params.totalCharge()) * 100;
    });

    self.payDist = ko.computed(function () {
      return self.totalPay() / self.movementDistance();
    });

    self.overrideMaxBuy = ko.observable(move.overrideMaxBuy);

    self.insertNewExtraPayRecord = function (extraPay, index) {
      var epr = extraPay.id
        ? extraPay
        : { movementId: self.movementId(), orderId: self.orderId() };
      epr.result = ko.observable();
      self.$parent.carrierExtraPayModalHeader(
        (extraPay.id ? "Edit " : "New ") + "Carrier Extra Pay"
      );
      self.$parent.carrierExtraPayModal(epr);

      epr.result.subscribe(function (newCarrierExtraPay) {
        if (newCarrierExtraPay) {
          newCarrierExtraPay.orderTracking = self.$parent
            .orderEntryTracking()
            .data.toJSON();

          Dispatch.insertOrUpdateCarrierExtraPay(newCarrierExtraPay)
            .then(function (serverValue) {
              if (extraPay.id) {
                self.carrierExtraPayRecords.replace(extraPay, serverValue);
              } else {
                self.carrierExtraPayRecords.push(serverValue);
              }
            })
            .catch(function (err) {
              self.$parent.showLoadingWheel(false);
            });
        }
      });
    };

    self.formatTransactionDate = function (value) {
      return dayjs(value).format("MM/DD/YYYY HH:mm");
    };

    self.deleteCarrierExtraPayRecord = function (obj) {
      if (
        self.$parent.$componentParent.orderLockComponent() &&
        self.$parent.$componentParent.orderLockComponent().lockOrderControls()
      ) {
        return false;
      }
      showconfirm("Delete this Extra Pay Move?").then(function (result) {
        if (result) {
          Dispatch.DeleteCarrierExtraPayRecord({
            id: obj.id,
            orderId: $parent.orderId,
            orderTracking: self.$parent.orderEntryTracking().data.toJSON(),
          })
            .then(function (success) {
              let index = self
                .carrierExtraPayRecords()
                .findIndex((x) => x.id == obj.id);
              self.carrierExtraPayRecords.splice(index, 1);
            })
            .catch(function (err) {
              self.$parent.showLoadingWheel(false);
            });
        }
      });
    };

    self.sendExtraPayRecordConfirmation = function (data) {
      var afterCloseCarrierContact = ko.observable();

      if (data.carrierId == self.carrier()) {
        self.$parent.rateConfirmationModal({
          movementId: self.movementId(),
          carrierId: data.carrierId,
          afterCloseCarrierContact: afterCloseCarrierContact,
          orderEntryTracking: self.$parent.orderEntryTracking().data,
        });
      } else {
        self.$parent.rateConfirmationModal({
          carrierExtraPayRecordId: data.id,
          orderEntryTracking: self.$parent.orderEntryTracking().data,
        });
      }

      afterCloseCarrierContact.subscribe(function (value) {
        self.carrierContactId(value.carrierContactId);
        self.carrierContactName(value.name);
        self.carrierContactPhone(value.phone);
        self.carrierContactFax(value.fax);
        self.carrierContactEmail(value.email);
      });
    };

    self.sendRateConfirmation = async () => {
      try {
        var afterCloseCarrierContact = ko.observable();
        afterCloseCarrierContact.subscribe((value) => {
          self.carrierContactId(value.carrierContactId);
          self.carrierContactName(value.name);
          self.carrierContactPhone(value.phone);
          self.carrierContactFax(value.fax);
          self.carrierContactEmail(value.email);

          self.saveMovement().then(async () => {
            if (TntContext.isTrackingOn()) {
              TntContext.saveTracking();

              const currentMove = TntContext.currentMoveTracking();
              if (
                TntContext.isTrackingRequired() &&
                currentMove.getValidationErrorObj(
                  currentMove.tracking,
                  TntContext.getState().isBrokered
                ).count === 0
              ) {
                await TntContext.startMovementTracking(
                  currentMove.movementExId
                );
              }
            }
          });
        });

        if ((self.$parent.$componentParent.readyToBill() ?? false) === false) {
          self.$parent.$componentParent.skipTrackingSaveCheck = true;

          self.$parent.$componentParent
            .initiateSaveOrderProcess()
            .then(() => {
              self.$parent.rateConfirmationModal({
                movementId: self.movementId(),
                afterCloseCarrierContact: afterCloseCarrierContact,
                orderEntryTracking: self.$parent.orderEntryTracking,
                carrierId: self.carrier(),
              });
            })
            .catch((err) => err && console.error(err));
        } else {
          self.$parent.rateConfirmationModal({
            movementId: self.movementId(),
            afterCloseCarrierContact: afterCloseCarrierContact,
            orderEntryTracking: self.$parent.orderEntryTracking,
            carrierId: self.carrier(),
          });
        }
      } catch (err) {
        console.error(err);
      }
    };

    self.stops = ko.observableArray([]);
    self.stops(
      move.stops.map(function (stop) {
        return new Stop(stop, self);
      })
    );
    self.carrierExtraPayRecords = ko.observableArray(
      move.carrierExtraPayRecords
    );

    self.movementStatus.subscribe((val) => {
      TntContext.updateTrackedMovementStatus(self.movementExId(), val);

      document.dispatchEvent(new CustomEvent('orderEntryMovementStatusChanged', {
        detail: { movementId: self.movementId(), movementStatus: val }
      }))
    });

    self.isCarrierReadOnly = ko.computed(function () {
      return (
        self.movementStatus() == "In Progress" ||
        self.movementStatus() == "Delivered"
      );
    });

    self.isCarrierDriverFieldsReadOnly = ko.computed(function () {
      return self.movementStatus() == "Delivered";
    });

    self.saveMovement = async function (skipLockCheck = false) {
      const err = await $parent.$componentParent.validateArApproval();
      if (err) {
        showmessage(err);
        this.dispatchAction(isLoading(false));
        return Promise.reject();
      }

      if (!self.overridePayRate() && self.carrier() != null) {
        showmessage("Rate is required");
        this.dispatchAction(isLoading(false));
        return Promise.reject();
      }

      return new Promise((resolve, reject) => {
        var data = {
          movementId: self.movementId(),
          carrierId: self.carrier(),
          carrierOrderNumber: self.carrierOrderNumber(),
          overrideDriverName: self.overrideDriverName(),
          carrierTractor: self.carrierTractor(),
          carrierTrailer: self.carrierTrailer(),
          overridePayeeId: self.overridePayeeId(),
          overridePayMethodId: self.overridePayMethodId()
            ? typeof self.overridePayMethodId() == "object"
              ? parseInt(self.overridePayMethodId().value)
              : self.overridePayMethodId()
            : null,
          overrideUnits: self.overrideUnits(),
          overridePayRate: self.overridePayRate(),
          brokerageStatusId: self.brokerageStatusId()
            ? typeof self.brokerageStatusId() == "object"
              ? self.brokerageStatusId().value
              : self.brokerageStatusId()
            : null,
          carrierContactId: self.carrierContactId(),
          orderTracking: self.$parent.orderEntryTracking().data,
          carrierDriverUserId: move.carrierDriverUserId,
          carrierDriverInfos: [],
          skipLockCheck,
        };

        // If has any entry (name, phone, email) then send in, otherwise it will be removed from the order/movement
        if (self.carrierDriver1() && self.carrierDriver1().hasEntries()) {
          data.carrierDriverInfos.push(self.carrierDriver1());
        }

        if (self.carrierDriver2() && self.carrierDriver2().hasEntries()) {
          data.carrierDriverInfos.push(self.carrierDriver2());
        }

        Dispatch.saveMovement(data)
          .then(function (value) {
            if (value.errors) {
              showmessage(value.errors);
              reject();
            } else {
              if (self.carrier() && self.movementStatus() == "Available") {
                self.movementStatus("Covered");
              }
              resolve({});
            }
          })
          .catch(function (err) {
            self.$parent.showLoadingWheel(false);

            if (err && err.responseJSON && err.responseJSON.message) {
              showmessage(err.responseJSON.message);
            }
          });
      });
    };

    self.isCancelDispatchVisible = ko.computed(function () {
      if (
        self.$parent.$componentParent.orderLockComponent() &&
        self.$parent.$componentParent.orderLockComponent().lockOrderControls()
      ) {
        return false;
      } else if (
        self.stops().filter(function (st) {
          return st.stopStatus() == "In Progress";
        }).length > 0
      ) {
        //Do we have an 'In Progress' stop?  No, we do not.
        return true;
      } else if (
        self.movementStatus() == "Available" ||
        self.movementStatus() == "Covered"
      ) {
        return false;
      } else if (self.readyToBill() == true) {
        return false;
      } else {
        //Get Next movement and make sure it's not in progress
        var nextMovement = self.$parent.movements()[self.index + 1];
        if (nextMovement && nextMovement.movementStatus() != "Available") {
          return false;
        } else {
          return true;
        }
      }
    });

    self.cancelDispatch = function () {
      var modalResult = ko.observable();
      self.$parent.orderEntryDispatchCancelDispatchModal({
        result: modalResult,
        brokered: true,
        isPartOfViewingOrder: true,
        cancelingOrderExId: $parent.orderExId(),
      });

      modalResult.subscribe(async (result) => {
        const executeDispatchCancel = async () => {
          var data = {
            cancelDispatchAndRemoveEquipment:
              result.cancelDispatchAndRemoveEquipment(),
            cancelEmptyMovement: result.cancelEmptyMovement()
              ? result.cancelEmptyMovement().value == "Yes"
              : false,
            cancelLatestStop: result.cancelLatestStop(),
            moveMovementToAvailableStatus:
              result.moveMovementToAvailableStatus(),
            movementId: self.movementId(),
            orderId: self.$parent.orderId,
            orderTracking: self.$parent.orderEntryTracking().data.toJSON(),
          };

          self.$parent.showLoadingWheel(true);
          Dispatch.cancelDispatch(data)
            .then(function (result) {
              self.$parent.showLoadingWheel(false);

              if (result.errors) {
                self.$parent.errorModal(result.errors);
              } else {
                /////////////// Cancel latest stop ///////////////
                if (
                  data.cancelLatestStop &&
                  data.moveMovementToAvailableStatus == false
                ) {
                  var latestStop = self
                    .stops()
                    .slice()
                    .reverse()
                    .find(function (value) {
                      return (
                        value.stopStatus() == "Delivered" ||
                        value.stopStatus() == "In Progress"
                      );
                    });
                  latestStop.timeFilledIn = false;
                  latestStop.actualArrival(null);
                  latestStop.actualDeparture(null);
                  latestStop.stopStatus("Available");

                  if (latestStop.sequence() == 1) {
                    //FirstStop
                    self.movementStatus(
                      (self.carrier() && "Covered") || "Available"
                    );
                    if (self.isFirstInSequence() == true) {
                      self.$parent.$componentParent
                        .summary()
                        .status("Available");
                    }
                  } else if (latestStop.sequence() == self.stops().length) {
                    //Last Stop
                    self.movementStatus("In Progress");

                    if (self.isLastInSequence()) {
                      self.$parent.$componentParent
                        .summary()
                        .status("In Progress");
                    }
                  }

                  //Need to clear any prefilled date out of the current stop,
                  var currentStop = self.stops().find(function (value) {
                    return value.sequence() == latestStop.sequence() + 1;
                  });
                  if (currentStop) {
                    currentStop.actualArrival(undefined);
                    currentStop.actualDeparture(undefined);
                  } else {
                    //If currentStop is null, need to look ahead to the next movement and see if there's a date to clear out, and then clear the error.
                    var nextMovement = self.$parent.movements()[self.index + 1];
                    if (nextMovement) {
                      nextMovement.stops()[0].actualArrival(null);
                      nextMovement.stops()[0].actualArrival.clearError();
                    }
                  }
                }

                /////////////////// Move Order to Available Status /////////////////////////
                else if (
                  data.moveMovementToAvailableStatus == true &&
                  data.cancelDispatchAndRemoveEquipment == false
                ) {
                  self.$parent.movements().forEach(function (movement) {
                    movement.stops().forEach(function (stop) {
                      stop.stopStatus("Available");
                      stop.actualArrival(null);
                      stop.actualDeparture(null);
                    });
                    movement.movementStatus("Covered");
                  });
                  self.$parent.$componentParent.summary().status("Available");
                }

                /////////////////// Cancel Dispatch And Remove Equipment /////////////////////////
                else if (data.cancelDispatchAndRemoveEquipment) {
                  for (let mi = 0; mi < self.$parent.movements().length; mi++) {
                    for (
                      let si = 0;
                      si < self.$parent.movements()[mi].stops().length;
                      si++
                    ) {
                      self.$parent
                        .movements()
                        [mi].stops()
                        [si].stopStatus("Available");
                      self.$parent
                        .movements()
                        [mi].stops()
                        [si].actualArrival(null);
                      self.$parent
                        .movements()
                        [mi].stops()
                        [si].actualArrival.clearError();
                      self.$parent
                        .movements()
                        [mi].stops()
                        [si].actualDeparture(null);
                      self.$parent
                        .movements()
                        [mi].stops()
                        [si].actualDeparture.clearError();
                    }

                    self.$parent.movements()[mi].carrier(null);
                    self.$parent.movements()[mi].movementStatus("Available");
                    self.$parent.movements()[mi].carrierDriver1(
                      new CarrierDriverInfo({
                        isPrimaryDriver: true,
                        movementId: self.$parent.movements()[mi].movementId,
                      })
                    );
                    self.$parent.movements()[mi].carrierDriver2(null);
                    self.$parent.movements()[mi].displayTeamDriver(false);
                    self.$parent.movements()[mi].carrierOrderNumber(null);
                    self.$parent.movements()[mi].carrierTractor(null);
                    self.$parent.movements()[mi].overrideDriverName(null);
                    self.$parent.movements()[mi].carrierTrailer(null);
                    self.$parent.movements()[mi].overridePayeeId(null);
                    self.$parent.movements()[mi].overridePayMethodId(3);
                    self.$parent.movements()[mi].overridePayRate(null);
                    self.$parent.movements()[mi].brokerageStatusId(undefined);
                  }
                  self.$parent.$componentParent.summary().status("Available");
                }
              }
            })
            .catch(function (err) {
              self.$parent.showLoadingWheel(false);
              if (err.status == 409 && err.responseJSON) {
                self.$parent
                  .orderEntryTracking()
                  .methods()
                  .displayConflictNotifyMessage(err.responseJSON);
              }
            });
        };

        if (
          TntContext.isMovementTracking(this.movementExId()) &&
          result.cancelDispatchAndRemoveEquipment()
        ) {
          const cancelTracking = await showconfirm(
            `Tracking is turned on for this movement. Cancelling dispatch will also cancel tracking. Do you want to continue?`
          );

          if (cancelTracking) {
            await TntContext.cancelMovementTracking(self.movementExId());
            await executeDispatchCancel();
          }
        } else {
          await executeDispatchCancel();
        }
      });
    };

    self.clearAssignments = async () => {
      if (TntContext.isMovementTracking(this.movementExId())) {
        const yes = await showconfirm(
          `Tracking is turned on for this movement. Clearing Assignment will cancel tracking. Do you want to continue?`
        );
        if (yes === false) {
          return;
        }

        await TntContext.cancelMovementTracking(self.movementExId());
      }

      self.movementStatus("Available");
      self.carrier(undefined);
      self.carrierDriver1(
        new CarrierDriverInfo({
          isPrimaryDriver: true,
          movementId: self.movementId,
        })
      );

      self.carrierDriver2(undefined);
      self.displayTeamDriver(false);

      self.overrideDriverName(undefined);
      self.brokerageStatusId(undefined);
      self.carrierOrderNumber(undefined);
      self.overridePayeeId("");
      self.carrierTractor(undefined);

      self.carrierTrailer(undefined);
      self.overridePayRate(undefined);
      self.stops()[0].actualArrival(null);
      self.stops()[0].actualArrival.clearError();
      self.stops()[0].actualDeparture(null);
      self.stops()[0].actualDeparture.clearError();
      self.overridePayMethodId(undefined);
      self.saveMovement().then(function () {
        self.overridePayMethodId(3);
      });
    };

    self.disableSendRateCon = ko.pureComputed(() => {
      if (
        self.$parent.$componentParent.summary().isSaveButtonDisabled &&
        self.$parent.$componentParent.summary().isSaveButtonDisabled()
      ) {
        return true;
      }

      if (
        self.$parent.$componentParent.orderLockComponent() &&
        self.$parent.$componentParent.orderLockComponent().lockOrderControls()
      ) {
        return true;
      }

      if (!self.carrier()) {
        return true;
      }

      if (self.overridePayRate() == null) {
        return true;
      }

      return false;
    });

    self.isClearAssignmentsDisabled = ko.computed(function (value) {
      if (
        self.movementStatus() != "Available" &&
        self.movementStatus() != "Covered"
      ) {
        return true;
      }

      if (
        !self.carrier() &&
        !self.carrierTractor() &&
        !self.carrierDriver1() &&
        !self.carrierDriver2()
      ) {
        return true;
      }

      if (
        self.$parent.$componentParent.summary().isSaveButtonDisabled &&
        self.$parent.$componentParent.summary().isSaveButtonDisabled()
      ) {
        return true;
      }

      if (
        self.$parent.$componentParent.orderLockComponent() &&
        self.$parent.$componentParent.orderLockComponent().lockOrderControls()
      ) {
        return true;
      }

      return false;
    });

    self.geoFenceRadius = ko.pureComputed({
      read: () => this.$trackedMove().movementGeoFenceRadius ?? 2,
      write: (val) => {
        TntContext.upsertTrackedMovement(self.movementExId(), {
          movementGeoFenceRadius: val ?? 2,
        });
      },
    });

    self.carrierContactEmail.subscribe((val) => {
      TntContext.upsertTrackedMovement(self.movementExId(), {
        tracking: {
          carrierAssignment: {
            carrierContactEmail: val,
          },
        },
      });
    });

    self.carrierTractor.subscribe((val) => {
      TntContext.upsertTrackedMovement(self.movementExId(), {
        tracking: {
          carrierAssignment: {
            carrierTractor: val,
          },
        },
      });
    });

    self.carrierTrailer.subscribe((val) => {
      TntContext.upsertTrackedMovement(self.movementExId(), {
        tracking: {
          carrierAssignment: {
            carrierTrailer: val,
          },
        },
      });
    });

    self.carrierContactPhone.subscribe((val) => {
      TntContext.upsertTrackedMovement(self.movementExId(), {
        tracking: {
          carrierAssignment: {
            carrierPhone: val,
          },
        },
      });
    });

    self.carrierDriver1()?.name.subscribe((val) => {
      TntContext.upsertTrackedMovement(self.movementExId(), {
        tracking: {
          driver1Name: val,
        },
      });
    });

    self.carrierDriver1()?.phone.subscribe((val) => {
      TntContext.upsertTrackedMovement(self.movementExId(), {
        tracking: {
          driver1Phone: val,
        },
      });
    });

    self.displayTeamDriver.subscribe((val) => {
      TntContext.upsertTrackedMovement(self.movementExId(), {
        tracking: {
          teamTracking: val,
        },
      });
    });

    setTimeout(() => {
      self.carrier(move.carrierId);
      self.vmReady(true);
    }, 100);
  } // end of constructor

  handleGetMarketRates = async () => {
    const orderData = ko.toJS(this.$parent.$componentParent);

    if (orderData.general && !orderData.general.trailerType) {
      showmessage("Trailer type is required.");
      return;
    }

    await this.$parent.$componentParent.initiateSaveOrderProcess();

    this.dispatchAction(isLoading(true));
    const result = await RateService.getSavedRatesForOrder(this.orderId());
    this.dispatchAction(isLoading(false));

    this.tpiViewRatesProps(result);
  };

  insertNewExtraPayRecordDisabled = ko.pureComputed(() => {
    if (
      this.$parent.$componentParent.orderLockComponent() &&
      this.$parent.$componentParent.orderLockComponent().lockOrderControls()
    ) {
      return true;
    } else {
      return this.readyToBill();
    }
  });

  sendExtraPayRecordConfirmationDisabled = ko.pureComputed(() => {
    return (
      this.$parent.$componentParent.orderLockComponent() &&
      this.$parent.$componentParent.orderLockComponent().lockOrderControls()
    );
  });

  handleStartTracking = async () => {
    try {
      await this.$parent.$componentParent.initiateSaveOrderProcess();
      await TntContext.startMovementTracking(this.movementExId());
    } catch (err) {
      console.error(err);
    }
  };

  getBrokerageStatusDDT = async () => {
    return await this.brokerageStatusDDTCache.fetch("brokerage_status_ddt", async() => {
      return new Promise(async(resolve, reject) => {
        dataModel.ajaxRequest("Lookup/BrokerageStatusDDT", "GET", null, true).done(resolve)
            .fail((err) => reject(`Unable to get brokerage status DDT`));
      })
    }, 1000 * 60 * 60 * 24);
  }

}

class Stop {
  /** @param { Movement } movement */
  constructor(stop, movement) {
    var self = this;

    const dispatchAction = useDispatch();

    self.movement = movement;
    self.id = ko.observable(stop.id);

    self.earliestArrival = ko.observable(stop.earliestArrival);
    self.latestArrival = ko.observable(stop.latestArrival);

    self.actualArrival = ko.observable(stop.actualArrival).extend({
      required: {
        params: true,
        message: "Actual Arrival is required.",
        onlyIf: function () {
          var previousStop = movement.stops().find(function (value) {
            return value.sequence() == self.sequence() - 1;
          });
          if (previousStop && previousStop.stopStatus() != "Delivered") {
            return false;
          } else {
            return true;
          }
        },
      },
    });

    self.actualDeparture = ko.observable(stop.actualDeparture);

    self.location = ko.observable(stop.location);
    self.sequence = ko.observable(stop.sequence);
    self.stopStatus = ko.observable(stop.stopStatus);
    if (
      self.stopStatus() == "Available" &&
      self.actualArrival() &&
      !self.actualDeparture()
    ) {
      self.stopStatus("In Progress"); //This is only for front end purpose
    }
    self.stopType = ko.observable(stop.stopType);

    self.blockCompleteStop = ko.computed(function () {
      if (
        !self.movement.carrier() ||
        !self.movement.carrierSelectedValue() ||
        (self.movement.carrierSelectedValue() &&
          self.movement.carrierSelectedValue().isActive == false)
      ) {
        return true;
      } else if (
        self.movement.$parent.$componentParent.orderLockComponent() &&
        self.movement.$parent.$componentParent
          .orderLockComponent()
          .lockOrderControls()
      ) {
        return true;
      } else if (self.movement.showCarrierInactiveWarning()) {
        return true;
      } else if (self.stopStatus() == "Delivered") {
        return true;
      } else if (self.movement.showCarrierIntrastateBlockMessage()) {
        return true;
      } else {
        if (self.movement.sequence() == 1) {
          if (self.sequence() == 1) {
            // empty block ?
          } else {
            let previousStop = self.movement.stops().filter(function (stop) {
              return stop.sequence() == self.sequence() - 1;
            })[0];
            if (previousStop && previousStop.stopStatus() != "Delivered") {
              return true;
            }
          }
        } else {
          //Not the first movement   Get previous movement then check to see if it's delivered.
          if (self.sequence() == 1) {
            let previousMovement = movement.$parent
              .movements()
              .filter(function (move) {
                return move.sequence() == self.movement.sequence() - 1;
              })[0];
            if (
              previousMovement &&
              previousMovement.movementStatus() != "Delivered"
            ) {
              return true;
            }
          } else {
            //Get previous stop and see if it's delivered.
            let previousStop = self.movement.stops().filter(function (stop) {
              return stop.sequence() == self.sequence() - 1;
            })[0];
            if (previousStop && previousStop.stopStatus() != "Delivered") {
              return true;
            }
          }
        }
      }

      if (!self.actualArrival() && self.timeFilledIn == false) {
        self.timeFilledIn = true;
        self.actualArrival(datetimeUTC(new Date().setSeconds(0, 0)));
        return false;
      } else if (!self.actualDeparture() && self.timeFilledIn == false) {
        self.timeFilledIn = true;
        self.actualDeparture(datetimeUTC(new Date().setSeconds(0, 0)));
        return false;
      } else {
        self.timeFilledIn = true;
        return false;
      }
    });

    self.timeFilledIn = false;

    self.isActualArrivalReadOnly = ko.computed(function () {
      if (self.stopStatus() == "In Progress") {
        return true;
      } else {
        return self.blockCompleteStop();
      }
    });

    self.completeButtonText = ko.computed(function () {
      if (self.stopStatus() == "Delivered") {
        return "Completed";
      } else {
        return "Complete";
      }
    });

    self.completeStop = function () {
      dispatchAction(isLoading(true)); // using page level loader as broker component has component leve loader (doesn't block summary btns)

      if (movement.$parent.orderEntryErrors()().length > 0) {
        movement.$parent.errorModal(movement.$parent.orderEntryErrors()());
        dispatchAction(isLoading(false));
        return;
      }

      var dispatchErrors = ko.validation.group({
        carrier: movement.carrier,
        actualArrival: self.actualArrival,
        rate: movement.overridePayRate,
      });
      if (dispatchErrors().length > 0) {
        movement.$parent.errorModal(dispatchErrors);
        dispatchAction(isLoading(false));
        return;
      }

      var stopRequest = {
        ActualArrival: self.actualArrival(),
        ActualDeparture: self.actualDeparture(),
        StopId: self.id(),
        carrierDriverUserId: self.movement.carrierDriverUserId,
        orderTracking: self.movement.$parent.orderEntryTracking().data.toJSON(),
      };

      var previousStop = self.movement.stops()[self.sequence() - 2];
      var previousMovement =
        self.movement.$parent.movements()[self.movement.sequence() - 2];

      if (
        previousMovement &&
        previousMovement
          .stops()
          [previousMovement.stops().length - 1].actualDeparture() >
          self.actualArrival()
      ) {
        let d = dayjs(
          previousMovement
            .stops()
            [previousMovement.stops().length - 1].actualDeparture()
        );

        movement.$parent.errorModal([
          "Actual Arrival cannot come before Actual Departure of last stop of previous movement (" +
            d.format("MM/DD/YYYY HH:mm") +
            ")",
        ]);

        dispatchAction(isLoading(false));
      } else if (
        previousStop &&
        previousStop.actualDeparture() > self.actualArrival()
      ) {
        var d = dayjs(previousStop.actualDeparture());

        movement.$parent.errorModal([
          "Actual Arrival cannot come before Actual Departure of previous stop (" +
            d.format("MM/DD/YYYY HH:mm") +
            ")",
        ]);

        dispatchAction(isLoading(false));
      } else if (
        self.actualDeparture() &&
        self.actualArrival() > self.actualDeparture()
      ) {
        let d = dayjs(self.actualArrival());
        movement.$parent.errorModal([
          "Actual Departure cannot come before Actual Arrival (" +
            d.format("MM/DD/YYYY HH:mm") +
            ")",
        ]);
        dispatchAction(isLoading(false));
      } else if (self.sequence() == 1) {
        //First Stop
        self.movement.saveMovement().then(function (sm) {
          if (sm.error) {
            movement.$parent.errorModal([sm.error]);
            dispatchAction(isLoading(false));
          } else {
            stopRequest.orderTracking = self.movement.$parent
              .orderEntryTracking()
              .data.toJSON(); // need update payload to send
            Dispatch.completeStop(stopRequest)
              .then(function (ss) {
                dispatchAction(isLoading(false));

                if (ss.errors) {
                  movement.$parent.errorModal([ss.errors]);
                } else if (self.actualArrival() && !self.actualDeparture()) {
                  self.timeFilledIn = false; //This will cause the computed to fill in an actual departure.
                  self.stopStatus("In Progress");
                } else {
                  movement.$parent.$componentParent
                    .summary()
                    .status("In Progress");
                  movement.movementStatus("In Progress");
                  self.stopStatus("Delivered");
                }
              })
              .catch(function (err) {
                dispatchAction(isLoading(false));
              });
          }
        });
      } else if (self.sequence() == movement.stops().length) {
        //Last Stop
        self.movement.saveMovement().then(function (value) {
          if (value.error) {
            movement.$parent.errorModal([value.error]);
            dispatchAction(isLoading(false));
          } else {
            stopRequest.orderTracking = self.movement.$parent
              .orderEntryTracking()
              .data.toJSON(); // need update payload to send
            Dispatch.completeStop(stopRequest)
              .then(function (cs) {
                dispatchAction(isLoading(false));

                if (cs.errors) {
                  movement.$parent.errorModal([cs.errors]);
                } else if (self.actualArrival() && !self.actualDeparture()) {
                  self.stopStatus("In Progress");
                  self.actualDeparture(
                    datetimeUTC(new Date().setSeconds(0, 0))
                  );
                } else {
                  movement.movementStatus("Delivered");
                  self.stopStatus("Delivered");
                  if (
                    movement.sequence() == movement.$parent.movements().length
                  ) {
                    movement.$parent.$componentParent
                      .summary()
                      .status("Delivered");
                  }
                }
              })
              .catch(function (err) {
                dispatchAction(isLoading(false));
              });
          }
        });
      } else {
        //In-between stops
        self.movement.saveMovement().then(function (value) {
          if (value.error) {
            movement.$parent.errorModal([value.error]);
            dispatchAction(isLoading(false));
          } else {
            stopRequest.orderTracking = self.movement.$parent
              .orderEntryTracking()
              .data.toJSON(); // need update payload to send

            Dispatch.completeStop(stopRequest)
              .then(function (result) {
                dispatchAction(isLoading(false));

                if (result.error) {
                  movement.$parent.errorModal([value.error]);
                } else if (self.actualArrival() && !self.actualDeparture()) {
                  self.stopStatus("In Progress");
                  self.actualDeparture(
                    datetimeUTC(new Date().setSeconds(0, 0))
                  );
                } else {
                  self.stopStatus("Delivered");
                }
              })
              .catch(function (err) {
                dispatchAction(isLoading(false));
              });
          }
        });
      }
    };
  }
}

class DispatchService {
  /////////////////////////////////////// Service Methods //////////////////////////////////////////
  #orderEntryTracking;
  constructor(orderEntryTracking) {
    this.#orderEntryTracking = orderEntryTracking;
  }
  getMovements = (orderId) => {
    return new Promise((resolve, reject) => {
      dataModel
        .ajaxRequest("Dispatch/GetBrokerageMovements", "GET", {
          orderId: orderId,
        })
        .done((value) => {
          resolve(value);
        });
    });
  };

  getCarrierDriverLastEquipment = ({ userId, email, phone }) => {
    return new Promise((resolve, reject) => {
      dataModel
        .ajaxRequest("Dispatch/GetCarrierDriverLastEquipment", "GET", {
          userId: userId,
          email: email,
          phone: phone,
        })
        .done((value) => {
          resolve(value);
        });
    });
  };

  getCarrierUserDriversForDDL = (phoneNumber) => {
    return new Promise((resolve, reject) => {
      dataModel
        .ajaxRequest("Driver/GetCarrierUserDriversForDDL", "GET", {
          searchText: phoneNumber,
          numOfItems: 1,
        })
        .done((value) => {
          resolve(value);
        });
    });
  };

  insertOrUpdateCarrierExtraPay = (extra) => {
    return new Promise((resolve, reject) => {
      dataModel
        .ajaxRequest("Dispatch/InsertOrUpdateCarrierExtraPay", "PUT", extra)
        .done((value) => {
          this.#orderEntryTracking()
            .methods()
            .saveUpdateTrackingAndRefreshTimestamp();
          resolve(value);
        })
        .fail((err) => {
          if (err.status == 409 && err.responseJSON) {
            this.#orderEntryTracking()
              .methods()
              .displayConflictNotifyMessage(err.responseJSON);
          } else if (err.status == 412) {
            // Order Locked
            showmessage(err.responseJSON.message);
          }
          return reject(err);
        });
    });
  };

  DeleteCarrierExtraPayRecord = (obj) => {
    return new Promise((resolve, reject) => {
      dataModel
        .ajaxRequest("Dispatch/DeleteCarrierExtraPayRecord", "DELETE", obj)
        .done((value) => {
          this.#orderEntryTracking()
            .methods()
            .saveUpdateTrackingAndRefreshTimestamp();
          resolve(value);
        })
        .fail((err) => {
          if (err.status == 409 && err.responseJSON) {
            this.#orderEntryTracking()
              .methods()
              .displayConflictNotifyMessage(err.responseJSON);
          } else if (err.status == 412) {
            // Order Locked
            showmessage(err.responseJSON.message);
          }
          return reject(err);
        });
    });
  };

  getCarrierIntrastateAuthority = (data) => {
    return new Promise((resolve, reject) => {
      dataModel
        .ajaxRequest("Dispatch/GetCarrierIntrastateAuthority", "GET", data)
        .done((value) => {
          resolve(value);
        });
    });
  };

  getCarrierRelationshipManager = (data) => {
    return new Promise((resolve, reject) => {
      dataModel
        .ajaxRequest("Dispatch/GetCarrierRelationshipManager", "GET", data)
        .done((value) => {
          resolve(value);
        });
    });
  };

  saveMovement = (data) => {
    return new Promise((resolve, reject) => {
      dataModel
        .ajaxRequest("Dispatch/SaveBrokerageDispatch", "POST", data)
        .done((value) => {
          this.#orderEntryTracking()
            .methods()
            .saveUpdateTrackingAndRefreshTimestamp();
          resolve(value);
        })
        .fail((err) => {
          if (err.status == 409 && err.responseJSON) {
            this.#orderEntryTracking()
              .methods()
              .displayConflictNotifyMessage(err.responseJSON);
          } else if (err.status == 412) {
            // Order Locked
            showmessage(err.responseJSON.message);
          }
          return reject(err);
        });
    });
  };

  completeStop = (stop) => {
    return new Promise((resolve, reject) => {
      dataModel
        .ajaxRequest("Dispatch/CompleteBrokerageStop", "POST", stop)
        .done((value) => {
          this.#orderEntryTracking()
            .methods()
            .saveUpdateTrackingAndRefreshTimestamp();
          resolve(value);
        })
        .fail((err) => {
          if (err.status == 409 && err.responseJSON) {
            this.#orderEntryTracking()
              .methods()
              .displayConflictNotifyMessage(err.responseJSON);
          } else {
            showmessage(err.responseJSON.message);
          }
          return reject(err);
        });
    });
  };

  cancelDispatch = (cdObj) => {
    return new Promise((resolve, reject) => {
      dataModel
        .ajaxRequest("Dispatch/CancelDispatch", "POST", cdObj)
        .done((value) => {
          this.#orderEntryTracking()
            .methods()
            .saveUpdateTrackingAndRefreshTimestamp();
          resolve(value);
        })
        .fail((value) => {
          if (value.status == 409 && value.responseJSON) {
            this.#orderEntryTracking()
              .methods()
              .displayConflictNotifyMessage(value.responseJSON);
            return reject(value);
          } else {
            resolve({
              errors: [
                value.responseJSON.message
                  ? value.responseJSON.message
                  : "An Error has occurred",
              ],
            });
          }
        });
    });
  };
}

class CarrierDriverInfo {
  constructor(x) {
    x = x || {};

    this.id = ko.observable(x.id);
    this.name = ko.observable(x.name);
    this.email = ko.observable(x.email);
    this._phone = ko.observable(x.phone);
    this.movementId = ko.observable(x.movementId);
    this.isPrimaryDriver = ko.observable(x.isPrimaryDriver);

    this.phone = ko.pureComputed({
      read: () => {
        return this._phone();
      },
      write: (val) => {
        if (val)
          val = val
            .replace(" ", "")
            .replace("(", "")
            .replace(")", "")
            .replace("-", "");
        this._phone(val);
      },
    });
  }

  // Checks if model has at least 1 input entry
  hasEntries = () => {
    return this.name() || this.email() || this.phone();
  };
}

export default {
  viewModel: OrderEntryDispatchBrokerageViewModel,
  template: template,
};
export { OrderEntryDispatchBrokerageViewModel };
