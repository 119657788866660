import ko from "knockout";
import dataModel from "data-model";
import GridStateComponentViewModel from "../../shared-components/jqx.grid-state-component/jqx.grid-state-component";
import gridStateUtils from "jqx.grid-utils";
import { getQueryVariable, createDateTimeFilterWidget } from "../../shared-components/global-functions";
import userProfile from "user-profile";
import {makePagingExpandable, lockPageOnGridScroll, buildGrid } from '../../../gridtoolbox';
import { loadSavedSearches } from "../../shared-components/SearchFilter-Saves-Component/SearchFilter-Saves-Component";
class TractorsPageViewModel {
    constructor() {
        var self = this;
        self.isLoading = ko.observable(false);
        self.tractorDetailsForModal = ko.observable();
        self.columns = [];
        self.datafields = "";
        self.lastUpdated = Date.now();
        self.updateGrid = false;
        self.isInitLoad = true;
        self.includeInactive = ko.observable(false);
        self.tractorsGrid = $("#tractorsGrid");
        self.tractorId = getQueryVariable("tractorId");
        self.roadsideDetailsModal = ko.observable();

        self.includeInactive.subscribe(function (newVal) {
            self.refreshGrid();
        });

        self.refreshGrid = function () {
            $("#tractorsGrid").jqxGrid("updatebounddata", "data");
        };

        self.isTractorGridLoading = ko.observable(false);

        self.loadGridColumnsandDatafields = function () {
            return new Promise(function (resolve, reject) {
                dataModel
                    .ajaxRequest("tractor/GetGridColumns", "GET")
                    .done(function (data, textStatus, jqXHR) {
                        self.columns = data.columns;
                        self.datafields = data.datafields;
                        resolve();
                    })
                    .fail(function (jqXHR, textStatus, errorThrown) {
                        self.isLoading(false);
                        reject();
                    });
            });
        };

        self.clearSelectedSavedSearchDDL = ko.observable(false);
        self.refreshSavedSearchDDL = ko.observable("");
        self.handleOnSelected = (state) => {
            self.tractorGridState = state.grid;
            gridStateUtils.applyGridState("tractorsGrid", state.grid);
            //Load source object last.  Solves a thousand issues.
            self.tractorsGrid.jqxGrid({ source: self.tractorGridSource() });
        }

        self.tractorGridState = null;

        
        self.loadTractorsGrid = function (columns, datafields) {
            self.columns.unshift(self.tractorDetailsButtonObject());

            for (var i = 0; i < columns.length; i++) {
                if (columns[i].datafield == "tractorId") {
                    columns[i].cellsrenderer = function (row, columnfield, value, defaultHTML, column, rowData) {
                        if (defaultHTML != null) {
                            var cell = $(defaultHTML);
                            if (rowData.eobrExternalId != null && rowData.eobrExternalId != "" && rowData.eobrExternalId != "0") {
                                cell.css("background-color", "lime");
                                return cell[0].outerHTML;
                            }
                        }
                        return defaultHTML;
                    };
                } else if (columns[i].datafield == "csaTrend") {
                    columns[i].cellsrenderer = function (row, columnfield, value, defaultHTML, column, rowData) {
                        if (defaultHTML != null) {
                            var cell = $(defaultHTML);
                            if (value == "E") {
                                cell.html("<img src='../Content/Images/doubleblue.png' />");
                            } else if (value == "U") {
                                cell.html("<img src='../Content/Images/redarrowup.png' />");
                            } else if (value == "D") {
                                cell.html("<img src='../Content/Images/greendown.png' />");
                            }
                            return cell[0].outerHTML;
                        }
                        return defaultHTML;
                    };
                } else if (columns[i].datafield == "carbCompliant") {
                    columns[i].width = "160px";
                    columns[i].filteritems = ["Yes", "No"];
                    columns[i].createfilterwidget = function (column, columnElement, widget) {
                        widget.jqxDropDownList({
                            dropDownWidth: "150px",
                            enableBrowserBoundsDetection: true,
                        });
                    };
                } else if (columns[i].datafield == "rating") {
                    columns[i].width = "140px";
                    columns[i].cellsrenderer = function (row, columnfield, value, defaultHTML, column, rowData) {
                        //refer to carriersnew.js for notation on rating function
                        if (defaultHTML != null) {
                            var pk = self.tractorsGrid.jqxGrid("getcellvalue", row, "id");
                            var fieldset = $("<fieldset>");
                            fieldset.addClass("star-rating");
                            fieldset.attr("id", "jqxRating_" + pk);

                            for (var i = 5; i > 0; i--) {
                                var id = "jqxRating_" + pk + i;
                                var input = $("<input>");
                                input.attr({
                                    type: "radio",
                                    id: "star" + i,
                                    name: "rating",
                                    value: i,
                                });
                                input.appendTo(fieldset);
                                var label = $("<label>").text(i);
                                if (value == i) {
                                    label.attr({ for: "star" + i });
                                }
                                label.insertAfter(input);
                                $(document)
                                    .off("click", "#jqxRating_" + pk)
                                    .on("click", "#jqxRating_" + pk, function (event) {
                                        var newRating;
                                        var previousRating;
                                        if (event.target.tagName == "LABEL") {
                                            newRating = event.target.previousSibling.value;
                                            $(event.target.parentElement.children).each(function (i) {
                                                if (this.tagName == "LABEL") {
                                                    if (this.attributes.length > 0) {
                                                        if (this.attributes.for.value != null) {
                                                            previousRating = this.attributes.for.value.slice(-1);
                                                        }
                                                        if (previousRating != newRating) {
                                                            $(this).removeAttr("for");
                                                        }
                                                    }
                                                }
                                            });
                                            if (previousRating != newRating) {
                                                var inputId = event.target.previousSibling.attributes.getNamedItem("id").value;
                                                $(event.target).attr("for", inputId);
                                                var id = event.target.parentElement.id.replace("jqxRating_", "");
                                                var params = {
                                                    objectId: id,
                                                    value: newRating,
                                                    agencyId: userProfile.currentAgencyId(),
                                                    favTable: 1,
                                                };
                                                dataModel
                                                    .ajaxRequest("Rating", "post", params)
                                                    .done(function (data, textStatus, jqXHR) {
                                                        $("#divTractorPanel").notify("Rating Updated", {
                                                            position: "top right",
                                                            className: "info",
                                                        });
                                                        self.tractorsGrid.jqxGrid("clearselection");
                                                        self.refreshGrid();
                                                    })
                                                    .fail(function (error, msg, d) {
                                                        self.isLoading(false);
                                                    });
                                            }
                                        }
                                    });
                            }

                            return fieldset[0].outerHTML;
                        }
                        return defaultHTML;
                    };
                    columns[i].filteritems = [
                        { value: 5, label: "5", html: "<img src='../Content/Images/5star.png' />" },
                        { value: 4, label: "4", html: "<img src='../Content/Images/4star.png' />" },
                        { value: 3, label: "3", html: "<img src='../Content/Images/3star.png' />" },
                        { value: 2, label: "2", html: "<img src='../Content/Images/2star.png' />" },
                        { value: 1, label: "1", html: "<img src='../Content/Images/1star.png' />" },
                    ];
                    columns[i].createfilterwidget = function (column, columnElement, widget) {
                        widget.jqxDropDownList({
                            dropDownWidth: 140,
                            enableBrowserBoundsDetection: true,
                            renderer: function (index, label, value) {
                                if (value != "") {
                                    return "<img src='../Content/Images/" + value + "star.png' />";
                                } else {
                                    return "Any";
                                }
                            },
                        });
                    };
                }
            }

            const {pagesize} = self.tractorGridState || {};

            const gridWithPaging = makePagingExpandable(self.tractorsGrid, {pagesize: pagesize || 20});
            gridWithPaging({
                theme: "GWTMDark",
                width: "100%",
                // source: ,  //Setting the source downstream makes loading initial filters way easlier.
                altrows: true,
                height: screen.height >= 800 ? (window.innerHeight - 300) : 400,
                sortable: true,
                showstatusbar: true,
                pageable: true,
                filterable: true,
                showfilterrow: true,
                columnsresize: true,
                columnsreorder: true,
                enablebrowserselection: true,
                columnsmenu: false,
                columns: columns,
                statusbarheight: 35,
                showtoolbar: true,
                renderstatusbar: function (statusbar) {},
                rendertoolbar: function (toolbar) {
                    const $grid = $("#tractorsGrid");
                    var vm1 = new GridStateComponentViewModel.viewModel(),
                        template = GridStateComponentViewModel.template;

                    vm1.clearFilters = function () {
                        self.isInitLoad = true;
                        $("#tractorsGrid").jqxGrid("clearfilters");
                        self.initFilters();
                        self.clearSelectedSavedSearchDDL(true)
                    };
                   

                    vm1.setDefaultSearchOverride = async () => {
                        const savedSearches = await loadSavedSearches($grid.attr("id"));
                        const filters = {
                            includeInactive: self.includeInactive(),
                            isDefault: true
                        }
        
                        vm1.loadSaveSearchModal(
                            $grid,
                            (val) => {
                                if(val && val.searchname) {
                                    self.refreshSavedSearchDDL(val.searchname)
                                }
                            },
                            savedSearches.filter(x => x.searchName).map(x => ({id: x.id, text: x.searchName})),
                            filters,
                            true
                        );
                    }

                    vm1.actions.push("Save Search");

                    vm1.loadSaveSearchModalOverride = async () => {
                        const savedSearches = await loadSavedSearches($grid.attr("id"));
                        const filters = {
                            includeInactive: self.includeInactive(),
                            isDefault: false
                        }
        
                        vm1.loadSaveSearchModal(
                            $grid,
                            (val) => {
                                if(val && val.searchname) {
                                    self.refreshSavedSearchDDL(val.searchname)
                                }
                            },
                            savedSearches.filter(x => x.searchName).map(x => ({id: x.id, text: x.searchName})),
                            filters,
                            true
                        );
                    }

                    vm1.refresh = function () {
                        self.updateGrid = true;
                        self.tractorsGrid.jqxGrid("updatebounddata", "data"); 
                    };

                    var tractorGridToolbar = $("#tractorGridToolbar");
                    toolbar.append(tractorGridToolbar);

                    var tdGridAction = tractorGridToolbar.find("#tdGridAction");
                    tdGridAction.append(template);

                    ko.applyBindingsToDescendants(vm1, tdGridAction[0]);
                },

                virtualmode: true,
                rendergridrows: function (obj) {
                    return obj.data;
                },

                ready: function (data) {
                    self.initFilters();
                },
            }, [buildGrid, lockPageOnGridScroll])
        }; //self.loadTractorsGrid

        self.createCSARoadsidePointHistoryGrid = function (data) {
            if (data.csaRoadsidePointHistory != null) {
                var source = {
                    localdata: data.csaRoadsidePointHistory,
                    datatype: "array",
                    datafields: [
                        { name: "rankingDate", type: "date" },
                        { name: "vehicleMaintenancePoints", type: "int" },
                        { name: "roadSides", type: "int" },
                    ],
                };
                var dataAdapter = new $.jqx.dataAdapter(source);
                var centerrenderer = function (value) {
                    return '<div style="text-align: center;margin-top: 12px;">' + value + "</div>";
                };

                $("#gridRoadsidePointHistory").jqxGrid({
                    theme: "GWTMDark",
                    width: "100%",
                    source: dataAdapter,
                    autoheight: true,
                    altrows: true,
                    columnsresize: true,
                    columns: [
                        {
                            text: "Month / Year",
                            datafield: "rankingDate",
                            cellsformat: "MM/yy",
                            cellsalign: "center",
                            renderer: centerrenderer,
                        },
                        {
                            text: "Vehicle Maintenance",
                            datafield: "vehicleMaintenancePoints",
                            cellsalign: "center",
                            renderer: centerrenderer,
                        },
                        { text: "Road sides", datafield: "roadSides", cellsalign: "center", renderer: centerrenderer },
                    ],
                });
            }
        };

        self.tractorDetailsButtonObject = function () {
            return {
                text: "",
                filterable: false,
                sortable: false,
                menu: false,
                width: 100,
                columnType: "button",
                pinned: true,
                buttonclick: function (row) {
                    self.isLoading(true);
                    var datarow = self.tractorsGrid.jqxGrid("getrowdata", row);
                    dataModel
                        .ajaxRequest("tractor/" + datarow.id)
                        .done(function (data, status, xhr) {
                            data.isAutoPosting = ko.observable(false);
                            data.isFreightBoard = ko.observable(false);
                            data.isPostToLoadBoard = ko.observable(false);

                            var noteModel = function (item) {
                                var self = this;
                                self.id = ko.observable(item.id);
                                self.comment = ko.observable(item.comment).extend({ required: true });

                                self.acceptedItem = function (item) {
                                    var noteData = ko.toJS(item);
                                    if (noteData.id != null) {
                                        dataModel
                                            .ajaxRequest("Tractor/EditNote", "post", noteData)
                                            .done(function (data, textStatus, jqXHR) {})
                                            .fail(function (jqXHR, textStatus, errorThrown) {});
                                    } else {
                                        noteData.tractorId = data.id;
                                        dataModel
                                            .ajaxRequest("Tractor/AddNote", "post", noteData)
                                            .done(function (data, textStatus, jqXHR) {})
                                            .fail(function (jqXHR, textStatus, errorThrown) {});
                                    }
                                };
                                self.deletedItem = function (item) {
                                    var id = item.id();
                                    dataModel
                                        .ajaxRequest("Tractor/DeleteNote/" + id, "post")
                                        .done(function (data, textStatus, jqXHR) {})
                                        .fail(function (jqXHR, textStatus, errorThrown) {});
                                };
                            };

                            data.notes = ko
                                .observableArray(
                                    ko.utils.arrayMap(data.notes, function (item) {
                                        return new noteModel(item);
                                    })
                                )
                                .extend({
                                    liveEditor: noteModel,
                                });

                            data.noteTmplToUse = function (item) {
                                return data.notes.selectedItem() === item ? "noteEditTmpl" : "noteItemTmpl";
                            };

                            data.toggleRoadsidePointHistory = function (event, args) {
                                var isToggled = $("#divRoadsidePointHistoryPanel").is(":visible");
                                var $this = $(args.currentTarget);
                                if (isToggled) {
                                    $this.find("i").removeClass("glyphicon-chevron-up").addClass("glyphicon-chevron-down");
                                } else {
                                    $this.find("i").removeClass("glyphicon-chevron-down").addClass("glyphicon-chevron-up");
                                }

                                $("#divRoadsidePointHistoryPanel").animate({ height: "toggle" }, 1500);
                            };

                            // Prop for unit message component grid query
                            data.unitMessageType = "T";

                            // Prop for equipment issued component grid query
                            data.equipmentIssuedType = "T";

                            self.tractorDetailsForModal(data);
                            self.createTractorInspectionsGrid(data);
                            self.createCSARoadsidePointHistoryGrid(data);

                            self.isLoading(false);
                        })
                        .fail(function (jqXHR, textStatus, errorThrown) {
                            if (jqXHR.responseJSON != null) {
                                var json = jqXHR.responseJSON;
                                if (json.message != null) {
                                    var errors = json.message;
                                    alert(errors);
                                }
                            } else {
                                alert("Unable to load tractor details");
                            }
                            self.isLoading(false);
                        });
                },
                cellsrenderer: function () {
                    return "View Details";
                },
            };
        };

        self.createTractorInspectionsGrid = function (data) {
            if (data.csaRoadsideInformation != null) {
                var csaRoadsidePointHistroySource = {
                    localdata: data.csaRoadsideInformation,
                    datatype: "array",
                    datafields: [
                        { name: "id", type: "int" },
                        { name: "postedDate", type: "date" },
                        { name: "inspectionDate", type: "date" },
                        { name: "roadside", type: "string" },
                        { name: "state", type: "string" },
                        { name: "inspectionLevel", type: "string" },
                        { name: "oos", type: "string" },
                    ],
                };
                var csaRoadsidePointHistroydataAdapter = new $.jqx.dataAdapter(csaRoadsidePointHistroySource);

                $("#gridRoadside").jqxGrid({
                    width: "100%",
                    source: csaRoadsidePointHistroydataAdapter,
                    autoheight: true,
                    altrows: true,
                    columnsresize: true,
                    // rowsheight: 50,
                    //initrowdetails: initrowdetails,
                    rowdetailstemplate: {
                        rowdetails: "<div id='grid' style='margin: 10px; padding: 0 20px; overflow-y: auto!important;'></div>",
                        rowdetailsheight: 300,
                        rowdetailshidden: true,
                    },
                    columns: [
                        {
                            text: "",
                            filterable: false,
                            sortable: false,
                            menu: false,
                            width: 100,
                            columnType: "button",
                            buttonclick: function (row) {
                                self.isLoading(true);

                                var datarow = $("#gridRoadside").jqxGrid("getrowdata", row);

                                var inspection = ko.utils.arrayFirst(data.csaRoadsideInformation, function (item) {
                                    if (item.id === datarow.id) {
                                        self.isLoading(false);
                                        return true;
                                    }
                                });

                                self.roadsideDetailsModal(inspection);

                                var grid = $("#gridViolatons");

                                var roadsideInformationGridSource = {
                                    localdata: inspection.violations,
                                    datatype: "array",
                                    datafields: [
                                        { name: "unitType", type: "string" },
                                        { name: "citation", type: "string" },
                                        { name: "basic", type: "string" },
                                        { name: "section", type: "string" },
                                        { name: "description", type: "string" },
                                        { name: "oos", type: "string" },
                                        { name: "severity", type: "string" },
                                        { name: "oosWeight", type: "string" },
                                        { name: "timeWeight", type: "string" },
                                        { name: "assetNumber", type: "string" },
                                        { name: "licenseNumber", type: "string" },
                                        { name: "licenseState", type: "string" },
                                        { name: "vin", type: "string" },
                                        { name: "points", type: "string" },
                                    ],
                                };
                                var roadsideInformationDataAdapter = new $.jqx.dataAdapter(roadsideInformationGridSource);

                                if (grid != null) {
                                    grid.jqxGrid({
                                        width: "100%",
                                        source: roadsideInformationDataAdapter,
                                        autoheight: true,
                                        altrows: true,
                                        columnsresize: true,
                                        //   autorowheight: true,
                                        columns: [
                                            { text: "Unit Type", datafield: "unitType", width: 70 },
                                            { text: "Citation", datafield: "citation", width: 70 },
                                            { text: "Basic", datafield: "basic", width: 100 },
                                            { text: "Section", datafield: "section", width: 100 },
                                            { text: "Description", datafield: "description", width: 150 },
                                            { text: "OOS", datafield: "oos", width: 40 },
                                            { text: "Severity", datafield: "severity", width: 70 },
                                            { text: "OOS Weight", datafield: "oosWeight", width: 90 },
                                            { text: "Time Weight", datafield: "timeWeight", width: 90 },
                                            { text: "Asset Number", datafield: "assetNumber", width: 100 },
                                            { text: "License Number", datafield: "licenseNumber", width: 120 },
                                            { text: "License State", datafield: "licenseState", width: 110 },
                                            { text: "VIN", datafield: "vin", width: 100 },
                                            { text: "Points", datafield: "points", width: 50 },
                                        ],
                                    });
                                }
                            },
                            cellsrenderer: function () {
                                return "Details";
                            },
                        },
                        {
                            text: "Post Date",
                            datafield: "postedDate",
                            filtertype: "date",
                            cellsformat: "MM/dd/yyyy",
                            width: 120,
                            createfilterwidget: createDateTimeFilterWidget,
                        },
                        {
                            text: "Inspection Date",
                            datafield: "inspectionDate",
                            filtertype: "date",
                            cellsformat: "MM/dd/yyyy",
                            width: 120,
                            createfilterwidget: createDateTimeFilterWidget,
                        },
                        {
                            text: "Roadside",
                            datafield: "roadside",
                            width: "45%",
                            cellsrenderer: function (row, columnfield, value, defaultHTML, column, rowData) {
                                if (defaultHTML != null) {
                                    var cell = $(defaultHTML);
                                    cell.html(
                                        "<a href='/RoadsideEntry?id=" +
                                            rowData.id +
                                            "'" +
                                            "target='_blank'  style='color:blue; '  >" +
                                            value +
                                            "</a>"
                                    );
                                    return cell[0].outerHTML;
                                }
                                return defaultHTML;
                            },
                        },
                        { text: "Inspection State", datafield: "state", width: 150 },
                        { text: "Inspection Level", datafield: "inspectionLevel", width: 150 },

                        { text: "OOS", datafield: "oos", width: 100 },
                    ],
                });
            }
        };

        self.tractorGridSource = function () {
            var source = {
                url: "Tractor/GetTractorsForGrid",
                datatype: "json",
                formatdata: function (data) {
                    var filterinfo = self.tractorsGrid.jqxGrid("getfilterinformation");
                    data = gridStateUtils.formatGridFilters(filterinfo, data);
                    data.includeInactive = self.includeInactive();

                    return data;
                },
                filter: function (filters, recordsArray) {
                    self.tractorsGrid.jqxGrid("updatebounddata", "filter");
                },
                sort: function (column, direction) {
                    self.tractorsGrid.jqxGrid("updatebounddata", "sort");
                },
                datafields: self.datafields,
            };
            var dataSource = (dataSource = dataModel.getDataAdapter(source));
            return dataSource;
        };

        self.initFilters = function () {
            if (self.tractorId != "") {
                var filtergroup2 = new $.jqx.filter();
                var filter2 = filtergroup2.createfilter("stringfilter", self.tractorId, "equal");
                filtergroup2.addfilter(1, filter2);
                self.tractorsGrid.jqxGrid("addfilter", "tractorId", filtergroup2);
                self.tractorsGrid.jqxGrid("applyfilters");
            }
            
        };

        Promise.all([self.loadGridColumnsandDatafields()]).then(function () {
            self.loadTractorsGrid(self.columns, self.datafields);
        });
    }
}

import template from "./tractors-page.html";
export default { viewModel: TractorsPageViewModel, template: template };
