import template from "./order-entry-stops-component.html";
import ko, { Observable } from "knockout";
import dataModel from "data-model";
import userProfile from "user-profile";
import "jqwidgets-scripts/jqwidgets/jqxknockout";
import dayjs from "dayjs";
import { OrderEntryViewModel } from "../order-entry-page";
import { showmessage } from "show-dialog-methods";
import { datetimeUTC, GenerateGuid } from "global-functions";
import {
  fetchAllAppointmentChangesByStopIdAsync,
  appointmentChangeModel,
} from "./order-entry-stops-edi-delay-component/order-entry-stops-edi-delay-code-editor";
import { isLoading } from "../../../../dataStore/actions/appUI";
import { useDispatch } from "ko-data-store";

export const GetUniqueStopNotes = (notes = []) => {
  return [...notes]
    .sort((a, b) => a.sequence - b.sequence)
    .reduce((notes, val) => {
      if (
        notes.some(
          (x) => x.noteTypeId == val.noteTypeId && x.comment == val.comment
        ) === false
      ) {
        notes.push(val);
      }

      return notes;
    }, []);
};

class StopModel {
  movementExternalId;
  /**@type { Observable<OrderEntryTracking> } */
  orderEntryTracking;
  /**@type {OrderEntryViewModel} */
  $parent;
  isShipper = ko.observable();
  /**@type { Observable<boolean> } */

  movementSequence = ko.observable();
  sequence = ko.observable();
  isConsignee = ko.observable();
  status = ko.observable();
  statusDescription = ko.observable();
  originalSelectedLocationId = ko.observable();
  isNewLocation = ko.observable();
  isNewContact = ko.observable();
  length = ko.observable();
  width = ko.observable();
  height = ko.observable();
  postingDimensions = ko.observable();
  noteSubTypes;

  // EDI
  isEdiCustomer = ko.observable(false).extend({ notify: "always" });
  ediPartnerId = ko.observable();
  displayEdiEditBtn = ko.observable(false);
  changeApptModal = ko.observable();

  // Helper ID for querying stops when there isn't any Id's yet..
  modelId; // -> UI only property, it is passed to the api and back with a stopId pair (so can remap the same modelId to the new stop), this is not saved to DB.

  constructor(params) {
    this.dispatchAction = useDispatch();

    this.$parent = params.mainModel;
    this.noteSubTypes = this.$parent.noteSubTypes;

    this.isEdiCustomer(this.$parent.isEdiCustomer());
    this.ediPartnerId(this.$parent.ediPartnerId());
    this.displayEdiEditBtn(this.$parent.hasEdiStatus() || false);

    var stop = params.stop;

    this.stops = this.$parent.stops;
    this.requiredFields = this.$parent.requiredFields;
    this.isReadOnly = this.$parent.isReadOnly;
    this.id = stop.id;

    this.modelId =
      stop.modelId ||
      (stop.externalId && stop.externalId.trim()) ||
      GenerateGuid(10);

    this.externalId = stop.externalId;
    this.orderId = this.$parent.orderId;
    this.movementId = stop.movementId;
    this.movementExternalId = stop.movementExternalId;
    this.rebuildDispatch = this.$parent.rebuildDispatch;
    this.orderEntryTracking = this.$parent.orderEntryTracking;

    this.movementSequence(stop.movementSequence);
    this.sequence(stop.sequence);
    this.isShipper(stop.isShipper);
    this.isConsignee(stop.isConsignee);
    this.status(stop.status);
    this.statusDescription(stop.statusDescription);
    this.originalSelectedLocationId(stop.location);
    this.isNewLocation(false);
    this.isNewContact(false);
    this.length(stop.length);
    this.width(stop.width);
    this.height(stop.height);
    this.postingDimensions(stop.postingDimensions || false);

    this.latitude = stop.latitude;
    this.longitude = stop.longitude;

    this.postingDimensions.subscribe(() => {
      if (!this.$parent.updatingStop) {
        this.$parent.updatingStop = this.id;
        this.$parent.stops().forEach((stop) => {
          if (this.$parent.updatingStop != stop.id) {
            stop.postingDimensions(false);
          }
        });
        this.$parent.updatingStop = undefined;
      }
    });

    this.isYsplit = ko.observable(stop.isYsplit || false);
    this.initStopType = ko.observable(stop.stopType);
    this.isStopTypeYsplit = ko.observable(
      stop.stopType == "SP" || stop.stopType == "SD" ? true : false
    );
    this.stopType = ko.observable(stop.stopType).extend({
      required: {
        params: true,
        message: "Stop type is required for stop #" + stop.sequence,
        onlyIf: () => {
          return (
            this.isShipper() == false &&
            this.isConsignee() == false &&
            this.isYsplit() == false &&
            this.isStopTypeYsplit() == false
          );
        },
      },
    });
    this.selectedLocationId = ko.observable(stop.location);
    this.selectedLocationCode = ko.observable();
    this.locationId = ko.observable(stop.locationId).extend({
      required: {
        message: () => {
          if (
            stop.isShipper &&
            this.requiredFields.isRequired("autoCompleteShippersLocation")
          ) {
            return (
              "The required field Shipper Location has not been entered for stop #" +
              stop.sequence +
              ".  Please enter the required information."
            );
          }
          if (
            stop.isConsignee &&
            this.requiredFields.isRequired("inputConsigneeLocation")
          ) {
            return (
              "The required field Consignee Location has not been entered for stop #" +
              stop.sequence +
              ".  Please enter the required information."
            );
          }
          return "Location is required for stop #" + stop.sequence + ".";
        },
        onlyIf: () => {
          if (this.isNewLocation() == true) {
            return false;
          }
          if (
            stop.isShipper &&
            this.requiredFields.isRequired("autoCompleteShippersLocation")
          ) {
            return true;
          }
          if (
            stop.isConsignee &&
            this.requiredFields.isRequired("inputConsigneeLocation")
          ) {
            return true;
          }
          if (this.isNewContact() == true) {
            return true;
          } else if (
            this.isShipper() == true ||
            (this.isConsignee() &&
              this.requiredFields.isRequired("inputConsigneeLocation"))
          ) {
            return true;
          }
          return false;
        },
      },
    });

    this.advancedLocationSearch = ko.observable();
    this.advancedLocationSearchWindow = ko.observable();
    this.isAddressReadOnly = ko.computed(
      this.isAddressReadOnlyComputedFunction,
      this
    );
    this.name = ko.observable(stop.name).extend({
      required: {
        message: () => {
          if (
            stop.isShipper &&
            this.requiredFields.isRequired("inputShipperName")
          ) {
            return (
              "The required field Shipper Name has not been entered for stop #" +
              stop.sequence +
              ".  Please enter the required information."
            );
          }
          if (
            stop.isConsignee &&
            this.requiredFields.isRequired("inputConsigneeName")
          ) {
            return (
              "The required field Consignee Name has not been entered for stop #" +
              stop.sequence +
              ".  Please enter the required information."
            );
          }
          return "Stop name is required";
        },
        onlyIf: () => {
          if (
            this.isConsignee() &&
            this.requiredFields.isRequired("inputConsigneeName") &&
            this.isAddressReadOnly() == false
          ) {
            return true;
          }
          if (
            stop.isShipper &&
            this.requiredFields.isRequired("inputShipperName")
          ) {
            return true;
          }
          if (
            stop.isConsignee &&
            this.requiredFields.isRequired("inputConsigneeName")
          ) {
            return true;
          }
          if (this.isNewLocation()) {
            return true;
          }
          return false;
        },
      },
    });
    this.address = ko.observable(stop.address).extend({
      required: {
        message: () => {
          if (
            stop.isShipper &&
            this.requiredFields.isRequired("inputShipperAddress")
          ) {
            return (
              "The required field Shipper Address has not been entered for stop #" +
              stop.sequence +
              ".  Please enter the required information."
            );
          }
          if (
            stop.isConsignee &&
            this.requiredFields.isRequired("inputConsigneeAddress")
          ) {
            return (
              "The required field Consignee Address has not been entered for stop #" +
              stop.sequence +
              ".  Please enter the required information."
            );
          }
          return "Address is required";
        },
        onlyIf: () => {
          if (
            this.isConsignee() &&
            this.requiredFields.isRequired("inputConsigneeAddress") &&
            this.isAddressReadOnly() == false
          ) {
            return true;
          }
          if (
            stop.isConsignee &&
            this.requiredFields.isRequired("inputConsigneeAddress")
          ) {
            return true;
          }
          if (
            stop.isShipper &&
            this.requiredFields.isRequired("inputShipperAddress")
          ) {
            return true;
          }
        },
      },
    });
    this.cityStateZip = ko.observable(stop.cityStateZip).extend({
      required: {
        message: () => {
          if (stop.isShipper && this.requiredFields.isRequired("cszShipper")) {
            return (
              "The required field Shipper City/Zip has not been entered for stop #" +
              stop.sequence +
              ".  Please enter the required information."
            );
          }
          if (
            stop.isConsignee &&
            this.requiredFields.isRequired("cszConsignee")
          ) {
            return (
              "The required field Consignee City/Zip has not been entered for stop #" +
              stop.sequence +
              ".  Please enter the required information."
            );
          }
          if (stop.isShipper == false && stop.isConsignee == false) {
            return "City/State/Zip is required at stop #" + stop.sequence;
          }
          return "City/State/Zip is required";
        },
        onlyIf: () => {
          if (stop.isShipper && this.requiredFields.isRequired("cszShipper")) {
            return true;
          }
          if (
            stop.isConsignee &&
            this.requiredFields.isRequired("cszConsignee")
          ) {
            return true;
          }
          if (this.isNewLocation()) {
            return true;
          } else if (this.isConsignee()) {
            return true;
          } else if (this.isShipper() == false && this.isConsignee() == false) {
            return true;
          }

          return false;
        },
      },
    });
    this.email = ko.observable(stop.email).extend({ email: true });
    this.phone = ko.observable(stop.phone).extend({
      required: {
        message: () => {
          if (
            this.isShipper() &&
            this.requiredFields.isRequired("inputShipperPhone")
          ) {
            return (
              "The required field Shipper Phone has not been entered for stop #" +
              stop.sequence +
              ".  Please enter the required information."
            );
          }
          if (
            this.isConsignee() &&
            this.requiredFields.isRequired("inputConsigneePhone")
          ) {
            return (
              "The required field Consignee Phone has not been entered for stop #" +
              stop.sequence +
              ".  Please enter the required information."
            );
          }
        },
        onlyIf: () => {
          if (
            this.isShipper() &&
            this.requiredFields.isRequired("inputShipperPhone")
          ) {
            return true;
          }
          if (
            this.isConsignee() &&
            this.requiredFields.isRequired("inputConsigneePhone")
          ) {
            return true;
          }
        },
      },
    });
    this.previousEarliestArrivalDate = ko.computed(() => {
      let index = this.$parent.stops.indexOf(this);
      if (index >= 0) {
        let previousStop = ko.unwrap(this.$parent.stops)[index - 1];
        if (previousStop != null) {
          return previousStop.earliestArrival();
        }
      }
      return undefined;
    });

    this.earliestArrival = ko.observable(stop.earliestArrival).extend({
      required: {
        message: () => {
          if (
            this.requiredFields.isRequired(
              "datePickerConsigneeScheduledArrivalStart"
            ) &&
            stop.isConsignee
          ) {
            return (
              "The required field Consignee Earliest Arrival has not been entered for stop #" +
              stop.sequence +
              ".  Please enter the required information."
            );
          }

          if (
            this.requiredFields.isRequired(
              "datePickerShipperScheduledArrivalStart"
            ) &&
            stop.isShipper
          ) {
            return (
              "The required field Shipper Earliest Departure has not been entered for stop #" +
              stop.sequence +
              ".  Please enter the required information."
            );
          }

          if (this.requiredFields.isRequired("datePickerEarliestArrival")) {
            return (
              "The required field Earliest Arrival has not been entered for stop #" +
              stop.sequence +
              ".  Please enter the required information."
            );
          }
        },
        onlyIf: () => {
          if (
            this.requiredFields.isRequired(
              "datePickerConsigneeScheduledArrivalStart"
            ) &&
            stop.isConsignee
          ) {
            return true;
          }

          if (
            this.requiredFields.isRequired(
              "datePickerShipperScheduledArrivalStart"
            ) &&
            stop.isShipper
          ) {
            return true;
          }

          if (this.requiredFields.isRequired("datePickerEarliestArrival")) {
            return true;
          }
          return false;
        },
      },
    });
    this.earliestArrival.extend({
      validation: {
        message: () => {
          return (
            "Arrival date must be after " +
            dayjs(this.previousEarliestArrivalDate()).format(
              userProfile.dateTimeFormat
            )
          );
        },
        validator: () => {
          if (
            this.previousEarliestArrivalDate != null &&
            ko.unwrap(this.earliestArrival) < this.previousEarliestArrivalDate()
          ) {
            return false;
          } else {
            return true;
          }
        },
      },
    });
    this.latestArrival = ko.observable(stop.latestArrival).extend({
      required: {
        params: true,
        message: () => {
          if (
            this.requiredFields.isRequired(
              "datePickerConsigneeScheduledArrivalEnd"
            ) &&
            stop.isConsignee
          ) {
            return (
              "The required field Latest Consignee Arrival has not been entered for stop #" +
              stop.sequence +
              ".  Please enter the required information."
            );
          }
          if (
            this.requiredFields.isRequired(
              "datePickerShipperScheduledArrivalEnd"
            ) &&
            stop.isShipper
          ) {
            return (
              "The required field Latest Shipper Departure has not been entered for stop #" +
              stop.sequence +
              ".  Please enter the required information."
            );
          }
        },
        onlyIf: () => {
          if (
            this.requiredFields.isRequired(
              "datePickerConsigneeScheduledArrivalEnd"
            ) &&
            stop.isConsignee
          ) {
            return true;
          }
          if (
            this.requiredFields.isRequired(
              "datePickerShipperScheduledArrivalEnd"
            ) &&
            stop.isShipper
          ) {
            return true;
          }
        },
      },
    });
    this.latestArrival.extend({
      validation: {
        message: () => {
          return (
            "Arrival date must be after " +
            dayjs(this.earliestArrival()).format(userProfile.dateTimeFormat)
          );
        },
        validator: () => {
          if (
            this.latestArrival &&
            this.earliestArrival &&
            this.latestArrival() &&
            this.earliestArrival() &&
            this.latestArrival() < this.earliestArrival()
          ) {
            return false;
          } else {
            return true;
          }
        },
      },
    });

    setTimeout(() => {
      this.locationId.subscribe(() => {
        this.$parent.rebuildDispatch(true);
      });

      this.latestArrival.subscribe((val) => {
        this.$parent.rebuildDispatch(true);
      });

      this.earliestArrival.subscribe((val) => {
        this.$parent.rebuildDispatch(true);
      });

      this.cityStateZip.subscribe(() => {
        this.$parent.rebuildDispatch(true);
      });
    }, 1500); //Need to wait for everything to finish loading before we subscribe to changes.

    this.pieces = ko.observable(stop.pieces).extend({ max: 1000000 });
    this.weight = ko.observable(stop.weight).extend({ max: 999999 });
    this.pallets = ko.observable(stop.pallets).extend({ max: 1000000 });
    this.driverLoad = ko.observable(stop.driverLoad);
    this.loadUnloadVisible = ko.observable(false);
    this.newLocation = ko.observable();
    this.newLocationCode = ko.observable().extend({
      required: {
        params: true,
        message: "Location code is required for stop #" + stop.sequence + ".",
        onlyIf: () => {
          var match = ko.utils.arrayFirst(
            this.requiredFields.required(),
            (item) => {
              return item.controlName === "inputConsigneeNewLocationCode";
            }
          );
          if (match || this.isNewLocation()) {
            return true;
          }

          return false;
        },
      },
      maxLength: 8,
    });

    this.locationContactId = ko.observable(stop.locationContact).extend({
      required: {
        message: () => {
          if (
            stop.isShipper &&
            this.requiredFields.isRequired("autoShipperContact")
          ) {
            return (
              "The required field Shipper Contact has not been entered for stop #" +
              stop.sequence +
              ".  Please enter the required information."
            );
          }
          if (
            stop.isConsignee &&
            this.requiredFields.isRequired("autoConsigneeContact")
          ) {
            return (
              "The required field Consignee Contact has not been entered for stop #" +
              stop.sequence +
              ".  Please enter the required information."
            );
          }
          return (
            "Location contact is required for stop #" + stop.sequence + "."
          );
        },
        onlyIf: () => {
          if (
            stop.isShipper &&
            this.requiredFields.isRequired("autoShipperContact")
          ) {
            return true;
          }
          if (
            stop.isConsignee &&
            this.requiredFields.isRequired("autoConsigneeContact")
          ) {
            return true;
          }
          var match = ko.utils.arrayFirst(
            this.requiredFields.required(),
            (item) => {
              return item.controlName === "autoCompleteConsigneeContact";
            }
          );
          if (match) {
            return true;
          }
          return false;
        },
      },
    });

    this.locationContact = ko.observable();
    this.selectedLocation = ko.observable();
    this.selectedLocation.subscribe(this.locationSelectedFunction);

    this.apptRequired = ko.observable(stop.apptRequired);
    this.isApptRequiredReadOnly = ko.pureComputed(() => {
      if (!this.$parent.summary() || !this.$parent.summary().status) {
        return false;
      }
      const _status = ["Delivered", "Voided"];
      return (
        (this.selectedLocation() && this.selectedLocation().apptRequired) ||
        _status.indexOf(this.$parent.summary().status()) != -1 ||
        _status.indexOf(stop.MovementStatus) != -1
      );
    });

    this.apptConfirmed = ko.observable(stop.apptConfirmed);
    this.isApptConfirmedReadOnly = ko.pureComputed(() => {
      if (!this.$parent.summary() || !this.$parent.summary().status) {
        return false;
      }
      const _status = ["Delivered", "Voided"];
      return (
        _status.indexOf(this.$parent.summary().status()) != -1 ||
        _status.indexOf(stop.MovementStatus) != -1
      );
    });

    this.addNewNote = ko.observable();

    this.stopNotes = ko.observableArray(
      ko.utils.arrayMap(GetUniqueStopNotes(stop.notes), (note) => {
        return new StopNotes(note, this);
      })
    );

    this.stopNoteInsertButtonDisabled = ko.observable(false);
    this.referenceNumbers = ko.observableArray(
      ko.utils.arrayMap(stop.referenceNumbers, (refNumber) => {
        return new ReferenceNumber(refNumber, this.sequence);
      })
    );
    this.currentReferenceNumber = ko.observable();
    //This is to force the update of the CSS class computer variable below.
    this.dummyVariable = ko.observable();
    this.currentStopNote = ko.observable();
    this.referenceNumbersColumnCSSClass = ko.computed(() => {
      var dummee = this.dummyVariable();
      if (this.referenceNumbers().length >= 3 && $(window).width() > 1200) {
        return "dimsAndRefOverflow";
      } else {
        return "col-lg-4 col-md-12";
      }
    });

    $(window).resize(() => {
      this.dummyVariable.notifySubscribers();
    });

    this.insertYSplit = (stop) => {
      this.$parent.rebuildDispatch(true);
      var sequence = stop.sequence();
      var movementSequence = stop.movementSequence();
      ko.utils.arrayForEach(this.$parent.stops(), (item) => {
        if (
          item.sequence() >= stop.sequence() &&
          item.movementSequence() == stop.movementSequence()
        ) {
          item.sequence(item.sequence() + 1);
        }
      });
      var index = this.$parent.stops.indexOf(stop);
      this.$parent.stops.splice(
        index,
        0,
        new StopModel({
          stop: {
            movementSequence: movementSequence,
            sequence: sequence,
            movementId: ko.unwrap(stop.movementId),
            stopType: "Y-Split",
            isYsplit: true,
            status: "A",
            isShipper: false,
            isConsignee: false,
          },
          mainModel: this.$parent,
        })
      );
    };

    this.notesGridId = "#geNotesGrid" + this.id;
    setTimeout(() => {
      if (!$("#geNotesGrid" + this.id).length && !$(this.notesGridId).length)
        return false;

      $("#geNotesGrid" + this.id).jqxGrid({
        cellhover: (element, pageX, pageY) => {
          if ($(element).children().hasClass("commentToolTip")) {
            $(element)
              .children()
              .jqxTooltip({
                content:
                  '<div class="scrollingTooltipDiv">' +
                  element.innerHTML +
                  "</div>",
                width: "600px",
                closeOnClick: false,
                autoHide: true,
                autoHideDelay: 0,
                showDelay: 1000,
                trigger: "hover",
              });
            $(element).children().jqxTooltip("open", pageX, pageY);
          }
        },
      });

      $(this.notesGridId).on("cellclick", function (event) {
        if (
          $(this).parent()[0].id == "jqxpanel" &&
          event.args.datafield == "comment" &&
          event.args.rightclick == false
        ) {
          if (event.args.row.rowdetailshidden) {
            $(this).jqxGrid("showrowdetails", event.args.rowindex);
            $(this).jqxGrid({ selectionmode: "singlerow" });
          } else {
            $(this).jqxGrid("hiderowdetails", event.args.rowindex);
            $(this).jqxGrid({ selectionmode: "none" });
          }
        }
      });
    }, 100);

    document.addEventListener('orderEntryMovementStatusChanged', this.handleMovementStatusChange)

    this.isMovementDelivered = ko.observable(false);
  }

  dispose = () => {
    document.removeEventListener('orderEntryMovementStatusChanged', this.handleMovementStatusChange)
  }

  handleMovementStatusChange = (event) => {
    const {detail} = event ?? {};

    if(detail?.movementId === this.movementId && detail?.movementStatus === "Delivered") {
      this.isMovementDelivered(true);
    }
    else {
      this.isMovementDelivered(false);
    }
  }

  addStopBefore = (stop) => {
    this.$parent.rebuildDispatch(true);
    var sequence = stop.sequence();
    var movementSequence = stop.movementSequence();
    ko.utils.arrayForEach(this.$parent.stops(), (item) => {
      if (
        item.sequence() >= stop.sequence() &&
        item.movementSequence() == stop.movementSequence()
      ) {
        item.sequence(item.sequence() + 1);
      }
    });

    var index = this.$parent.stops.indexOf(stop);
    this.$parent.stops.splice(
      index,
      0,
      new StopModel({
        stop: {
          movementSequence: movementSequence,
          sequence: sequence,
          movementId: ko.unwrap(stop.movementId),
          status: "A",
          isShipper: false,
          isConsignee: false,
        },
        mainModel: this.$parent,
      })
    );

    //If this is the 3rd stop
    if (
      this.$parent.stops().length == 3 &&
      this.$parent.agencyId() == "DVABAS"
    ) {
      this.$parent.stops()[1].cityStateZip.subscribe((value) => {
        var city = value.substring(value.indexOf("(") + 1, value.indexOf(","));
        $("#customField2").val(city);
      });
    }
  };

  stopNoteColumns = () => {
    return [
      {
        text: "&nbsp;",
        datafield: "Edit",
        columntype: "button",
        width: "50px",
        cellsrenderer: () => {
          return "Edit";
        },
        cellclassname: (row, columnfield, value, data) => {
          if (
            this.isNoteAndRefNumReadOnly() ||
            data.disableDelete == true ||
            this.isReadOnly()
          ) {
            return "hideRowButton";
          }
          return "";
        },
        buttonclick: (row) => {
          var note = this.stopNotes()[row] || {};
          this.currentStopNote({ ...note });
        },
      },
      {
        text: "&nbsp;",
        datafield: "Copy",
        columntype: "button",
        width: "50px",
        cellsrenderer: () => {
          return "Copy";
        },
        buttonclick: (row) => {
          let note = this.stopNotes()[row];
          navigator.clipboard.writeText(note.comment());
        },
      },
      { text: "id", dataField: "id", hidden: true },
      {
        text: "Type",
        dataField: "noteType",
        width: "150px",
        cellsrenderer: (
          row,
          columnfield,
          value,
          defaultHTML,
          column,
          rowData
        ) => {
          if (rowData && rowData.noteType == "Internal Billing Comment") {
            var _$el = $(defaultHTML).css({
              color: "red",
              padding: "2px",
            });

            defaultHTML = _$el[0].outerHTML;
          }

          return defaultHTML;
        },
      },
      {
        text: "Comment",
        dataField: "comment",
        cellsrenderer: (
          row,
          columnfield,
          value,
          defaultHTML,
          column,
          rowData
        ) => {
          var re = new RegExp(/\n/, "g");
          defaultHTML = defaultHTML.replace(re, "<br />");
          var re2 = new RegExp(/\n/, "g");
          defaultHTML = defaultHTML.replace(re2, "<br />");
          if (rowData && rowData.noteType == "Internal Billing Comment") {
            var _$el = $(defaultHTML).css({
              color: "red",
              padding: "2px",
            });
            defaultHTML = _$el[0].outerHTML;
          }
          return '<div class="commentToolTip">' + defaultHTML + "</div>";
        },
      },
      {
        text: "Username",
        datafield: "username",
        width: "100px",
      },
      {
        text: "LastUpdated",
        datafield: "lastUpdated",
        width: "150px",
        cellsrenderer: (
          row,
          columnfield,
          value,
          defaultHTML,
          column,
          rowData
        ) => {
          if (value) {
            let $defaultHTML = $(defaultHTML);
            $defaultHTML.html(dayjs(value).format("MM/DD/YYYY HH:mm"));
            return $defaultHTML[0].outerHTML;
          }
        },
      },
      {
        text: "&nbsp;",
        datafield: "delete",
        columntype: "button",
        width: "50px",
        cellclassname: (row, columnfield, value, data) => {
          if (
            this.isNoteAndRefNumReadOnly() ||
            data.disableDelete == true ||
            this.isReadOnly()
          ) {
            return "hideRowButton";
          }
          return "";
        },
        cellsrenderer: function () {
          return "Delete";
        },
        buttonclick: (row) => {
          var note = this.stopNotes()[row];
          //if this note already exists in db, remove it from db when 'delete' is clicked.
          //if the note does not already exist in db, then remove it from the stopNote array when delete is clicked
          if (note.id()) {
            dataModel
              .ajaxRequest("Order/DeleteStopNote", "DELETE", {
                noteId: note.id(),
                orderTracking: this.orderEntryTracking().data.toJSON(),
              })
              .done((response) => {
                if (response.success) {
                  this.stopNotes.remove(note);
                } else {
                  var tempComment = note.comment();
                  note.comment(response.errorMessage);
                  setTimeout(() => {
                    note.comment(tempComment);
                  }, 3750);
                }

                this.orderEntryTracking()
                  .methods()
                  .saveUpdateTrackingAndRefreshTimestamp();
              })
              .fail((jqXHR) => {
                if (jqXHR.status == 409 && jqXHR.responseJSON) {
                  this.orderEntryTracking()
                    .methods()
                    .displayConflictNotifyMessage(jqXHR.responseJSON);
                } else if (jqXHR.status == 412) {
                  // Order Locked
                  showmessage(jqXHR.responseJSON.message);
                }
              });
          } else {
            this.stopNotes.remove(note);
          }
        },
      },
    ];
  };

  notesGridObject = () => {
    return {
      source: this.stopNotes,
      columns: this.stopNoteColumns(),
      autoheight: true,
      width: "100%",
      initrowdetails: (index, parentElement, gridElement, datarecord) => {
        $(parentElement).text(datarecord.comment);
        $(parentElement).css("white-space", "pre-wrap");
      },
      showrowdetailscolumn: false,
      rowdetails: true,
      rowdetailstemplate: {
        rowdetails:
          "<div style='white-space: normal;' class='notesGridRowDetails'></div>",
        rowdetailsheight: 200,
      },
    };
  };

  isLocationLinkVisible = ko.pureComputed(() => {
    if (this.selectedLocationCode() != null && this.isAddressReadOnly()) {
      return true;
    }
    return false;
  });

  isNoteAndRefNumReadOnly = ko.pureComputed(() => {
    if (
      this.status() == "V" ||
      this.$parent.isAgencyOrder() == false ||
      this.$parent.isReadOnlyOrder()
    ) {
      return true;
    } else if (this.$parent.orderLockComponent()?.lockOrderControls()) {
      return true;
    }
    return false;
  });

  isReadOnly = ko.pureComputed(() => {
    var isParentReadOnly = this.$parent.isReadOnly();
    if (isParentReadOnly == true || this.isStopTypeYsplit() == true) {
      return true;
    }
    return false;
  });

  isArrivalReadOnly = ko.pureComputed(() => {
    var isParentReadOnly = this.$parent.isReadOnly();
    if (isParentReadOnly == true || this.status() == "D") {
      return true;
    }
    return false;
  });

  isFieldHidden = (name) => {
    // Need a function on this context so we can access it in the view easier.
    return this.requiredFields.isHidden(name);
  };

  isReadOnlyStopType = ko.pureComputed(() => {
    var isParentReadOnly = this.isReadOnly();
    if (this.isShipper() || this.isConsignee()) {
      return true;
    } else if (isParentReadOnly == true) {
      return true;
    } else if (this.isYsplit() || this.isStopTypeYsplit()) {
      return true;
    }
    return false;
  });

  isAddressReadOnlyComputedFunction = () => {
    if (this.isReadOnly()) {
      return true;
    } else if (this.isNewLocation() == true) {
      return false;
    } else if (this.locationId() > 0) {
      return true;
    } else {
      return false;
    }
  };

  previousStop = (index) => {
    var previousStop = ko.unwrap(this.stops)[index - 1];
    return previousStop;
  };

  isAddStopBeforeVisible = ko.pureComputed(() => {
    if (!this.$parent.summary() || !this.$parent.summary().status) {
      return false;
    }
    return (
      this.isShipper() == false &&
      this.status() == "A" &&
      this.isReadOnly() == false &&
      this.$parent.summary().status() != "Delivered" &&
      !this.$parent.orderLockComponent()?.lockOrderControls()
    );
  });

  isDeleteVisible = ko.pureComputed(() => {
    if (this.$parent.orderLockComponent()?.lockOrderControls()) {
      return false;
    } else if (this.isShipper() == false && this.isConsignee() == false) {
      var currentMovementStops = ko.utils.arrayFilter(
        ko.unwrap(this.stops),
        (item) => {
          if (item.movementSequence() === this.movementSequence()) {
            return true;
          }
          return false;
        }
      );
      if (
        currentMovementStops.length >= 2 &&
        this.status() == "A" &&
        this.isStopTypeYsplit() == false
      ) {
        return true;
      }
    }
    return false;
  });

  isYsplitVisible = ko.pureComputed(() => {
    if (this.$parent.orderLockComponent()?.lockOrderControls()) {
      return false;
    } else if (
      this.status() == "A" &&
      this.isYsplit() == false &&
      this.isReadOnly() == false
    ) {
      if (!this.$parent.summary() || !this.$parent.summary().status) {
        return false;
      }

      let index = this.stops().findIndex((x) => {
        return x.id == this.id;
      });
      if (index >= 0) {
        var previousStop = ko.unwrap(this.stops)[index - 1];
        if (previousStop != null && previousStop.status) {
          if (
            previousStop.status() == "D" &&
            this.stopTypeCode() != "SP" &&
            this.$parent.summary().status() != "Delivered"
          ) {
            return true;
          } else if (
            this.stopTypeCode() != "SP" &&
            (this.status() == "A" || this.status() == "P") &&
            this.$parent.summary &&
            this.$parent.summary().status &&
            this.$parent.summary()?.status() != "Delivered"
          ) {
            return true; //^^                                      This is stupid                                              ^^
          }
        }
      }
    }
    return false;
  });

  isDeleteYsplitVisible = ko.pureComputed(() => {
    if (this.$parent.orderLockComponent()?.lockOrderControls()) {
      return false;
    } else if (this.stopTypeCode() == "SD" && this.status() == "A") {
      return true;
    }
    return false;
  });

  stopTypeCode = ko.pureComputed(() => {
    var ysplitCode = this.initStopType();
    if (ysplitCode == "SP" || ysplitCode == "SD") {
      return ysplitCode;
    } else {
      var typeId = this.stopType();
      if (typeId == 1 || typeId == "PU") {
        return "PU";
      } else if (typeId == 2 || typeId == "RP") {
        return "RP";
      } else if (typeId == 3 || typeId == "SO") {
        return "SO";
      } else if (typeId == 6 || typeId == "VA") {
        return "VA";
      } else if (typeId == 7 || typeId == "VP") {
        return "VP";
      }
    }
  });

  stopTypeId = ko.pureComputed(() => {
    var stopType = this.initStopType();
    if (stopType == "SP") {
      return 4;
    } else if (stopType == "SD") {
      return 5;
    } else {
      stopType = this.stopType();
      if (typeof stopType == "number") {
        return stopType;
      }
    }
  });

  advancedLocationSearchClick = () => {
    this.advancedLocationSearchWindow({ locationId: this.locationId });
  };

  get referenceNumbersColumns() {
    return [
      {
        text: "&nbsp;",
        datafield: "Edit",
        columntype: "button",
        width: 50,
        cellsrenderer: () => {
          return "Edit";
        },
        cellclassname: (row, columnfield, value, data) => {
          data = data || {};
          if (
            this.isNoteAndRefNumReadOnly() ||
            (this.isReadOnly() && data.qualifierTypeId > 0)
          ) {
            return "hideRowButton";
          }
          return "";
        },
        buttonclick: (row) => {
          var rowRecord = this.referenceNumbers()[row];
          var refNumers = ko.toJS(rowRecord);
          refNumers.qualifierTypeId = refNumers.qualifierType;
          this.currentReferenceNumber(
            new ReferenceNumber(refNumers, this.sequence)
          );
        },
      },
      {
        text: "id",
        dataField: "id",
        hidden: true,
      },
      {
        text: "Number",
        dataField: "number",
        width: "230px",
      },
      {
        text: "Qualifier Type",
        dataField: "qualifierDescription",
        width: "230px",
        cellsrenderer: (
          row,
          columnfield,
          value,
          defaultHTML,
          column,
          rowData
        ) => {
          rowData = rowData || {};

          if (defaultHTML != null && !rowData.qualifierDescription) {
            var cell = $(defaultHTML);
            cell.text("Needs Qualifier Type");
            cell.css("color", "red");
            return cell[0].outerHTML;
          }

          return defaultHTML;
        },
      },
      {
        text: "&nbsp;",
        datafield: "delete",
        columntype: "button",
        width: 50,
        cellclassname: () => {
          if (this.isNoteAndRefNumReadOnly() || this.isReadOnly()) {
            return "hideRowButton";
          }
          return "";
        },
        cellsrenderer: function () {
          return "Delete";
        },
        buttonclick: (row) => {
          var refNum = this.referenceNumbers()[row];
          this.referenceNumbers.remove(refNum);

          this.currentReferenceNumber(undefined);
        },
      },
    ];
  }

  removeStop = (stop) => {
    this.rebuildDispatch(true);
    ko.utils.arrayForEach(this.stops(), (item) => {
      if (
        item.sequence() >= stop.sequence() &&
        item.movementSequence() == stop.movementSequence()
      ) {
        item.sequence(item.sequence() - 1);
      }
    });
    this.stops.remove(stop);
  };

  removeYSplitStop = (stop) => {
    this.rebuildDispatch(true);
    var sequence = stop.sequence();
    var splitMovementId = 0;
    //Remove second ysplit stop.  This will be the first stop of the next movement.
    //If multiple empty movements exists, it won't always be stop.movementSequence() + 1.
    let nextMovementSequence = stop.movementSequence() + 1;
    while (
      stop.stops().some((x) => x.movementSequence() == nextMovementSequence) ==
      false
    ) {
      nextMovementSequence++;
    }
    ko.utils.arrayForEach(this.stops(), (item) => {
      if (item != null) {
        if (
          item.stopTypeCode() == "SP" &&
          nextMovementSequence == item.movementSequence()
        ) {
          splitMovementId = item.movementId;
          this.stops.remove(item);
        }
      }
    });

    ko.utils.arrayForEach(this.stops(), (item) => {
      if (
        item.sequence() >= stop.sequence() &&
        item.movementSequence() == stop.movementSequence()
      ) {
        item.sequence(item.sequence() - 1);
      }
      if (item.movementSequence() > stop.movementSequence()) {
        item.movementSequence(item.movementSequence() - 1);
        if (splitMovementId == item.movementId) {
          item.sequence(sequence + item.sequence() - 2);
          item.movementId = stop.movementId;
        }
      }
    });
    this.stops.remove(stop);
  };

  showReferenceNumber = () => {
    this.currentReferenceNumber(
      new ReferenceNumber(
        {
          isNew: true,
        },
        this.sequence
      )
    );
  };

  locationSelectedFunction = (selectedItem) => {
    if (selectedItem != undefined) {
      if (this.selectedLocationId() != selectedItem.id) {
        this.locationContactId(undefined);
      }
      this.selectedLocationId(selectedItem.id);
      this.name(selectedItem.name);
      this.selectedLocationCode(selectedItem.code);
      this.address(selectedItem.address);
      if (selectedItem.zip != null) {
        this.cityStateZip(
          selectedItem.zip +
            " (" +
            selectedItem.city +
            ", " +
            selectedItem.state +
            ")"
        );
      } else {
        this.cityStateZip(selectedItem.city + ", " + selectedItem.state);
      }

      // if selected location is required, then override
      if (selectedItem.apptRequired) {
        this.apptRequired(selectedItem.apptRequired);
      }
    } else {
      this.locationContactId(undefined);
      this.selectedLocationCode(undefined);
      this.name(null);
      this.address(null);
      this.cityStateZip(null);
    }
  };

  stopContactSelected = (selectedItem) => {
    if (selectedItem != undefined) {
      dataModel
        .ajaxRequest("LocationContact/" + selectedItem.id)
        .done((data) => {
          this.phone(data.phone);
          this.email(data.email);
        });
    }
  };

  commentShrinker = (row, column, value) => {
    var html = value;
    if (value.length > 50) {
      var firstFiftyChars = value.substring(0, 50);
      html =
        "<a title= '" +
        value +
        "' style='text-decoration:none;'>" +
        firstFiftyChars +
        " ... " +
        "</a>";
    }
    return html;
  };

  saveStopNote = (note) => {
    var validationErrors = ko.validation.group(this.currentStopNote);
    if (validationErrors().length > 0) {
      validationErrors.showAllMessages();
      return false;
    }

    if (typeof note.comment === 'string' && note.comment.trim() === '') {
      showmessage(`Stop: Note added without a comment. Please update or delete the note to continue.`);
      return false;
  }

    if (!note.noteTypeId()) {
      showmessage(`Please select a stop note type.`);
      return false;
    }

    //if stop exists in db or the order is in quote status, allow a note to be added to the stop in db
    if (this.id || this.status() == "Quote") {
      if (note.isNew()) {
        note.sequence = this.stopNotes().length + 1;
        note.lastUpdated(new Date());
        note.username(userProfile.userName);
        note.userId(userProfile.userId);
      } else {
        var preExistingStopNote = ko.utils.arrayFirst(
          this.stopNotes(),
          (item) => {
            return note.id() === item.id();
          }
        );

        if (preExistingStopNote) {
          var data = ko.toJS(note);
          preExistingStopNote.noteTypeId(data.noteTypeId);
          preExistingStopNote.comment(data.comment);
          preExistingStopNote.lastUpdated(new Date());
          preExistingStopNote.username(userProfile.userName);
          preExistingStopNote.userId(userProfile.userId);
        }
      }

      var stopData = ko.toJSON(this.stops);
      var noteData = {
        orderId: this.orderId(),
        stopId: this.id,
        stopSequence: this.sequence(),
        stopNote:
          preExistingStopNote === undefined
            ? ko.toJS(note)
            : preExistingStopNote,
        numOfStops: this.stops().length,
        stops: stopData,
      };

      // Add order conflict tracking
      noteData.orderTracking = this.orderEntryTracking().data.toJSON();

      this.stopNoteInsertButtonDisabled(true);

      dataModel
        .ajaxRequest("order/UpdateStopNote", "PUT", noteData)
        .done((response) => {
          this.stopNoteInsertButtonDisabled(false);
          if (response.success) {
            //if new note was added
            if (note.isNew()) {
              note.isNew(false);
              this.orderEntryTracking()
                .methods()
                .saveUpdateTrackingAndRefreshTimestamp();
              //if a new stop(s) was added
              if (response.stops) {
                for (let i = 0; i < response.stops.length; i++) {
                  //for each stop:
                  //Add the stop's id to the array of stops.  The id is not an observable in the stops array
                  this.stops()[response.stops[i].sequence - 1].id =
                    response.stops[i].id;
                  //If the new stop has a new note, add the new note to that stop.  in the returned collection of new stops
                  //created by the note insert, only one new stop can have a new note, and it can only have one note
                  if (response.stops[i].notes.length > 0) {
                    var mappedData = ko.utils.arrayMap(
                      response.stops[i].notes,
                      (newStopNote) => {
                        return new StopNotes(newStopNote, this);
                      }
                    );

                    //if a new stopNote was constructed, set the isNew property to false, and add that new note the new stop
                    if (mappedData.length > 0) {
                      mappedData[0].isNew(false);
                      this.stops()[response.stops[i].sequence - 1].stopNotes(
                        mappedData
                      );
                    }
                  }
                }
              }
              //If a new note has been added to a stop that already existed, just add the new note.id to the note.  The other pertinent information for the note (besides the id)
              //is already contained in the 'note' object in the stopNote array for client side purposes, and the rest of the new note info has been saved in db
              else {
                note.id(response.newNoteId);
                this.stopNotes.push(note);
              }
            }

            /// If it is not a new note or new stop, it is an update. Updating the note in the stop note array has already been done for client side
            /// via the 'match' function above, and in the database via the updatestopnote method, so there is nothing to do
            this.currentStopNote(undefined);
          } // saving the note will fail if an order has not been generated.  An order / orderId must exist before adding a note.
          else {
            $("#stopNoteComment").css({
              color: "red",
              "background-color": "#f2f2f2",
            });
            note.comment(response.errorMessage);
            setTimeout(() => {
              note.comment(undefined);
              this.currentStopNote(undefined);
              $("#stopNoteComment").css({ color: "black" });
            }, 7000);
          }
        })
        .fail((jqXHR) => {
          if (jqXHR.status == 409) {
            this.orderEntryTracking()
              .methods()
              .displayConflictNotifyMessage(jqXHR.responseJSON);
          } else if (jqXHR.status == 412) {
            // Order Locked
            showmessage(jqXHR.responseJSON.message);
          } else {
            $("#stopNoteComment").css({
              color: "red",
              "background-color": "#f2f2f2",
            });
            note.comment("Something went wrong.  Please try again.");
            setTimeout(() => {
              note.comment(undefined);
              this.currentStopNote(undefined);
              $("#stopNoteComment").css({ color: "black" });
            }, 5500);
          }
          this.stopNoteInsertButtonDisabled(false);
          this.orderEntryTracking()
            .methods()
            .saveUpdateTrackingAndRefreshTimestamp();
        });
    } else {
      //If a note is ADDED on a NEW stop and the order is not in 'Quote' status
      //Push the note into the stop notes array and handle the note save if/when the entire order is saved
      //If the entire order is not saved, then the note will not be saved
      if (note.isNew()) {
        note.isNew(false);
        this.stopNotes.push(note);
      }
      //If a note is UPDATED on a NEW stop and the order is not in 'Quote' status
      //Update the note in the stop notes array and save the changes if/when the entire order is saved
      //If the entire order is not saved, then the note will not be saved
      else {
        preExistingStopNote = ko.utils.arrayFirst(this.stopNotes(), (item) => {
          return note.id() === item.id();
        });

        if (preExistingStopNote) {
          data = ko.toJS(note);
          preExistingStopNote.noteTypeId(data.noteTypeId);
          preExistingStopNote.comment(data.comment);
        }
      }
      this.currentStopNote(undefined);
    }
  };

  cancelNote = () => {
    this.currentStopNote(undefined);
  };

  showAddNewNote = () => {
    this.currentStopNote(
      new StopNotes(
        {
          isNew: true,
          sequence: this.stopNotes().length + 1,
        },
        this
      )
    );
  };

  saveReferenceNumber = (refNum) => {
    var validationErrors = ko.validation.group(this.currentReferenceNumber);
    if (validationErrors().length > 0) {
      validationErrors.showAllMessages();
      return false;
    }
    if (refNum.isNew()) {
      refNum.isNew(false);
      refNum.id(0 - this.referenceNumbers().length - 1);
      this.referenceNumbers.push(refNum);
    } else {
      var match = ko.utils.arrayFirst(this.referenceNumbers(), function (item) {
        return refNum.id() === item.id();
      });
      if (match) {
        var data = ko.toJS(refNum);
        match.number(data.number);
        match.qualifierDescription(data.qualifierDescription);
        match.qualifierTypeId(data.qualifierTypeId);
        match.qualifierType(data.qualifierType);
      }
    }
    this.currentReferenceNumber(undefined);
  };

  cancelReferenceNumber = () => {
    this.currentReferenceNumber(undefined);
  };

  generateLocationCodeClick = () => {
    var locationCodeParam = {
      name: this.name(),
      cityStateZip: this.cityStateZip(),
      stopSequence: this.sequence(),
    };
    dataModel
      .ajaxRequest("location/generatecode", "POST", locationCodeParam, true)
      .done((response) => {
        this.newLocationCode(response);
      });
  };

  stopTypeSelected = (selectedItem) => {
    if (selectedItem != undefined) {
      if (
        (selectedItem.code == "PU" || selectedItem.code == "SO") &&
        stop.header == "Stop"
      ) {
        this.loadUnloadVisible(true);
      }
    } else {
      if (stop.header == "Shipper" || stop.header == "Consignee") {
        this.loadUnloadVisible(true);
      } else {
        this.loadUnloadVisible(false);
      }
    }
  };
  newLocationClick = () => {
    if (this.isNewLocation()) {
      this.isNewLocation(false);
    } else {
      this.isNewLocation(true);
      this.locationContactId(undefined);
      this.selectedLocationId(undefined);
    }
  };
  newContactClick = () => {
    if (this.isNewContact()) {
      this.isNewContact(false);
    } else {
      this.isNewContact(true);
    }
  };
  validationObject() {
    return {
      stopType: this.stopType,
      locationId: this.locationId,
      name: this.name,
      address: this.address,
      cityStateZip: this.cityStateZip,
      email: this.email,
      phone: this.phone,
      earliestArrival: this.earliestArrival,
      latestArrival: this.latestArrival,
      pieces: this.pieces,
      weight: this.weight,
      pallets: this.pallets,
      newLocationCode: this.newLocationCode,
      locationContactId: this.locationContactId,
    };
  }

  isEdiApptChangeBtnReadOnly = ko.pureComputed(() => {
    if (
      this.status() == "V" ||
      this.$parent.isAgencyOrder() == false ||
      this.$parent.isReadOnlyOrder()
    ) {
      return true;
    } else if (this.$parent.orderLockComponent()?.lockOrderControls()) {
      return true;
    } else if (this.isReadOnly()) {
      return true;
    } else if (this.isArrivalReadOnly()) {
      return true;
    } else if (
      this.apptConfirmed() === false ||
      this.apptRequired() === false
    ) {
      return true;
    }

    return false;
  });

  ediApptChangeBtnTooltip = ko.pureComputed(() => {
    if (this.$parent.orderLockComponent()?.lockOrderControls()) {
      return `Order is locked.`;
    }

    if (this.apptConfirmed() === false || this.apptRequired() === false) {
      return `You must set appointment required and confirmed to edit.`;
    }

    return `When you are changing the scheduled times the arrival time for the next stop cannot be earlier than a previous stop.`;
  });

  handleEditAppointmentChange = async () => {
    try {
      const { orderOpenedAtTimestamp } =
        this.orderEntryTracking().data.toJSON() || {};

      this.dispatchAction(isLoading(true));
      const stopId = ko.toJS(this.id);
      const results =
        (await fetchAllAppointmentChangesByStopIdAsync(stopId)) || [];
      this.dispatchAction(isLoading(false));

      const appts = results.map(
        (x) => new appointmentChangeModel({ ...x, stopId })
      );

      this.changeApptModal({
        partnerId: this.ediPartnerId(),
        appointmentChanges: appts,
        stopId: this.id,
        preventAddMore: this.status() !== "A",
        originalEarliestArrival: this.earliestArrival(),
        originalLatestArrival: this.latestArrival(),
        orderId: this.orderId(),
        orderOpenedAtTimestamp,
      });
    } catch (err) {
      this.dispatchAction(isLoading(false));
      console.error(err);
    }
  };

  handleCloseEditAppointmentModal = () => this.changeApptModal(null);

  handleStopAppointmentChange = ({
    stopId,
    revisedEarliestArrival,
    revisedLatestArrival,
  }) => {
    if (stopId == this.id) {
      const revEarlyDate = datetimeUTC(revisedEarliestArrival);
      const revLateDate = datetimeUTC(revisedLatestArrival);

      if (revEarlyDate.isValid()) {
        this.earliestArrival(revEarlyDate);
      }

      if (revLateDate.isValid()) {
        this.latestArrival(revLateDate);
      }
    }

    this.handleCloseEditAppointmentModal();
  };
}

class StopNotes {
  constructor(note, $parent = {}) {
    this.isNew = ko.observable(note.isNew);
    this.id = ko.observable(note.id);
    this.comment = ko.observable(note.comment).extend({ required: true });
    this.sequence = ko.observable(note.sequence);
    this.noteTypeId = ko.observable(note.noteTypeId);
    this.noteType = ko.observable(note.noteType);

    const allowedNoteLength = {
      1: 600,
    };

    this.usedNoteTxt = ko.pureComputed(() => {
      const typeId = this.noteTypeId();
      const textLength = allowedNoteLength[typeId];
      const comment = this.comment() ?? "";

      if (!typeId) {
        return "";
      }

      if (comment.length > textLength) {
        return `You have too many characters for selected note type. Only ${textLength} characters allowed.`;
      }

      return `${comment.length}/${textLength ?? "5000"} characters remaining.`;
    });

    this.selectedNoteType = ko
      .observable(note.noteTypeId)
      .extend({ required: true });
    this.selectedNoteType.subscribe((newValue) => {
      if (newValue) {
        this.noteType(newValue.label);
        this.noteTypeId(newValue.value);
      } else {
        this.noteType(undefined);
      }
    });

    // error box pops up when noteTypeId = 1 and chars = 150
    var invoiceValidChars = ko.pureComputed(() => {
      const typeId = this.noteTypeId();
      const allowed = allowedNoteLength[typeId] ?? 5000;

      if (this.comment() && this.comment().length > allowed) {
        return true;
      }
      return false;
    });

    invoiceValidChars.subscribe((valid) => {
      $parent.stopNoteInsertButtonDisabled(valid);
    });

    this.stopNoteMaxLength = ko.pureComputed(() => {
      const typeId = this.noteTypeId();
      const allowed = allowedNoteLength[typeId] ?? 5000;
      return allowed;
    });

    var _disableDelete =
      note.disableDelete || note.noteType == "Internal Billing Comment";
    this.disableDelete = ko.observable(_disableDelete);
    this.locationId = ko.observable(note.locationId);
    this.username = ko.observable(note.username);
    this.userId = ko.observable(note.userId);
    this.lastUpdated = ko.observable(
      note.lastUpdated ? new Date(note.lastUpdated) : undefined
    );
  }
}

class ReferenceNumber {
  constructor(refNum, stopSequenceNum) {
    stopSequenceNum = ko.unwrap(stopSequenceNum);

    this.id = ko.observable(refNum.id);
    this.number = ko
      .observable(refNum.number)
      .extend({ required: true, maxLength: 32 });
    this.qualifierTypeId = ko.observable(refNum.qualifierTypeId).extend({
      required: {
        params: true,
        message: `Stop ${
          stopSequenceNum && refNum.id > 0 ? "#" + stopSequenceNum : ""
        } Reference Number ${
          refNum.id > 0 && refNum.number ? ko.unwrap(this.number) : ""
        } is missing required qualifier type.`,
      },
    });
    this.qualifierType = ko.observable(refNum.qualifierType);
    this.qualifierDescription = ko.observable(refNum.qualifierDescription);
    this.selectedQualifierType = ko.observable();
    this.selectedQualifierType.subscribe((newValue) => {
      if (newValue) {
        this.qualifierTypeId(newValue.id);
        this.qualifierDescription(newValue.description);
        this.qualifierType(newValue.code);
      } else {
        this.qualifierDescription(undefined);
        this.qualifierType(undefined);
        this.qualifierTypeId(undefined);
      }
    });

    this.isNew = ko.observable(refNum.isNew);
  }
}

StopModel.prototype.toJSON = function () {
  var copy = ko.toJS(this);
  delete copy.stops;
  delete copy.$parent;

  return copy;
};

StopModel.prototype.toJS = function () {
  var copy = ko.toJS(this);
  delete copy.stops;
  delete copy.$parent;

  return copy;
};

export default {
  viewModel: {
    createViewModel: function (params, componentInfo) {
      //Don't do anything.  Use the object created in the main OrderEntry page.
    },
  },
  template: template,
};

export { StopModel };
