const host = (window.location.host && window.location.host.toUpperCase()) || "";
const isNewProd = host.toUpperCase().indexOf("VFGWGE24") > -1;
const isProd = host.toUpperCase().indexOf("GREAT-EDGE.NET") > -1;
const isNewPreview = host.toUpperCase().indexOf("PREVIEW.GREAT-EDGE") > -1;

const apiUrl = (isNewPreview && 'https://apipreview.great-edge.net/')  ||
    (isNewProd && 'https://VFGWGE24:61984/') || (isProd && 'https://greatwideapi.great-edge.net/');

export default {
    gemAPI: 'http://great-edge/api/GEM/V2/',
    apiUrl: apiUrl,
    mapquestApiKey: 'Fmjtd|luuanuut20,rn=o5-96bldw',
    pushKey: 'BHmR6yWY5uiTRoisNC4fkcMI-7ORhljE7hZgUprVXMCW4PUkP5_VfDJ2U9jmA6BJUc1Cw_O4GfRtO-D0JDrJ9oU'
};61984